/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useAuth } from '../../contexts/AuthContext';
import { MyFirebase } from '../../api/my_firebase.ts';
import * as StripePayment from '../../api/stripe_paypment.ts';
import Dashboard from '../dashboard/Dashboard.tsx';

import {
  Button,
  Typography,
  Box,
  Input
} from '@mui/joy';

const Settings = () => {
  const { isPremium, email } = useAuth();
  const [loading, setLoading] = useState(false);

  const app = MyFirebase.getInstance().getApp();

  return (
    <Dashboard>
      <Helmet >
        <title>Settings</title>
        <meta name="description" content={"Account Settings"} />
      </Helmet>
      <Box>
        <Typography
          level="h1"
          sx={{
            fontSize: "1.8em",
            fontWeight: 'lg',
            fontFamily: "Libre Baskerville",
            pb: 2
          }}
        >
          Settings
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            gap: 2
          }}
        >
          <Input
            size="lg"
            placeholder={email ? "Email" : "Loading email..."}
            variant="outlined"
            disabled
            value={email || ''}
            sx={{ width: 'fit-content', minWidth: '300px' }}
          />
          {
            isPremium &&
            <Button
              variant="outlined"
              color="neutral"
              loading={loading}
              sx={{
                bgcolor: 'background.level1',
                textTransform: 'none',
              }}
              onClick={async () => {
                setLoading(true);
                const url = await StripePayment.getPortalUrl(app)
                window.open(url, '_blank');
                setLoading(false);
              }}
            >
              Manage Membership
            </Button>
          }
          <Button
            variant="outlined"
            color="neutral"
            sx={{
              textTransform: 'none',
            }}
            onClick={() => {
              MyFirebase.getInstance().signOut();
            }}
          >
            Logout
          </Button>
        </Box>
      </Box>
    </Dashboard>
  );
};

export default Settings;
