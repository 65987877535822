import React from 'react';
import { Box, Card, Typography } from '@mui/joy';

interface MetricCardProps {
  title: string;
  subtitle: string;
  amount: string;
  description?: string;
  sx?: any;
}

const MetricCard: React.FC<MetricCardProps> = ({ title, amount, subtitle, description, sx }) => {
  return (
    <Box sx={sx}>
      <Card variant="outlined" sx={{ p: 2 }}>
        <Typography level="h2" fontSize="xl" sx={{ mb: 0 }}>
          {title}
        </Typography>
        <Typography level="h1" fontSize="5xl" fontWeight="xl" sx={{ mb: 0 }}>
          {amount}
        </Typography>
        <Typography level="body-sm" sx={{ mb: 0 }}>
          {subtitle}
        </Typography>
        {description && <Box
          sx={{
            display: 'inline-block',
            px: 1,
            py: 0.5,
            borderRadius: 'lg',
            bgcolor: 'success.softBg',
            color: 'success.softColor',
          }}
        >
          <Typography level="body-sm" fontWeight="md">
            {description}
          </Typography>
        </Box>}
      </Card>
    </Box>
  );
};

export default MetricCard;