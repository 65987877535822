import React from 'react';
import { Box } from '@mui/joy';
import { Helmet } from 'react-helmet-async';
import Dashboard from '../../pages/dashboard/Dashboard.tsx';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import Chip from '@mui/joy/Chip';
import Typography from '@mui/joy/Typography';
import Check from '@mui/icons-material/Check';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import ToggleButtonGroup from '@mui/joy/ToggleButtonGroup';
import { useCheckout } from './useCheckout.ts';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext.js';

// Types and Interfaces
interface PricingTier {
  badge: string;
  title: string;
  description: string;
  monthlyPrice: number;
  yearlyPrice: number;
  color?: 'neutral';
  isPopular?: boolean;
  ctaText: string;
}

interface TierFeatures {
  [key: string]: {
    [key: string]: boolean | string;
  };
}

// Constants
const ALL_FEATURES = [
  'Closet Sharing',
  // 'Schedule Sharing',
  'Product Research',
  'Historical Data',
  'Priority Support'
] as const;

const PRICING_TIERS: PricingTier[] = [
  {
    badge: 'BASIC',
    title: 'Free',
    description: 'Perfect for getting started!',
    monthlyPrice: 0,
    yearlyPrice: 0,
    ctaText: 'Current Plan'
  },
  {
    badge: 'PREMIUM',
    title: 'Business',
    description: 'Unlimited data to maximize your reselling profits.',
    monthlyPrice: 39.99,
    yearlyPrice: 359.99,
    ctaText: 'Get Started',
    isPopular: true,
  },
  {
    badge: 'BUDGET',
    title: 'Starter',
    description: 'Ideal for growing your sales and researching trends.',
    monthlyPrice: 29.99,
    yearlyPrice: 269.99,
    ctaText: 'Get Started'
  },
];

const TIER_FEATURES: TierFeatures = {
  'Free': {
    'Closet Sharing': 'Up to 100 listings/day',
    'Schedule Sharing': false,
    'Product Research': 'Up to 5 searches/day',
    'Historical Data': '1 month',
    'Priority Support': false
  },
  'Starter': {
    'Closet Sharing': 'Unlimited',
    'Schedule Sharing': false,
    'Product Research': 'Unlimited',
    'Historical Data': '3 months',
    'Priority Support': true
  },
  'Business': {
    'Closet Sharing': 'Unlimited',
    'Schedule Sharing': true,
    'Product Research': 'Unlimited',
    'Historical Data': 'Unlimited',
    'Priority Support': true
  }
};

const calculateMonthlyPrice = (price: number, billingCycle: 'monthly' | 'yearly') => {
  return billingCycle === 'monthly' ? price : price / 12;
};

// Subcomponents
const PricingCard: React.FC<{
  tier: PricingTier;
  billingCycle: 'monthly' | 'yearly';
  onCheckout: (tierTitle: string) => void;
  loading: boolean;
  loadingTier: string | null;
  isSignedIn: boolean;
}> = ({ tier, billingCycle, onCheckout, loading, loadingTier, isSignedIn }) => (
  <Card
    size="lg"
    variant={tier.isPopular ? 'outlined' : 'outlined'}
    color={tier.color}
    invertedColors={false}
    sx={{
      ...(tier.isPopular ? {
        transform: { md: 'scale(1.05)' },
        boxShadow: 'lg',
        zIndex: 1,
      } : undefined),
      mb: { xs: 2, md: 0 },
      display: 'flex',
      flexDirection: 'column',
      minHeight: 300,
      transition: 'transform 0.3s ease-in-out',
      '&:hover': {
        transform: tier.isPopular ? 'scale(1.07)' : 'scale(1.02)',
      }
    }}
  >
    <Box sx={{ mb: 'auto' }}>
      <Chip
        size="sm"
        variant="outlined"
        color={tier.isPopular ? 'primary' : 'neutral'}
        sx={{ mb: 1.5 }}
      >
        {tier.badge}
      </Chip>
      <Typography
        level="h2"
        sx={{ color: tier.isPopular ? 'primary.500' : 'inherit' }}
      >
        {tier.title}
      </Typography>
      <Typography level="body-sm" sx={{ mt: 1, mb: 2 }}>
        {tier.description}
      </Typography>
      <Typography level="title-lg">
        ${calculateMonthlyPrice(billingCycle === 'monthly' ? tier.monthlyPrice : tier.yearlyPrice, billingCycle).toFixed(2)}{' '}
        <Typography textColor="text.tertiary" sx={{ fontSize: 'sm' }}>
          / month
        </Typography>
      </Typography>
      {billingCycle === 'yearly' && tier.monthlyPrice > 0 && (
        <Typography level="body-sm" textColor="text.tertiary">
          Billed annually
        </Typography>
      )}
    </Box>

    <Button
      variant={tier.isPopular ? 'solid' : 'soft'}
      color={tier.isPopular ? 'primary' : 'neutral'}
      endDecorator={tier.title === 'Free' && isSignedIn ? null : <KeyboardArrowRight />}
      loading={loading && loadingTier === tier.title}
      disabled={(loading && loadingTier !== tier.title) || (tier.title === 'Free' && isSignedIn)}
      onClick={() => {
        if (tier.title === 'Free' && !isSignedIn) {
          onCheckout('Free');
        } else if (tier.title !== 'Free') {
          onCheckout(tier.title);
        }
      }}
      sx={{
        mt: 2,
        ...(tier.title === 'Free' && isSignedIn && {
          cursor: 'default',
          '&:hover': {
            bgcolor: 'inherit',
          }
        })
      }}
    >
      {tier.title === 'Free' && isSignedIn ? 'Current Plan' : 'Get Started'}
    </Button>
  </Card>
);

const FeatureRow: React.FC<{ feature: string; selectedTier?: string }> = ({ feature, selectedTier }) => (
  <React.Fragment>
    <Typography
      level="title-sm"
      sx={{
        p: { xs: 1, md: 2 },
        borderBottom: '1px solid',
        borderColor: 'divider',
        bgcolor: { xs: 'background.level1', md: 'transparent' },
        gridColumn: { xs: '1 / span 3', md: '1' },
        mb: { xs: 0.5, md: 0 },
        position: 'relative',
        zIndex: 1,
      }}
    >
      {feature}
    </Typography>

    {PRICING_TIERS.map((tier) => (
      <Box
        key={`${tier.title}-${feature}`}
        sx={{
          p: { xs: 1, md: 2.5 },
          borderBottom: '1px solid',
          borderColor: 'divider',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative',
          zIndex: 1,
          ...(tier.isPopular && {
            '@media (min-width: 900px)': {
              bgcolor: 'neutral.100',
              fontWeight: 600,
            }
          }),
        }}
      >
        {typeof TIER_FEATURES[tier.title][feature] === 'boolean' ? (
          TIER_FEATURES[tier.title][feature] ? (
            <Check sx={{
              fontWeight: {
                xs: selectedTier === tier.title ? 700 : 400,
                md: tier.isPopular ? 700 : 400
              },
              color: {
                xs: selectedTier === tier.title ? 'primary.500' : 'inherit',
                md: tier.isPopular ? 'primary.500' : 'inherit'
              },
              fontSize: tier.isPopular ? '1.2rem' : '1rem'
            }} />
          ) : null
        ) : (
          <Typography
            level="body-sm"
            sx={{
              fontWeight: {
                xs: selectedTier === tier.title ? 700 : 400,
                md: tier.isPopular ? 600 : 400
              },
              color: {
                xs: selectedTier === tier.title ? 'primary.500' : 'inherit',
                md: tier.isPopular ? 'primary.500' : 'inherit'
              },
              textAlign: 'center',
              width: '100%'
            }}
          >
            {TIER_FEATURES[tier.title][feature]}
          </Typography>
        )}
      </Box>
    ))}
  </React.Fragment>
);

export const Pricing = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  const messageContent = code === '429' ? {
    title: "You've reached your usage limits for today",
    description: "Sign up or subscribe to increase your limits."
  } : null;

  const [selectedTier, setSelectedTier] = React.useState<string>('Business');
  const [billingCycle, setBillingCycle] = React.useState<'monthly' | 'yearly'>('yearly');
  const [loadingTier, setLoadingTier] = React.useState<string | null>(null);

  const { isSignedIn } = useAuth();
  
  const { loading, handleCheckout } = useCheckout();

  const handleTierCheckout = (tierTitle: string) => {
    setLoadingTier(tierTitle);
    handleCheckout(tierTitle, billingCycle);
  };

  const yearlySavings = React.useMemo(() => {
    const businessTier = PRICING_TIERS[1]; // Most popular tier
    const yearlyTotal = businessTier.monthlyPrice * 12;
    return yearlyTotal > 0
      ? Math.round(((yearlyTotal - businessTier.yearlyPrice) / yearlyTotal) * 100)
      : 0;
  }, []);

  // Update grid template columns to be dynamic
  const gridTemplateColumns = React.useMemo(() => ({
    xs: `repeat(${PRICING_TIERS.length}, 1fr)`,
    md: `0.7fr ${PRICING_TIERS.map(() => '1fr').join(' ')}`,
  }), []);

  return (
    <Dashboard>
      <Helmet>
        <title>Pricing</title>
        <meta name="description" content="" />
        <meta property="og:title" content="" />
        <meta property="og:description" content="" />
        <meta property="og:url" content="https://poshwatch.io/pricing" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      {/* Add Message Section */}
      {messageContent && (
        <Box
          sx={{
            textAlign: 'center',
            mb: 4,
            display: 'grid',
            gridTemplateColumns: {
              xs: '1fr',
              md: '0.7fr 1fr 1fr 1fr',
            }
          }}
        >
          <Box sx={{ display: { xs: 'none', md: 'block' } }} />
          <Box sx={{ gridColumn: 'span 3' }}>
            <Typography
              level="h1"
              sx={{
                mb: 1,
                fontSize: { xs: '1.875rem', md: '2.25rem' },
                fontFamily: '"Libre Baskerville", serif'
              }}
            >
              {messageContent.title}
            </Typography>
            <Typography
              level="body-lg"
              color="neutral"
              sx={{ mx: 'auto', px: 2 }}
            >
              {messageContent.description}
            </Typography>
          </Box>
          <Box />
        </Box>
      )}

      {/* Add Billing Toggle */}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xs: '1fr',
            md: '0.7fr 1fr 1fr 1fr',
          },
          mb: { xs: 0, sm: 1 },
        }}
      >
        <Box sx={{ display: { xs: 'none', md: 'block' } }} />
        <Box />
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <ToggleButtonGroup
            value={billingCycle}
            onChange={(_, value) => value && setBillingCycle(value)}
            sx={{
              mb: 2,
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 'md',
              overflow: 'hidden'
            }}
          >
            <Button
              value="monthly"
              variant={billingCycle === 'monthly' ? 'solid' : 'soft'}
              sx={{
                ...(billingCycle === 'monthly' && {
                  bgcolor: 'neutral.900',
                  '&:hover': { bgcolor: 'neutral.800' }
                })
              }}
            >
              Monthly
            </Button>
            <Button
              value="yearly"
              variant={billingCycle === 'yearly' ? 'solid' : 'soft'}
              sx={{
                ...(billingCycle === 'yearly' && {
                  bgcolor: 'neutral.900',
                  '&:hover': { bgcolor: 'neutral.800' }
                })
              }}
            >
              Yearly
              <Chip
                size="sm"
                variant="soft"
                color="success"
                sx={{ ml: 1 }}
              >
                Save {yearlySavings}%
              </Chip>
            </Button>
          </ToggleButtonGroup>
        </Box>
        <Box />
      </Box>

      {/* Pricing Cards */}
      <Box sx={{ mb: 4, display: { xs: 'none', md: 'block' } }}>
        <Box sx={{ display: 'grid', gridTemplateColumns: gridTemplateColumns, gap: 2 }}>
          <Box sx={{ p: 2, display: { xs: 'none', md: 'block' } }}></Box>
          {PRICING_TIERS.map((tier, index) => (
            <PricingCard
              key={index}
              tier={tier}
              billingCycle={billingCycle}
              onCheckout={handleTierCheckout}
              loading={loading}
              loadingTier={loadingTier}
              isSignedIn={isSignedIn}
            />
          ))}
        </Box>
      </Box>

      {/* Features Table */}
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: gridTemplateColumns,
          gap: { xs: 0, md: 0 },
          position: 'relative',
          '@media (min-width: 900px)': {
            bgcolor: 'background.surface',
            p: 2,
            borderRadius: 'lg',
            border: '1px solid',
            borderColor: 'divider',
            '& > *': {
              borderBottom: '1px dashed var(--joy-palette-divider)',
              '&:last-child, &:nth-last-child(2), &:nth-last-child(3), &:nth-last-child(4)': {
                borderBottom: 'none'
              }
            }
          },
        }}
      >
        {/* Column Highlights for Mobile */}
        {PRICING_TIERS.map((tier, index) => (
          <Box
            key={`highlight-${tier.title}`}
            sx={{
              display: { xs: 'block', md: 'none' },
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: `${(index * 100) / PRICING_TIERS.length}%`,
              width: `${100 / PRICING_TIERS.length}%`,
              bgcolor: 'transparent',
              transition: 'background-color 0.2s',
              zIndex: 0,
            }}
          />
        ))}

        {/* Mobile Headers */}
        <Box sx={{ display: { xs: 'block', md: 'none' }, gridColumn: '1 / span 3', position: 'relative', zIndex: 1 }}>
          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gap: 1,
              mb: 2,
            }}
          >
            {PRICING_TIERS.map((tier) => (
              <Box
                key={tier.title}
                onClick={() => setSelectedTier(tier.title)}
                sx={{
                  textAlign: 'center',
                  p: 2,
                  bgcolor: 'background.surface',
                  borderRadius: 'sm',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: 1,
                  cursor: 'pointer',
                  transition: 'all 0.2s',
                  border: '1px solid',
                  borderColor: selectedTier === tier.title ? 'primary.500' : 'divider',
                  transform: selectedTier === tier.title ? 'scale(1.05)' : 'scale(1)',
                  zIndex: selectedTier === tier.title ? 1 : 0,
                  '&:hover': {
                    bgcolor: 'background.level2',
                    transform: selectedTier === tier.title ? 'scale(1.07)' : 'scale(1.02)',
                  }
                }}
              >
                <Typography
                  level="h3"
                  sx={{
                    color: selectedTier === tier.title ? 'primary.500' : 'inherit',
                    fontWeight: 'bold',
                  }}
                >
                  {tier.title}
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <Typography level="body-sm" sx={{ color: 'text.tertiary' }}>
                    ${calculateMonthlyPrice(billingCycle === 'monthly' ? tier.monthlyPrice : tier.yearlyPrice, billingCycle).toFixed(2)}/mo
                  </Typography>
                  {billingCycle === 'yearly' && tier.monthlyPrice > 0 && (
                    <Typography
                      level="body-xs"
                      sx={{
                        color: 'text.tertiary',
                        mt: 0.5
                      }}
                    >
                      Billed annually
                    </Typography>
                  )}
                </Box>
              </Box>
            ))}
          </Box>

          {/* Mobile CTA Button */}
          <Button
            variant="solid"
            color="primary"
            endDecorator={<KeyboardArrowRight />}
            loading={loading}
            onClick={() => handleCheckout(selectedTier, billingCycle)}
            disabled={selectedTier === 'Free'}
            sx={{
              width: '100%',
              mb: 2,
              '--Button-minHeight': '48px',
              ...(selectedTier === 'Free' && {
                cursor: 'default',
                '&:hover': {
                  bgcolor: 'inherit',
                }
              })
            }}
          >
            {selectedTier === 'Free' ? 'Current Plan' : `Continue with ${selectedTier} plan`}
          </Button>
        </Box>

        {ALL_FEATURES.map((feature) => (
          <FeatureRow key={feature} feature={feature} selectedTier={selectedTier} />
        ))}
      </Box>
    </Dashboard>
  );
};

export default Pricing;