import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ResponsiveAppBar } from '../../components/ResponsiveAppBar.js';
import Footer from '../../components/Footer.js';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import { Helmet } from 'react-helmet-async';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Panel from '../../components/Panel.js';
import { lightTheme } from '../../customTheme.js';
import confetti from 'canvas-confetti';
import PATHS from '../../utils/paths';

const PremiumStatus = () => {
  const navigate = useNavigate();

  const handleContinue = async () => {
    navigate(PATHS.HOME);
  }

  useEffect(() => {
    var count = 200;
    var defaults = {
      origin: { y: 0.7 }
    };

    function fire(particleRatio, opts) {
      confetti({
        ...defaults,
        ...opts,
        particleCount: Math.floor(count * particleRatio)
      });
    }

    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });
    fire(0.2, {
      spread: 60,
    });
    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2
    });
    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  }, [])


  return (
    <div style={{ position: 'relative' }}>
      <Helmet >
        <title>Premium</title>
        <meta name="description" content={""} />
      </Helmet>
      <ResponsiveAppBar hideLinks />
      <Panel theme={lightTheme}>
        <Container maxWidth='md' style={{ display: 'flex', justifyContent: 'center' }}>
          <Card style={{ minWidth: "400px", maxWidth: "400px" }}>
            <CardContent style={{ padding: "30px" }}>
              <div>
                <Typography variant="h4">
                  Congratulations!
                </Typography>
                <Typography variant="body1" paddingTop={2} paddingBottom={2}>
                  Welcome to Poshwatch Premium. Discover top brands, detailed insights, and more!
                </Typography>

                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                  <Button onClick={handleContinue} variant="contained" color="primary" fullWidth
                    style={{
                      textTransform: 'none', height: "40px"
                    }}>
                    Continue
                  </Button>
                </div>
              </div>
            </CardContent>
          </Card>
        </Container>
      </Panel>
      <Footer hideLinks />
    </div >
  );
};

export default PremiumStatus;
