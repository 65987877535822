import { colors } from '../customTheme';
import styleTags from '../images/how_to_rank_high/pastel-style-tag.png';
import ListingAnalyzer from '../images/how_to_list/analyzer.png';
import ListingAge from '../images/how_to_rank_high/listingAge.png';
import SharesInfluence from '../images/how_to_rank_high/shares-influence.png';
import RankChecker from '../images/how_to_rank_high/rank-checker.png';
import RankCheckerRanked from '../images/how_to_rank_high/rank-checker-ranked.png';

const howToRank1inPoshmarkSearch =`
~~~~light,${colors.white}
# How to Rank #1 in Poshmark Search

<div style="text-align: center; font-family: Inter; margin-bottom: 6px">
Last updated August 25, 2024
</div>
<div style="text-align: center; font-family: Inter">
Written by Alice
</div>
\n&nbsp;\n

You have a bunch of listings in your closet that aren't getting traction. 

They’re lost in the wilderness of the Poshmark search results, buried under the mountain of other listings.

So, what do you need to change to get them ranking #1—or at least on the first page? Let’s explore the importance of ranking on the first page and the key factors that influence Poshmark search rankings. 

So you can increase your listings' visibility and help them sell quickly.


~~~~light,${colors.white},${colors.slatePurple}
#### The 3 Key Factors That Influence Poshmark Search Rankings

Shares, Titles, Listing Age are the most influential factors in determining your Poshmark search ranking.


~~~~light,${colors.white}
##### 1. Shares

If you want to appear at the top, your best bet is to share at least 3x a day.

Since Poshmark's default search is 'Just shared,' which I suspect users rarely deviate from that default. In our research many of the listings at the top of this sorting order had well over 100 total shares. 

They also maintained their top spots more consistently than other items that would appear because they are newer. Those would evaporate just as quickly as they appeared.

![Picture showing the influence of sharing](${SharesInfluence})

~~~~light,${colors.white}
##### 2. Title

Listings that closely match the search query in their titles rank higher. 

At the bare minimum, they should include the _Brand, Item type, and style_. 

Add condition, color, and size so your listing appears in a more specific search query. Buyers with more specific queries are usually closer to purchasing.

E.g. _'NEW Birkenstock Marid Brown Sandals Size 7'_

Even though we did not look at descriptions in our assessment. It is well known that descriptions with relevant keywords will also help your listing rank higher.


~~~~light,${colors.white}
##### 3. Listing Age

Newer listings rank higher. 

Consider relisting about every 30 days to improve your chances of ranking higher.

When shares are low, the impact of listing age is more clearly visible. This was the case with these two listings ranked #3 and #6 for the search 'Birkenstock Florida sandals' sorted by Just Shared.

![Picture showing the influence of listing age](${ListingAge})


~~~~light,${colors.white}
#### Why Rank on Page 1 of Poshmark Search

It's Simple, conversion rates (sales) are closely tied to ranking.

The first step to every sale is first to be seen. The higher you rank in the search results, the more eyes you get on your listings and the more opportunities for your listing to be sold. 

Most of the research in this area has been done into ranking importance in regards to Google but its safe to assume the results would be similar on other search engines like Poshmark as user behavior would be similar.

~~~~light,${colors.white}
###### First Page Dominance

According to Neil Patel a well known SEO agency owner [About 75%](https://neilpatel.com/blog/first-page-google/) of users on Google will only interact with the first page of search results. 

~~~~light,${colors.white}
###### Page 2 and Beyond is a Ghost Town

Very few people wonder to this neck of the search woods.

According to Backlino [0.44% of users visit the second page](https://backlinko.com/google-user-behavior), which basically makes page 2 and beyond an abandoned radioactive town you dont want to get stuck in. 

While I think users are more likely to scroll a bit more on Poshmark than Google due to their search results having infinite scroll, its safe to assume that there will still be way less eyes and life visiting the deeper parts of the Poshmark search trenches.

Don't get your listing stuck where the sun don't shine.

~~~~light,${colors.white}
###### Click-Through Rate Decline:

The CTR decreases significantly with each page. 

While the first few results on the first page might have a [CTR of 10-40%](https://firstpagesage.com/reports/google-click-through-rates-ctrs-by-ranking-position/), by the time you reach the second page, CTRs for even the top position would likely be below 2%. 

Once again these are numbers for Google as there are no published numbers for this on Poshmark. But user behavior is likely the same.

So to give your listing a fighting chance you need your listing to be within the 1st page and ideally within the first few spots.




~~~~light,${colors.white},${colors.slatePurple}
#### The Impact of Other Listing Properties

While Title, Listing Age, and Shares remain the most influential factors in determining your Poshmark search ranking, effectively managing Price, Condition, Likes, Style Tags and the # of photos can enhance your overall success on the platform as well.

~~~~light,${colors.white}
##### 1. Price

**Impact on Ranking:** Low

While price is important for attracting the attention of buyers, our research shows that it doesn't heavily influence ranking unless sorting by price or using the price filters.

~~~~light,${colors.white}
##### 2. Condition

**Impact on Ranking:** Low

The condition of an item (e.g., New With Tags (NWT), Like New) significantly influences buyer decision-making but seems to have a minimal direct effect on search ranking. Though we were only assessing whether condition was set on the listing and not whether it was mentioned in the title.

Some buyers filter searches by condition and also add it to their queries so if your item is in NWT or Like new condition and you want to appear in these search results where they buyer specified condition its best to add it to your title and description.

~~~~light,${colors.white}
##### 3. Likes

**Impact on Ranking:** Low

Likes indicate buyer interest but doesn't seem to have a strong influence on the ranking of listings in Poshmarks search. 

They are, however, a good indicator of the desirability of your item. Likes also provide opportunities to engage with potential buyers through offers which can increase sales.


~~~~light,${colors.white}
##### 4. Style Tags

**Impact on Ranking:** Low

According to Poshmark, sellers can use Style tags to increase a listing's visibility. 

We did not find them to have a strong impact on a simple keyword search but they do behave like a category and users are able to visit specific style tag search pages where all the listings are tagged with a particular style tag. Eg. [Pastel](https://poshmark.com/style-tag/Pastel).
![Picture of style tag search](${styleTags})

~~~~light,${colors.white}
##### 5. # of Photos

**Impact on Ranking:** Low or None

We found that the number of photos had little to no impact on the ranking of a listing but its best to have at least 4-5 photos to clearly show all angles. This will help buyers to feel confident in their purchase decisions and reduce returns.


~~~~light,${colors.white}
#### How to Check Your Listing's Rank

To make it easy for sellers to assess the rankings of their listings for different keywords in Poshmark's search results we created a free [listing rank checker](https://poshwatch.io/listing-rank-checker) tool. 

It allows you to see where your listing ranks in comparison to others and shows you how your stats compare to the top listings for certain keywords.

This comparison can help you understand what adjustments might be needed to climb the ranks. 

Here is how it works:

**1. Visit** 

[listing rank checker](https://poshwatch.io/listing-rank-checker)

**2. Select the sort option**

The default is relevance but test both.

**3. Enter keywords**

E.g. 'birkenstock Madrid sandals blue size 7' or 'birkenstock Madrid sandals' play around with it

**4. Enter your listing URL**

![Picture of listing rank checker](${RankChecker})

**5. Search Then Analyze Your Ranking**

![Picture of listing with high rank](${RankCheckerRanked})


If it indicates that you are not ranked in the top few pages then it will give you the option to get feedback on your listing with our Poshmark listing analyzer which will give you clear, actionable insights so you can optimize your listings and increase your chances ranking #1, and making a sale.


~~~~light,${colors.white},${colors.slatePurple}
#### Tools & Resources

If you are struggling to create good listings, we’ve written a blog post to help you. [How to List on Poshmark](https://poshwatch.io/guides/how-create-poshmark-listings-that-sell). 

Concerned about the quality of your listings? We’ve also got you covered, try our free analyzer tool: [Poshmark Listing Analyzer](https://poshwatch.io/listing-analyzer-poshmark).

![Picture of listing analyzer](${ListingAnalyzer})


~~~~light,${colors.white}
#### Conclusion

Getting your listings to rank #1 in Poshmark search results is about understanding the key factors that influence rankings. By focusing on sharing daily, optimizing your titles, and keeping your listings fresh by relisting them often, you can improve your chances of getting that top spot. And don’t forget, tools like our Listing Rank Checker can give you the insights you need to stay ahead of the competition.

Keep testing, tweaking, and improving your listings, and you’ll see the results in no time. Happy selling!`

export default howToRank1inPoshmarkSearch;
