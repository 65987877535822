import React from 'react';
import { Avatar, Box, Card, CardContent, Typography, Rating, Grid, Container } from '@mui/material';
import { colors } from '../../../customTheme.js';

export interface TestimonialProps {
  name: string;
  comment: string;
  rating?: number;
  title?: string;
  avatarUrl?: string;
}

const Testimonial: React.FC<TestimonialProps> = ({
  name,
  comment,
  rating,
  title,
  avatarUrl,
}) => {
  return (
    <Card
      variant='outlined'
      sx={{
        borderRadius: '10px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column'
      }}>
      <CardContent sx={{ flexGrow: 1 }}>
        <Box display="flex" alignItems="center" mb={1}>
          <Avatar
            src={avatarUrl ?? name}
            alt={name}
            sx={{
              width: 48,
              height: 48,
              mr: 2,
              bgcolor: colors.revolverPurple,
            }}
          />
          <Box>
            <Typography variant="h6" component="div">
              {name}
            </Typography>
            {title && (
              <Typography variant="subtitle2" color="text.secondary">
                {title}
              </Typography>
            )}
          </Box>
        </Box>
        {rating !== undefined && (
          <Box mb={1}>
            <Rating
              value={rating}
              readOnly
              style={{
                fontSize: "30px",
                color: colors.wafer
              }} />
          </Box>
        )}
        <Typography
          variant="body1"
          color="text.secondary"
          style={{
            fontSize: "1em",
            lineHeight: 1.6
          }}
        >
          {comment}
        </Typography>
      </CardContent>
    </Card>
  );
};

interface TestimonialsProps {
  testimonials: TestimonialProps[];
}

// Sample data
const testimonials = [
  {
    name: "Olivia",
    title: "Posh Seller & Data Enthusiast",
    comment: "This tool is incredible! The comprehensive stats on sold listings, including average time to sell and price ranges, are invaluable for my Poshmark strategy. It's super user-friendly and provides insights I've been struggling to find elsewhere.",
    rating: 5,
  },
  {
    name: "Julia",
    title: "12 yr Thrifting Pro",
    comment: "This product research tool is a game-changer for me and other Poshmark sellers. I've been using it to prepare for thrifting trips, and it's dramatically improved my ability to spot profitable items.",
    rating: 5,
  },
  {
    name: "Abbie",
    title: "8 yr Posh Veteran",
    comment: "I think i'm obsessed! I've been selling on Poshmark for years, and this tool is exactly what I've been waiting for. The ability to see detailed stats on sold listings is incredible. It's helped me price my items more effectively and understand market trends.",
    rating: 5,
  }
];

const Testimonials: React.FC<TestimonialsProps> = () => {
  return (
    <Container>
      <Container maxWidth='md' sx={{ pb: 4 }}>
        <Typography
          fontFamily="Libre Baskerville"
          variant="h3"
          align='center'
          sx={{
            fontWeight: 'bold'
          }}
          paddingBottom={4}>Testimonials
        </Typography>
        <Typography
          variant="h4"
          align="center"
          color="textSecondary"
          style={{
            fontSize: "1.2em",
            fontWeight: 400,
            lineHeight: 1.6,
          }}
        >
          Here is what long time Poshmark resellers have to say.
        </Typography>
      </Container>
      <Grid container spacing={3}>
        {testimonials.map((testimonial, index) => (
          <Grid item xs={12} md={4} key={index}>
            <Testimonial {...testimonial} />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Testimonials;