import React from 'react';
import { Box, CardContent, Typography } from '@mui/material';
import Paragraph from '../../components/Paragraph';

const BrandsPageDetails = () => {
  const paragraphStyle = {
    marginBottom: '15px',
    lineHeight: '1.6'
  };

  const h2Style = { fontSize: '1.8rem', marginTop: '20px', marginBottom: '20px', fontFamily: "Libre Baskerville" }
  const sectionStyle = { marginBottom: '80px' }

  return (
    <CardContent>
      <Box style={sectionStyle}>
        <Typography variant='h2' style={h2Style}>Poshmark's Top Brands to Look Out For</Typography>
        <Paragraph variant='body1' style={paragraphStyle}>
          {`Staying informed about the top-selling brands is crucial. 
          
          It doesn’t matter if you have all the time and capital to invest in your Poshmark business. If you source items from low-quality, poorly performing brands and styles, they will sit in your closet and gather dust.
          
          Introducing the ultimate BOLO (brands to look out for) list. 
          
          This resource provides up-to-date sales ranking data for Poshmark's most popular brands. Its purpose is to empower your sourcing decisions by offering insights into what's currently in on the platform.

          By leveraging this list, you can eliminate guesswork when selecting inventory. Quickly pinpoint popular brands, categories, and styles that drive sales, ultimately boosting your profitability.

          These insights include daily sales volumes, total items sold, and average selling prices per brand, ensuring you stay ahead in the competitive world of Poshmark.`}
        </Paragraph>
      </Box>
      <Box style={sectionStyle}>
        <Typography variant='h2' style={h2Style}>What Influences the Rankings?</Typography>
        <Typography variant='body1' style={paragraphStyle}>
          Our list is dynamic, constantly evolving based on various factors. Therefore, conducting thorough research before sourcing is crucial to avoid overstocking on any single item or style.
        </Typography>
        <Typography variant='body1' style={paragraphStyle}>
          Factors That Influence Brand Rankings:
        </Typography>
        <Typography variant='body1' style={paragraphStyle}>
          <ul>
            <li>
              <strong>Consumer Preferences: </strong>
              Brand rankings shift as consumer tastes evolve, with some categories consistently leading while specific brands rise and fall in popularity.
            </li>
            <li>
              <strong>Market Influences: </strong>
              Rapid changes in sentiment can occur due to product releases, marketing campaigns, public relations events, and negative press.
            </li>
            <li>
              <strong>Market Saturation: </strong>
              When a brand's items flood the market, their desirability and demand can decrease, leading to lower sales.
            </li>
            <li>
              <strong>Seasonal Trends: </strong>
              Sales can be affected by seasonal changes, with certain brands and items becoming more popular at specific times of the year.
            </li>
            <li>
              <strong>Emerging Trends and New Brands: </strong>
              New brands and trends can quickly gain popularity, altering the rankings.
            </li>
          </ul>
        </Typography>
      </Box>
      <Box style={sectionStyle}>
        <Typography variant='h2' style={h2Style}>How Do We Uncover Poshmark's Best-Selling Brands?</Typography>
        <Paragraph variant='body1' style={paragraphStyle}>
          {`We analyze Poshmark’s public sales data, which includes over 11,000 brands.
          
          By closely tracking daily sales data and average selling prices, we keep our finger on the pulse of market trends. Our rankings are updated daily to provide precise insights into brand sales performance. 
          
          However, we encounter challenges such as:`}
        </Paragraph>
        <Typography variant='body1' style={paragraphStyle}>
          <ul>
            <li>
              <strong>Misused listings: </strong>
              Listings used for advertising posh shows or sharing information like tips, as if it were a blog post.
            </li>
            <li>
              <strong>Incorrect categorization: </strong>
              For example, a bag labeled as shoes.
            </li>
          </ul>
          To mitigate these issues, we employ filtering methods based on keywords and pricing, ensuring the integrity and quality of our data.
        </Typography>
      </Box>
      <Box style={sectionStyle}>
        <Typography variant='h2' style={h2Style}>
          Are People Really Buying High-Priced Luxury Items on Poshmark?
        </Typography>
        <Paragraph variant='body1' style={paragraphStyle}>
          {`Absolutely!
          
          The data reveals that people are splurging on those high-priced luxury items, which suggests that the deals must be too good to ignore.`}
        </Paragraph>
      </Box>
      <Box style={sectionStyle}>
        <Typography variant='h2' style={h2Style}>
          How Many of These Sales are of Fake Products?
        </Typography>
        <Paragraph variant='body1' style={paragraphStyle}>
          {`The exact number will be impossible to know.

          Why?

          First, some percentage of really good fakes will always slide through the cracks. Not all buyers notice the very fine details that separate an authentic item from a fake.

          Plus, some sellers might not even realize they're selling counterfeit.
   
          In countless posts on reselling and authentication communities, people swear their item is authentic but just want to confirm, only to find out they have a lump of coal. Now, imagine how many more people don't post because they are already convinced or don't know about these communities and services where they can get a second opinion.

          On the bright side, Poshmark has an authentication process and a dedicated team to tackle this issue. So shoppers can feel confident about their purchases.

          Lastly, there is no way to know how many sales or listings are reported as fake, as it isn't public data.`}
        </Paragraph>
      </Box>
      <Box style={sectionStyle}>
        <Typography variant='h2' style={h2Style}> Why Didn't "X" Brand Make The List?
        </Typography>

        <Paragraph variant='body1' style={paragraphStyle}>
          {`This list is always changing.

          Maybe they were once on the list but fell out of favor with consumers, or other market conditions changed, causing them to fall off. Maybe they do well, but don’t have large enough sales volumes to make the top rankings.

          Many factors influence a brand's ability to achieve in high sales volumes. 
          
          Sometimes, it’s all about trends and what’s hot right now. Other times, it could be due to pricing, availability, or the season. Just because a brand or category isn't on the list doesn't mean it isn't going to perform well. 
          
          If you know a brand that sells quickly and yields high profits but doesn't make the list, consider yourself lucky. 
          
          You found a hidden gem. keep sourcing it.`}
        </Paragraph>
      </Box>
      <Box style={sectionStyle}>
        <Typography variant='h2' style={h2Style}>Does This Data Include Active Listings?</Typography>
        <Paragraph variant='body1' style={paragraphStyle}>
          {`This list only includes items that have been sold.

          While it would be interesting to also analyze active listings to calculate a sell-through rate, we are currently focused on completed sales.

          However, incorporating active listings into our analysis is something we're working on for the future.`}
        </Paragraph>
      </Box>
      <Box style={sectionStyle}>
        <Typography variant='h2' style={h2Style}>Were Extreme Outliers Removed?</Typography>
        <Paragraph variant='body1' style={paragraphStyle}>
          {`We strive to eliminate most extreme outliers to ensure the accuracy of our data.

          However, it's possible that some may still slip through. Our goal is to provide the most reliable and clean data possible for your sourcing decisions.`}
        </Paragraph>
      </Box>
      <Box>
        <Typography variant='h2' style={h2Style}>
          How to Use This List?
        </Typography>
        <Paragraph variant='body1' style={paragraphStyle}>
          {`This resource meant to simplify your sourcing research.

          Dive deeper into the data for each brand by clicking its name. This will take you to a dedicated page with more sales metrics, like the highest average prices, popular subcategories, fastest time-to-sell, and more.

          To make the most of this list, check it regularly, especially before each sourcing trip.

          Use the insights to inform your sourcing strategy, and consider diversifying your inventory based on trending brands and categories.`}
        </Paragraph>
      </Box>
    </CardContent>
  );
};

export default BrandsPageDetails;
