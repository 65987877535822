
import React from 'react';
import { Box, Typography, Divider } from '@mui/joy';

const SectionTitle = ({ title }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
      <Typography level="h3" sx={{ mr: 2 }}>{title}</Typography>
      <Divider sx={{ flexGrow: 1, mt: 2.3 }} />
    </Box>
  );
};

export default SectionTitle;