import React, { useMemo } from 'react';
import { Card, Typography, Input, Button, Box } from '@mui/joy';
import EmailIcon from '@mui/icons-material/Email';
import useNewsletterSubscription from './useNewsletterSubscription.ts';
import { colors } from '../../customTheme.js';

const NewsletterPanelV2 = () => {
  const {
    email,
    success,
    error,
    loading,
    handleEmailChange,
    handleSubscribe
  } = useNewsletterSubscription();

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSubscribe();
    }
  };

  const hasError = useMemo(() => {
    return error !== null;
  }, [error]);

  const renderForm = () => {
    if (success) {
      return (
        <Card
          variant="soft"
          color="success"
          sx={{
            mt: 2,
            py: 1,
            backgroundColor: '#d3fbeb',
            '--joy-palette-success-softBg': '#d3fbeb',
            '--joy-palette-success-softColor': colors.elfGreen,
          }}
        >
          <Typography
            level="body-md"
            textAlign="center"
          >
            Success! Now check your email to confirm your subscription.
          </Typography>
        </Card>
      );
    }

    return (
      <>
        <Typography
          level="h2"
          sx={{
            fontFamily: "Libre Baskerville",
            fontSize: '1.5rem',
            mb: 0.3
          }}
        >
          Stay up to date
        </Typography>

        <Typography
          level="body-sm"
          sx={{
            color: 'text.secondary',
            mb: 0.8
          }}
        >
          Get weekly insights into Poshmark's top sellers and trending brands.
        </Typography>

        <form onSubmit={(e) => { e.preventDefault(); handleSubscribe(); }} style={{ display: 'flex', gap: '0.5rem' }}>
          <Box
            sx={{
              flexGrow: 1,
              display: 'flex',
              flexDirection: 'column',
              gap: '0.25rem'
            }}
          >
            <Input
              disabled={loading}
              size="sm"
              placeholder="Enter your email"
              value={email}
              startDecorator={<EmailIcon />}
              onChange={(e) => handleEmailChange(e)}
              onKeyDown={handleKeyDown}
              error={hasError}
              sx={{
                '&:focus': {
                  borderColor: 'primary.500',
                },
              }}
            />
            {error && (
              <Typography
                level="body-xs"
                color="danger"
                sx={{ ml: 1 }}
              >
                {error}
              </Typography>
            )}
          </Box>
          <Button
            size="sm"
            type="submit"
            loading={loading}
            sx={{
              height: '30px',
              bgcolor: 'primary.500',
              color: 'white',
              '&:hover': {
                bgcolor: 'primary.600',
              },
            }}
          >
            Subscribe
          </Button>
        </form>
      </>
    );
  };

  return (
    <Box sx={{ maxWidth: '680px' }}>
      {renderForm()}
    </Box>
  );
};

export default NewsletterPanelV2;