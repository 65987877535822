import React, { useState } from 'react';
import { MyFirebase } from '../api/my_firebase.ts';
import GoogleLogo from './svgs/GoogleLogo.js';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import { appWelcomeSequence } from "../api/client.js";

const SignInCard = ({ onSignIn, register = false } = { onSignIn: () => { } }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const handleGoogleSignIn = async () => {
    try {
     const { user } = await MyFirebase.getInstance().signInWithGoogle();
     console.log("google sign in", user)
      if (user && user.email) {
        console.log("welcoming", user)
        await appWelcomeSequence(user.email);
      }
      if (onSignIn) onSignIn();
      console.log("Successfully signed in with Google");
    } catch (e) {
      console.error(e);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleFacebookSignIn = async () => {
    try {
      const { user } = await MyFirebase.getInstance().signInWithFacebook();
      if (user && user.email) {
        // Call your internal API with the user's email
        await appWelcomeSequence(user.email);
      }
      if (onSignIn) onSignIn();
      console.log("Successfully signed in with Facebook");
    } catch (e) {
      console.error(e);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const handleAppleSignIn = async () => {
    try {
      const { user } = await MyFirebase.getInstance().signInWithApple();
      if (user && user.email) {
        // Call your internal API with the user's email
        await appWelcomeSequence(user.email);
      }
      if (onSignIn) onSignIn();
      console.log("Successfully signed in with Apple");
    } catch (e) {
      console.error(e);
    }
  };

  const handleEmailSignIn = async () => {
    try {
      const result = await MyFirebase.getInstance().signInWithEmail(email, password);
      if (result) {
        if (onSignIn) onSignIn();
        console.log("Successfully signed in with email and password");
      } else {
        setError("Invalid email or password.");
      }
    } catch (e) {
      setError("Failed to sign in with email and password.");
      console.error(e);
    }
  };

  const handleEmailRegistration = async () => {
    try {
      const results = await MyFirebase.getInstance().registerWithEmail(email, password);
      if (results.user && results.user.email) {
        console.log("sign in", email)
        await appWelcomeSequence(email);
        if (onSignIn) onSignIn();
        console.log("Successfully registered with email and password");
      } else {
        setError("Invalid email or password.");
      }
    } catch (e) {
      setError("Failed to register with email and password. This email address may already be in use.");
    }
  };

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
        <Button
          onClick={handleGoogleSignIn}
          variant="contained"
          color="primary"
          fullWidth
          style={{
            boxShadow: 'none',
            backgroundColor: '#f2f2f2',
            color: 'black',
            textTransform: 'none',
            marginBottom: '10px',
            height: '40px'
          }}
          startIcon={<GoogleLogo />}
        >
          Continue with Google
        </Button>

        <Box paddingBottom={2} paddingTop={1}>
          <Divider textAlign="center">or continue with email</Divider>
        </Box>

        <TextField
          size="small"
          label="Email"
          variant="outlined"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          size="small"
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          onClick={register ? handleEmailRegistration : handleEmailSignIn}
          variant="contained"
          color="primary"
          fullWidth
          style={{
            boxShadow: 'none',
            textTransform: 'none',
            marginBottom: '10px',
            height: '40px'
          }}
        >
          {register ? "Register with Email" : "Sign In with Email"}
        </Button>

        {error && <Alert severity="error">{error}</Alert>}
      </div>
    </div>
  );
}

export default SignInCard;