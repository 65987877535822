import React, { useState } from 'react';
import {
  Box,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { darkTheme } from '../customTheme';
import SearchIcon from '@mui/icons-material/Search';
import PATHS from '../utils/paths';
import SearchSuggestions from './SearchSuggestions.tsx';
import { useNavigate } from 'react-router-dom';

const ToolsCard: React.FC = () => {
  const [query, setQuery] = useState('');
  const navigate = useNavigate();

  const handleProductSearch = () => {
    if (query.trim() !== '') {
      navigate(`${PATHS.PRODUCT_RESEARCH}?q=${query}`);
    }
  };

  return (
    <ThemeProvider theme={darkTheme}>
      <Box>
        <TextField
          fullWidth
          variant="filled"
          placeholder="Enter a product description"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleProductSearch();
            }
          }}
          sx={{
            mt: 0,
            '& .MuiFilledInput-root': {
              backgroundColor: 'white', // Set background to white
              borderRadius: '300px', // Rounded corners
              height: '60px', // Increase height
              fontSize: '18px', // Increase font size
              display: 'flex', // Flex container for alignment
              alignItems: 'center', // Center items vertically
              '&:hover': {
                backgroundColor: 'white', // Preserve white on hover
              },
              '&.Mui-focused': {
                backgroundColor: 'white', // Preserve white on focus
              },
            },
            '& .MuiFilledInput-input': {
              padding: '15px 12px', // Adjust padding for better alignment
              fontSize: '18px', // Match font size
            },
            '& .MuiInputAdornment-root': {
              height: '100%', // Match the height of the TextField
              display: 'flex', // Flex container for icon alignment
              alignItems: 'center', // Center icons vertically
            },
            '& .MuiInputBase-input': {
              color: 'black', // Black text color
            },
            '& .MuiFilledInput-underline': {
              '&:before, &:after': {
                display: 'none !important',
                border: 'none !important',
                borderBottom: 'none !important',
              },
              '&:hover:before': {
                display: 'none !important',
                border: 'none !important',
                borderBottom: 'none !important',
              }
            },
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleProductSearch}
                  disabled={query.trim() === ''}
                >
                  <SearchIcon sx={{ fontSize: 34 }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <SearchSuggestions
          count={3}
          variant='filled'
          borderRadius='40px'
          onSuggestionClick={(suggestion) => {
            navigate(`${PATHS.PRODUCT_RESEARCH}?q=${suggestion}`)
          }}
        />
      </Box>
    </ThemeProvider >
  );
};

export default ToolsCard;