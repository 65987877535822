import { sendToBackgroundViaRelay } from "@plasmohq/messaging";

export const checkExtensionInstall = async () => {
  const TIMEOUT_DURATION = 1000;

  const timeoutPromise = new Promise((_, reject) =>
    setTimeout(() => reject(new Error("Timeout")), TIMEOUT_DURATION)
  );

  try {
    const resp = await Promise.race([
      sendToBackgroundViaRelay({
        name: "check_extension_install",
      }),
      timeoutPromise
    ]);

    return resp;
  } catch (error) {
    return null;
  }
};
