import React from 'react';
import { Box, Typography, Container } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import RedditIcon from '@mui/icons-material/Reddit';
import Stack from '@mui/material/Stack';
import Banner from '../images/poshwatch-banner.png';
import SadGIF from '../images/fran-sad.gif';

export const UnsubscribePage = () => {
  return (
    <Container maxWidth={false} sx={styles.page}>
      <Box sx={styles.header}>
        <img
          src={Banner}
          alt="Poshwatch banner"
          style={styles.logo}
        />
      </Box>
      <Box sx={styles.unsubscribeSection}>
        <Typography variant="h5" sx={styles.heading}>
          You are unsubscribed
        </Typography>
        <Typography sx={{marginBottom: '30px'}}>
          We're <strong>really</strong> sorry to see you go!
        </Typography>
        <img
          src={SadGIF}
          alt="The Nanny crying"
          style={{ width: '325px', height: 'auto', margin: '0 auto' }}
        />
        <Typography variant="body1" sx={styles.message}>
          If you change your mind, you can always re-subscribe. 
          If you're not ready to break up with us fully, you can still get updates by 
          joining our Reddit or Facebook communities.
        </Typography>
        <Box sx={styles.iconSection}>
          <Stack direction="row" spacing={2}>
            <a href="https://www.reddit.com/r/poshwatch/" target="_blank" rel="noopener noreferrer">
              <RedditIcon fontSize="large" sx={{ color: "#F63D15" }} />
            </a>
            <a href="https://www.facebook.com/groups/poshwatch" target="_blank" rel="noopener noreferrer">
              <FacebookIcon fontSize="large" sx={{ color: "#2053FF" }} />
            </a>
          </Stack>
        </Box>
        <Typography variant="body1" sx={styles.footerText}>
          Miss you already 😢
        </Typography>
      </Box>
    </Container>
  );
}

const styles = {
  page: {
    textAlign: 'center',
    backgroundColor: '#F7F7F7',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '10px',
  },
  logo: {
    height: '175px',
  },
  unsubscribeSection: {
    backgroundColor: 'white',
    borderRadius: '10px',
    marginBottom: '20px',
    maxWidth: '50%',
  },
  iconSection: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '20px',
    marginBottom: '20px',
  },
  heading: {
    marginBottom: '30px',
    marginTop: '40px',
    fontFamily: 'Libre Baskerville',
  },
  gif: {
    width: '300px',
    height: 'auto',
    display: 'block',
  },
  message: {
    margin: '30px 30px',
    padding: '0px 50px',
  },
  footerText: {
    fontSize: '1.2rem',
    fontWeight: 'bold',
    marginBottom: '40px',
  },
};
