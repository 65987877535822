export const splitIntoParagraphs = (text: string, targetParagraphs: number = 3): string[] => {
  if (!text) return [];
  
  // Remove extra whitespace and split into sentences
  const sentences = text
    .replace(/([.!?])\s+/g, "$1|")
    .split("|")
    .filter(sentence => sentence.trim().length > 0);

  if (sentences.length <= targetParagraphs) {
    return sentences;
  }

  // Calculate approximate sentences per paragraph
  const sentencesPerParagraph = Math.ceil(sentences.length / targetParagraphs);
  
  // Distribute sentences into paragraphs
  const paragraphs: string[] = [];
  for (let i = 0; i < sentences.length; i += sentencesPerParagraph) {
    const paragraph = sentences
      .slice(i, i + sentencesPerParagraph)
      .join(" ")
      .trim();
    
    if (paragraph) {
      paragraphs.push(paragraph);
    }
  }

  return paragraphs;
};