import React from 'react';
import { Box, Sheet, Skeleton, Stack, Grid } from '@mui/joy';

const MetricCard = () => (
  <Sheet
    variant="outlined"
    sx={{
      borderRadius: 'md',
      p: 2,
      width: '100%',
      height: '100%',
    }}
  >
    <Skeleton variant="text" width="100%" height={100} />
    <Skeleton variant="text" width="40%" height={20} />
  </Sheet>
);

const DonutChart = () => (
  <Sheet
    variant="outlined"
    sx={{
      borderRadius: 'md',
      p: 2,
      width: '100%',
      height: '100%',
    }}
  >
    <Skeleton
      variant="circular"
      width={200}
      height={200}
      sx={{ mx: 'auto', mb: 2 }}
    />
    {[...Array(4)].map((_, i) => (
      <Stack key={i} direction="row" spacing={1} sx={{ mb: 1 }}>
        <Skeleton variant="circular" width={10} height={10} />
        <Skeleton variant="text" width="60%" />
      </Stack>
    ))}
  </Sheet>
);

const TopReseller = () => (
  <Stack direction="row" spacing={2} alignItems="center" sx={{ width: '100%' }}>
    <Skeleton variant="circular" width={40} height={40} />
    <Skeleton variant="text" width={120} />
    <Skeleton variant="text" width={80} sx={{ ml: 'auto' }} />
  </Stack>
);

const DetailsSkeleton = () => (
  <Box sx={{ pt: 4 }}>
    {/* Metrics Grid */}
    <Grid container spacing={2}>
      <Grid xs={12} sm={6} md={4}>
        <MetricCard />
      </Grid>
      <Grid xs={12} sm={6} md={4}>
        <MetricCard />
      </Grid>
      <Grid xs={12} sm={6} md={4}>
        <MetricCard />
      </Grid>

      <Grid xs={12} sm={6} md={4}>
        <DonutChart />
      </Grid>
      <Grid xs={12} sm={6} md={4}>
        <DonutChart />
      </Grid>
      <Grid xs={12} sm={6} md={4}>
        <DonutChart />
      </Grid>

      <Sheet
        variant="outlined"
        sx={{
          borderRadius: 'md',
          p: 2,
          m: 1,
          width: '100%',
        }}
      >
        <Stack spacing={2}>
          <Skeleton variant="text" width={300} height={32} /> {/* Title skeleton */}
          {[...Array(5)].map((_, i) => (
            <TopReseller key={i} />
          ))}
        </Stack>
      </Sheet>
    </Grid>


  </Box>
);

export default DetailsSkeleton;