import { useState } from 'react';
import { subscribeWithEmail } from '../../api/client';

const useNewsletterSubscription = () => {
  const [email, setEmail] = useState('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e) => {
    setError(null);
    setEmail(e.target.value);
  };

  const handleSubscribe = async () => {
    if (validateEmail(email)) {
      setError(null);
      setLoading(true);

      try {
        const success = await subscribeWithEmail(email);
        
        if (success) {
          setSuccess(true);
        } else {
          setError("An error occurred, please try again later");
        }
      } catch (err) {
        setError("An error occurred, please try again later");
      } finally {
        setLoading(false);
      }
    } else {
      setError("Invalid email address");
      setLoading(false);
    }
  };

  return {
    email,
    success,
    error,
    loading,
    handleEmailChange,
    handleSubscribe,
  };
};

export default useNewsletterSubscription;