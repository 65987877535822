import React from "react";
import { Analyzer } from "../Analyzer";
import { fetchListingAnalysis } from "../../api/client";
import PATHS from '../../utils/paths';

export const PoshmarkAnalyzer = () => {
  return (
    <Analyzer 
      platform="Poshmark"
      canonicalUrl ={PATHS.POSHMARK_LISTING_ANALYZER}
      getListingAnalysisCallback={fetchListingAnalysis}
    />
  );
};
