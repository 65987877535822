import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { TwitterCard } from './TwitterCard.tsx';
import { tweets, pies, bars, topData } from './data.ts';
import { DataProvider } from './DataProvider.tsx';
import ChartPost from './ChartPost.tsx';
import TopFive from './TopFive.tsx';
import { Typography } from '@mui/material';

const PostGenerator: React.FC = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');

  const renderContent = () => {
    if (type === 'tweet') {
      return (
        <DataProvider initialData={tweets}>
          <TwitterCard />
        </DataProvider>
      );
    } else if (type === 'pie') {
      return (
        <DataProvider initialData={pies}>
          <ChartPost chartType={type} />
        </DataProvider>
      );
    } else if (type === 'top5') {
      return (
        <DataProvider initialData={topData}>
          <TopFive />
        </DataProvider>
      );
    } else if (type === 'bar') {
      return (
        <DataProvider initialData={bars}>
          <ChartPost chartType={type} />
        </DataProvider>
      )
    }
    return <Typography>Type must be: tweet, pie, bar, top5</Typography>;
  };

  return (
    <div>
      {renderContent()}
    </div>
  );
};

export default PostGenerator;