import * as React from 'react';
import Box from '@mui/joy/Box';
import Table from '@mui/joy/Table';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import FormControl from '@mui/joy/FormControl';
import FormLabel from '@mui/joy/FormLabel';
import IconButton from '@mui/joy/IconButton';
import Link from '@mui/joy/Link';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { visuallyHidden } from '@mui/utils';
import { ToggleButtonGroup, Button as ToggleButton } from '@mui/joy';
import Chip from '@mui/joy/Chip';

export interface TableData {
  [key: string]: string | number;
}

interface HeadCell {
  id: string;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
  width?: string;
  hideWhenAvailable?: boolean;
  render?: (row: TableData) => React.ReactNode;
  formatter?: (value: any) => React.ReactNode;
}

interface CustomDataTableProps {
  hideFooter?: boolean;
  rows: TableData[];
  headers: readonly HeadCell[];
  toolbar?: React.ReactNode;
  minWidth?: string;
  onRowClick?: (row: TableData) => void;
}

function labelDisplayedRows({
  from,
  to,
  count,
}: {
  from: number;
  to: number;
  count: number;
}) {
  return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
}

// Compare two values and return:
// -1 if a should come before b
//  1 if b should come before a
//  0 if they're equal
function descendingComparator(a: TableData, b: TableData, orderBy: string) {
  const valueA = a[orderBy];
  const valueB = b[orderBy];

  if (valueB < valueA) return -1;  // b should come after a
  if (valueB > valueA) return 1;   // b should come before a
  return 0;                        // they're equal
}

// Create a sorting function based on direction ('asc' or 'desc')
function getComparator(order: 'asc' | 'desc', orderBy: string) {
  // For descending order, use the comparator as-is
  // For ascending order, flip the comparison by adding a minus sign
  return (a: TableData, b: TableData) => {
    if (order === 'desc') {
      return descendingComparator(a, b, orderBy);
    } else {
      return -descendingComparator(a, b, orderBy);
    }
  };
}

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  order: 'asc' | 'desc';
  orderBy: string;
  rowCount: number;
  headers: readonly HeadCell[];
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort, headers } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <thead>
      <tr>
        {headers.map((header) => {
          const active = orderBy === header.id;
          return (
            <th
              key={header.id}
              style={{
                width: header.width || 'auto',
                textAlign: header.numeric ? 'right' : 'left'
              }}
              aria-sort={
                active
                  ? ({ asc: 'ascending', desc: 'descending' } as const)[order]
                  : undefined
              }
            >
              <Link
                underline="none"
                color="neutral"
                textColor={active ? 'primary.plainColor' : undefined}
                component="button"
                onClick={createSortHandler(header.id)}
                startDecorator={
                  header.numeric ? (
                    <ArrowDownwardIcon
                      sx={[active ? { opacity: 1 } : { opacity: 0 }]}
                    />
                  ) : null
                }
                endDecorator={
                  !header.numeric ? (
                    <ArrowDownwardIcon
                      sx={[active ? { opacity: 1 } : { opacity: 0 }]}
                    />
                  ) : null
                }
                sx={{
                  fontWeight: 'lg',
                  '& svg': {
                    transition: '0.2s',
                    transform:
                      active && order === 'desc' ? 'rotate(0deg)' : 'rotate(180deg)',
                  },

                  '&:hover': { '& svg': { opacity: 1 } },
                }}
              >
                {header.label}
                {active ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </Link>
            </th>
          );
        })}
      </tr>
    </thead>
  );
}

interface EnhancedTableToolbarProps {
  title?: string;
  showAvailable?: boolean;
  onToggleAvailable?: () => void;
}

export function EnhancedTableToolbar({
  title,
  showAvailable,
  onToggleAvailable
}: EnhancedTableToolbarProps) {
  return (
    <Box
      sx={[
        {
          display: 'flex',
          alignItems: 'center',
          py: 1,
          pl: 2,
          pr: 1,
          borderTopLeftRadius: 'var(--unstable_actionRadius)',
          borderTopRightRadius: 'var(--unstable_actionRadius)',
          bgcolor: 'var(--joy-palette-background-level1)',
        },
      ]}
    >
      {title && <Typography
        level="body-lg"
        sx={{ flex: '1 1 100%' }}
        id="tableTitle"
        component="div"
      >
        {title}
      </Typography>}
      {onToggleAvailable && (
        <ToggleButtonGroup
          value={showAvailable ? 'available' : 'sold'}
          onChange={(_, value) => {
            if (value !== null) {
              onToggleAvailable();
            }
          }}
          size="sm"
          sx={{ mr: 1 }}
        >
          <ToggleButton value="sold">Sold</ToggleButton>
          <ToggleButton value="available">Available</ToggleButton>
        </ToggleButtonGroup>
      )}
    </Box>
  );
}

const ImageCell = ({ row }: { row: TableData }) => (
  <Box sx={{
    width: { xs: 60, sm: 80 },
    height: { xs: 60, sm: 80 },
    overflow: 'hidden',
    borderRadius: '8px'
  }}>
    <Box
      component="img"
      src={row.image.toString()}
      alt={row.title.toString()}
      sx={{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
        transition: 'transform 0.2s ease-in-out',
        borderRadius: '8px',
        'tr:hover &': {
          transform: 'scale(1.1)',
        }
      }}
    />
  </Box>
);

const TitleCell = ({ row }: { row: TableData }) => (
  <Box>
    <Typography
      level="body-md"
      sx={{
        fontSize: '1rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {row.title}
    </Typography>
    <Typography
      level="body-sm"
      sx={{
        color: 'text.secondary',
        fontWeight: 500
      }}
    >
      <Chip
        size="sm"
        variant="soft"
        color={row.daysSinceSale !== null ? "danger" : "success"}
      >
        {row.daysSinceSale !== null ? "Sold" : "Available"}
      </Chip>
    </Typography>
  </Box>
);

export default function CustomDataTable({ hideFooter = true, rows, headers, toolbar, minWidth, onRowClick }: CustomDataTableProps) {
  const [order, setOrder] = React.useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = React.useState<string>('soldDate');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: any, newValue: number | null) => {
    setRowsPerPage(parseInt(newValue!.toString(), 10));
    setPage(0);
  };

  const getLabelDisplayedRowsTo = () => {
    if (rows.length === -1) {
      return (page + 1) * rowsPerPage;
    }
    return rowsPerPage === -1
      ? rows.length
      : Math.min(rows.length, (page + 1) * rowsPerPage);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Sheet
      variant="outlined"
      sx={{ width: '100%', borderRadius: 'sm' }}
    >
      {toolbar}
      <Box sx={{ overflow: 'auto' }}>
        <Table
          aria-labelledby="tableTitle"
          hoverRow
          sx={{
            '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
            '& td, & th': { px: 2 },
            tableLayout: 'fixed',
            width: '100%',
            ...(minWidth && { minWidth }),
          }}
        >
          <EnhancedTableHead
            headers={headers}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <tbody>
            {[...rows]
              .sort(getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <tr
                  key={row.id}
                  tabIndex={-1}
                  onClick={() => onRowClick?.(row)}
                  style={{ cursor: onRowClick ? 'pointer' : 'default' }}
                >
                  {headers.map((header) => {
                    let content: React.ReactNode;
                    if (header.render) {
                      content = header.render(row);
                    } else if (header.formatter) {
                      content = header.formatter(row[header.id]);
                    } else {
                      switch (header.id) {
                        case 'image':
                          content = <ImageCell row={row} />;
                          break;
                        case 'title':
                          content = <TitleCell row={row} />;
                          break;
                        default:
                          content = row[header.id];
                      }
                    }

                    return (
                      <td
                        key={header.id}
                        style={{
                          textAlign: header.numeric ? 'right' : 'left',
                          width: header.width
                        }}
                      >
                        {content}
                      </td>
                    );
                  })}
                </tr>
              ))}
            {emptyRows > 0 && (
              <tr
                style={
                  {
                    height: `calc(${emptyRows} * 40px)`,
                    '--TableRow-hoverBackground': 'transparent',
                  } as React.CSSProperties
                }
              >
                <td colSpan={headers.length} aria-hidden />
              </tr>
            )}
          </tbody>
          {!hideFooter && (
            <tfoot>
              <tr>
                <td colSpan={headers.length}>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 2,
                      justifyContent: 'flex-end',
                    }}
                  >
                    <FormControl orientation="horizontal" size="sm">
                      <FormLabel>Rows per page:</FormLabel>
                      <Select onChange={handleChangeRowsPerPage} value={rowsPerPage}>
                        <Option value={25}>25</Option>
                        <Option value={100}>100</Option>
                        <Option value={250}>250</Option>
                      </Select>
                    </FormControl>
                    <Typography sx={{ textAlign: 'center', minWidth: 80 }}>
                      {labelDisplayedRows({
                        from: rows.length === 0 ? 0 : page * rowsPerPage + 1,
                        to: getLabelDisplayedRowsTo(),
                        count: rows.length === -1 ? -1 : rows.length,
                      })}
                    </Typography>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      <IconButton
                        size="sm"
                        color="neutral"
                        variant="outlined"
                        disabled={page === 0}
                        onClick={() => handleChangePage(page - 1)}
                        sx={{ bgcolor: 'background.surface' }}
                      >
                        <KeyboardArrowLeftIcon />
                      </IconButton>
                      <IconButton
                        size="sm"
                        color="neutral"
                        variant="outlined"
                        disabled={
                          rows.length !== -1
                            ? page >= Math.ceil(rows.length / rowsPerPage) - 1
                            : false
                        }
                        onClick={() => handleChangePage(page + 1)}
                        sx={{ bgcolor: 'background.surface' }}
                      >
                        <KeyboardArrowRightIcon />
                      </IconButton>
                    </Box>
                  </Box>
                </td>
              </tr>
            </tfoot>
          )}
        </Table>
      </Box>
    </Sheet>
  );
}
