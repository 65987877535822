import React from 'react';
import { Box, CardContent, Typography } from '@mui/material';
import Paragraph from '../../components/Paragraph';

const CategoryPageDetails = () => {
  const paragraphStyle = {
    marginBottom: '15px',
    lineHeight: '1.6'
  };

  const h2Style = { fontSize: '1.8rem', marginTop: '20px', marginBottom: '20px', fontFamily: "Libre Baskerville" }
  const sectionStyle = { marginBottom: '80px' }

  return (
    <CardContent>
      <Box style={sectionStyle}>
        <Typography variant='h2' style={h2Style}>Top Categories On Poshmark</Typography>
        <Paragraph variant='body1' style={paragraphStyle}>
          {`Sourcing high-demand and high-value categories ensures your inventory sells quickly.

            Our resource offers the latest data on Poshmark's most popular categories and subcategories to help your business avoid stagnation.

            The goal is to help you make informed decisions when sourcing for your closet and eliminate the guesswork. So that you can quickly identify top-selling items and increase your sales.
          `}
        </Paragraph>
      </Box>
      <Box style={sectionStyle}>
        <Typography variant='h2' style={h2Style}>What Influences Our Category Rankings?</Typography>
        <Paragraph variant='body1' style={paragraphStyle}>
          {`Like the Poshmark marketplace, this list is always changing.

            Therefore, thorough research on items while sourcing is crucial to avoid overstocking on one item or style.

            While some categories consistently perform well, the popularity of brands within those categories can change rapidly. You don’t want your closet to be clogged with items that are no longer in demand. 
          `}
        </Paragraph>
      </Box>
      <Box style={sectionStyle}>
        <Typography variant='h2' style={h2Style}>How Do We Uncover Poshmark's Best Selling Categories?</Typography>
        <Paragraph variant='body1' style={paragraphStyle}>
          {`We analyze Poshmark's public sales data to determine which categories have the highest sales volumes.`}
        </Paragraph>
      </Box>
      <Box style={sectionStyle}>
        <Typography variant='h2' style={h2Style}>Are People Actually Buying High-Priced Luxury Items on Poshmark?</Typography>
        <Paragraph variant='body1' style={paragraphStyle}>
          {`Yes, people do buy high-priced luxury items on Poshmark. 

            As the data suggests, shoppers are willing to splurge on Poshmark. Likely because the deals are too good to resist compared to buying at retail value.`}
        </Paragraph>
      </Box>
      <Box style={sectionStyle}>
        <Typography variant='h2' style={h2Style}>How Many of These Sales are of Fake Products?</Typography>
        <Paragraph variant='body1' style={paragraphStyle}>
          {`The exact number of counterfeit sales is unknown.

            Some high-quality fakes can slip through undetected, as not all buyers can distinguish fakes from authentic items.
            
            Additionally, some sellers might unknowingly sell counterfeits. Nonetheless, Poshmark has an authentication process and a team dedicated to tackling this issue, which helps build shopper confidence. 
            
            Unfortunately, the number of reported fake sales or listings is not public data.
          `}
        </Paragraph>
      </Box>
      <Box style={sectionStyle}>
        <Typography variant='h2' style={h2Style}> Why Didn't X Category Rank Higher or Make The List?</Typography>
        <Paragraph variant='body1' style={paragraphStyle}>
          {`The rankings of categories will always change.

            A once higher category may have fallen out of favor with consumers due to other market conditions. Sometimes, a category may perform well but not have enough sales volume to rank highly. Factors like trends, pricing, availability, and seasonality influence a brand's sales volume.
            
            If you know of a subcategory that sells quickly and yields high profits but doesn’t make the list, consider it a hidden gem. Continue sourcing it.`}
        </Paragraph>
      </Box>
      <Box>
        <Typography variant='h2' style={h2Style}>How to Use This Resource?</Typography>
        <Paragraph variant='body1' style={paragraphStyle}>
          {`This resource simplifies your sourcing research.

            It helps you decide whether to add an item to your inventory.

            To make the most of the resource, check it regularly, especially before each sourcing trip. Use the insights to inform your sourcing strategy and consider diversifying your inventory based on trending brands and categories.`}
        </Paragraph>
      </Box>
    </CardContent>
  );
};

export default CategoryPageDetails;
