import React from 'react';
import { Container } from '@mui/material';
import { ThemeProvider } from "@mui/material/styles";
import { lightTheme } from '../customTheme';

const Panel = ({ theme = lightTheme, backgroundColor, backgroundImage, children, paddingSize = 'md', style }) => {
  const paddingSizes = {
    xxs: 10,
    xs: 20,
    sm: 40,
    md: 80,
    lg: 120,
  };

  const padding = paddingSizes[paddingSize] || 0;

  return (
    <ThemeProvider theme={theme}>
      <Container
        sx={{
          padding: {
            xs: 0,
            sm: 2,
          },
        }}
        maxWidth={false}
        style={{
          ...style,
          background: backgroundColor ?? theme.palette.background.default,
          backgroundImage: backgroundImage,
          paddingTop: padding,
          paddingBottom: padding,
        }}
      >
        {children}
      </Container>
    </ThemeProvider>
  );
};

export default Panel;
