import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useData } from './DataProvider.tsx';
import { TopData } from './data';
import PostTemplate from './PostTemplate.tsx';

const TopFive: React.FC = () => {
  const { currentData, data } = useData<TopData>();

  if (!currentData) return null;

  return (
    <PostTemplate theme="light" numScreenshots={data.length}>
      <Box
        textAlign="center"
        sx={{
          paddingLeft: '2rem',
          paddingRight: '4em', // There is an issue with the screenshots where the right padding is not there if we use 2em
        }}
      >
        <Typography
          fontFamily="Libre Baskerville"
          variant="h3"
          style={{
            fontSize: '4rem'
          }}
        >
          {currentData.title}
        </Typography>
        <Typography
          variant="h6"
          style={{
            fontSize: '2rem'
          }}
        >
          {currentData.description}
        </Typography>
        <Typography
          variant="h6"
          style={{
            opacity: 0.8,
            fontSize: '1.2rem'
          }}
          sx={{
            pb: '2rem',
          }}
        >
          {currentData.date}
        </Typography>

        <Grid container spacing={4} justifyContent="center">
          {currentData.items.map((item) => (
            <Grid
              item
              xs={4}
              key={item.rank}
              sx={{ textAlign: 'center', position: 'relative' }}
            >
              <Box
                sx={{
                  position: 'relative',
                  borderRadius: '0.8rem',
                  overflow: 'hidden',
                  backgroundColor: '#f5f5f5',
                }}
              >
                <Box
                  component="img"
                  src={item.image}
                  alt={item.label}
                  sx={{
                    width: '100%',
                    objectFit: 'cover',
                    borderRadius: '0.4rem',
                  }}
                />
                <Typography
                  variant="h4"
                  component="span"
                  sx={{
                    position: 'absolute',
                    margin: 0,
                    bottom: 0,
                    left: 0,
                    color: 'black',
                    opacity: 1,
                    paddingLeft: '1rem',
                    paddingRight: '1rem',
                    borderTopRightRadius: '0.8rem',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  }}
                  style={{ fontSize: '5rem' }}
                >
                  {item.rank}
                </Typography>
              </Box>
              <Typography
                variant="body1"
                sx={{ paddingTop: '1rem', }}
                style={{
                  fontSize: '2rem',
                }}
              >
                {item.label}
              </Typography>
              {item.subtitle && <Typography
                variant="body1"
                color="textSecondary"
                style={{
                  fontSize: '1.5rem',
                }}
              >
                {item.subtitle}
              </Typography>}
            </Grid>
          ))}
        </Grid>
      </Box>
    </PostTemplate>
  );
};

export default TopFive;