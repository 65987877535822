const GREYS = [
  '#1A202C', '#2D3748', '#3B4858', '#4A5568',
  '#5E6B7E', '#718096', '#8795AD', '#A0AEC0'
];

const GREENS = [
  '#0A3622', '#0F4A30', '#155C3D', '#1B6F4A',
  '#238157', '#2B9364', '#33A571', '#3CB77F'
];

const BLUES = [
  '#1A365D', '#2A4A7F', '#3B619F', '#4C78BF',
  '#5D8FDF', '#6EA5FF', '#7FBCFF', '#90D2FF'
];

const REDS = [
  '#63171B', '#822025', '#A1292F', '#C0323A',
  '#DF3B44', '#FF444E', '#FF5D67', '#FF7680'
];

const YELLOWS = [
  '#744210', '#975A16', '#B7791F', '#D69E2E',
  '#F6AD55', '#FAC364', '#FED273', '#FFF382'
];

const PINKS = [
  '#521B41', '#702459', '#8E2D71', '#AC3689',
  '#CA3FA1', '#E84FB9', '#FF63D1', '#FF77E9'
];

const PURPLES = [
  '#322659', '#44337A', '#553C9A', '#6B46C1',
  '#805AD5', '#9562E8', '#A97FFB', '#BD9EFF'
];

const COLOR_PALETTES = [
  GREYS,
  GREENS,
  BLUES,
  REDS,
  YELLOWS,
  PINKS,
  PURPLES
];

export function getColorPallete() {
  return COLOR_PALETTES[Math.floor(Math.random() * COLOR_PALETTES.length)];
}
