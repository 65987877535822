import { colors } from '../customTheme';
import Buttons from '../images/how_to_identify_quality/buttons.png';
import Zippers from '../images/how_to_identify_quality/zipper.png';
import Closures from '../images/how_to_identify_quality/closures.png';
import Pockets from '../images/how_to_identify_quality/pockets.png';
import Seams from '../images/how_to_identify_quality/seams.png';
import Serged from '../images/how_to_identify_quality/serged.png';
import PrintJacquard from '../images/how_to_identify_quality/print-vs-jacquard.png';
import LingingQuality from '../images/how_to_identify_quality/lining-quality.png';
import LowQualityTags from '../images/how_to_identify_quality/low-quality-tags.png';
import HighQualityTags from '../images/how_to_identify_quality/high-quality-tags.png';


const howToIdentifyQuality = `
~~~~light,${colors.white}
# Top Seller's Guide To Identifying High Quality Clothing

<div style="text-align: center; font-family: Inter; margin-bottom: 6px">
Last updated Jul. 19, 2024
</div>
<div style="text-align: center; font-family: Inter">
Written by Alice
</div>
\n&nbsp;\n

So you aspire to be a top seller, and swear it means selling large quantities. Well, I have have some good news for you.

Welcome, grab your favorite drink and get comfy because this is my favorite topic! It’s as the old saying goes, “quality over quantity”. This guide is crafted to arm you with the knowledge necessary to discern the subtle nuances that define quality in clothing. As a reseller, this expertise offers a distinct competitive edge, enabling you to curate a collection that stands out in the marketplace. 

Before we dive in, I want to dispel a common misconception: 

high price tags and coveted brand names do not always signify superior quality. Quality exists independently of brand identity, and value is dictated only by market demand, not by the perceived prestige of high-end labels. Therefore, let your decisions be guided by what the ‘sold comps’ reveal—rather than the perception and allure of branding.

I know you’re probably thinking, okay Alice, but what’s in it for me? I promise you, caring about the garments you pick is going to strengthen your game and have customers coming back for more and that’s the goal. High reviews, trust and satisfaction breeds repeat customers.

The purpose of high-quality construction is comfort and durability. 

So your goal is to attune your senses to quickly identify the quality indicators and then move on to determine if they are in high demand (please don't miss this step).

Cultivating an eye for quality requires a bit of patience and practice. The ability to identify superior craftsmanship extends beyond just the visuals—it requires engaging all your senses. Learning to notice the feel in your hands, weight, colors, texture, thickness, and smell; it’s a whole sensory experience. 

Have a fun with it! 

From the fabric's tactile sensation to its subtle scents, all of it speaks volumes about its construction and composition; every detail matters.

If you’re really serious about being a top seller, which I know you are, take a day trip to Nordstrom, Neiman Marcus, Saks, or any high-end department store in your area to examine the craftsmanship up close. Be sure to visit the men's departments as well, as men's clothing is often of better quality. 

Pull out your magnifying glass and observe everything, From fabric compositions down to the tags. Pay attention to the less obvious things like the lining stitching, buckles, buttons, tags, every square inch and detail.

Next . . .try it on!

How do the jackets drape?

How do the fabrics feel on your skin?

How does it feel walking in the shoes? Comfortable or like torture?

What you feel is what your customer will feel too.

Then head into the cheapest stores in your mall and do the same thing. Get into the persona of a connoisseur, or as if you are doing quality control, be picky. 

What differences can you spot?

What are they trying to get away with?

Take notes!


~~~~light,${colors.white}
#### Chapter 1 - Fabric

Let's begin with making the distinction between natural and synthetic fibers. Understanding their characteristics, advantages, and limitations is helpful when learning to asses quality.

We'll then go into how to engage your senses and what characteristics to look out for. Whether you're a seasoned professional or a curious newcomer, having a thorough comprehension will lay the foundation for your success.

~~~~~light,${colors.white}
##### 1.1 - Natural vs. Synthetic Fibers

Natural fibers are materials that are sourced from plants, animals, or minerals and are used to create fabric. These natural fibers are valued for their breathability, durability, and biodegradability. 

They are often more comfortable to wear in different climates due to their ability to absorb moisture and regulate body temperature. Each type of natural fiber also brings its unique qualities and textures to fabrics.

Here are the fabrics you are most likely to encounter:

- Cotton
- Linen 
- Hemp 
- Bamboo
- Viscose 
- Silk
- Wool
- Cashmere
- Alpaca 
- Agora

This next fact might surprise you but, while natural fibers are often associated with quality, they do not exclusively equate to quality. Therefore, assessing characteristics like origin is important in determining its quality.

For example, Egypt is known for its high-quality Cotton, whereas India and China who are also producers, focus on mass production, tend to have lower-quality Cotton products. These minor nuances can trip you up it not learned.

\n&nbsp;\n
**Synthetic Fibers:**

These fibers are man-made and derived from chemicals that are processed into threads to make fabric. Common examples include:

- Polyester
- Rayon
- Nylon
- Acetate
- Acrylic

The use of synthetic materials is often associated with lower quality in fashion, but it's important not to demonize them outright. In athletic and outdoor wear, synthetics are invaluable for their moisture-repelling properties. Their main advantage lies in enhancing performance and comfort in environments where managing moisture is crucial. 

However, you still need to approach synthetics with discernment. Take acrylic, for example; at first glance, it might seem beautiful and soft, as it is excellent at mimicking the textures of more luxurious natural fibers. Yet, this initial appeal often belies its lack of longevity. 

After just a few wears you’ll notice pilling and after a few washes, garments made from acrylic are prone to losing shape and form, relegating them prematurely to worn-out house clothes or leading them to an early retirement in the bin. Definitely not what we’re after.


~~~~light,${colors.white}
##### 1.2 - Engagging Your Senses: A Guide to Assessing Fabric Quality

Now we will pivot to something equally essential—the art of evaluating fabric quality through a sensory lens. 

Have you ever held clothing that felt like the weight of it could buckle your knees? It could strut off the rack, pay for your cart of items and walk out. High-quality garments feel like they mean business.

Here’s a detailed look at how to harness your senses to discern the quality of fabric:

**Texture and feel:**

The fabric's texture, weight, and thickness are immediate quality indicators. How does it feel in your hand? High-quality garments should feel pleasant and substantial. Higher quality fabric will also better maintain its shape after stretching or scrunching. If the fabric sags, it will not hold up after a few washes and eventually lose shape.

**Smell:**

Trust your nose. The scent of clothing is a subtle but telling indicator. Ever walked into a store and smelled something... off? A garment reeking of chemicals like it just tumbled out of a plastic factory is no good. Clinging to odors like a bad ex. 

A plastic-like smell is almost always a signal of the presence of synthetic fibers, which are prone to retaining body odors due to their non-polar nature, attracting oils and not releasing them quickly upon washing. This characteristic affects the garment's freshness over time and can also impact the wearer's comfort over time.

**Visual Inspection:**

Pay attention to the consistency of the fabric's colors, sheerness and patterns. High-quality prints should not be see-through and should align at the seams, showcasing attention to detail. However, be cautious with printed and patterned fabrics, which can sometimes mask defects.

The garment's inside should match the quality of its exterior. This includes seamless finishes, the absence of loose threads, and extra details like spare buttons, thread and reinforced stitching, which speak volumes about the garment's craftsmanship. More on this later.

**Material Composition:**

While blends offer some benefits, many different fibers mixed together often compromise fabric quality. Ideally, a garment should comprise one to two fabric types to maintain integrity and quality.


~~~~light,${colors.white}
##### 1.3 - Understanding Fabric Prints and Weaves: Prints vs. Jacquards

The devil’s in the details. Printed fabrics feature designs applied onto the surface after creating the fabric. Jacquard fabrics on the other hand are woven on a special loom that creates intricate patterns directly within the fabric, using threads of different colors.

Printed fabrics, while versatile, can sometimes be a cost-saving choice, using simple techniques to apply patterns onto the fabric. On the flip side, jacquards exude luxury, with designs intricately woven into the fabric using colored threads. This weaving technique adds depth and texture, indicating higher craftsmanship and material quality.

![print vs jacquard](${PrintJacquard})


~~~~light,${colors.white}
##### 1.4 - The Significance of Fabric Grain in Quality Assessment:

Remember that magnifying glass I joked about earlier? Pull it out again, because The true testament to a garment's quality, is its adherence to the fabric grain. The grain is the direction of the threads that make up the fabric. 

These lines should run perfectly vertical or horizontal on the garment without any diagonal skew. A garment cut accurately to these grain lines hangs properly and maintains its shape.

High-quality garments respect the grain, ensuring the front and back pieces are cut precisely, preserving the garment's shape and draping through multiple washes.

Manufacturers looking to cut corners on fabric will try to play Tetris with the pattern pieces tilting them in all different angles to make use of as much scrap pieces as possible, compromising the garment's alignment with the grain lines. 

Such practices can lead to twisted seams after washing — a clear marker of compromised quality.


~~~~light,${colors.white}
##### 1.5 - Colors & Dying:

A high-quality garment should have a consistent and uniform color throughout, without patches fading or blurring. Pay special attention to seams and folds where inconsistencies might be more apparent.


~~~~light,${colors.white},${colors.slatePurple}
#### Chapter 2 - Tailoring & Construction

From the satisfying snap of a well-stitched seam to the sturdy embrace of a perfectly aligned collar, Let's review the aspects of tailoring that will help you to spot quality that not only looks good but feels exquisite.

~~~~light,${colors.white}
##### 2.1 - Stitching and Structural Elements

**Stitching Precision:**

Stitching should be tight, neat, straight and even. Do you see double stitching and closed seams? Even better.

![Well done stitching vs. poorly done stitching](${Serged})

**Dart Placement:** 

Fewer darts suggest a compromise in shaping for the sake of cost-saving. Tucks, yokes, pleats

**Collar Construction:**

A well-constructed collar will be symmetric, have neat stitching, feel sturdy and have firm interlining. Avoid shirts with flimsy collars lacking shape and structure.

**Seam Quality:**

The stitching should be tight, the more per inch, the higher the quality. Stitching should be flat and have no loose threads. Double-stitching or reinforced seams in high-stress areas like pockets, buttonholes, zippers, crotch and Inner thighs are indicators of durable construction.

Also, look out for finished or covered seams vs serged.

![Well done seams vs. poorly done seams](${Seams})

Pull gently on a seam, If the stitches pull apart slightly, and you can see the light coming through the gaps, it hasn’t been well sewn. Seams should not be bulky; They should lie flat and be tidy with no looseness, threads sticking out or inconsistency.

**Hems:** 

Hems should have clean stitching, so no fraying or loose threads, especially on sleeves, as they often come loose if not done well as a garment ages or on pieces that have been tried on a lot.

**Linings:**

The inside of a garment tells you as much as the outside. Oftentimes, manufacturers do their best on the exterior and neglect the interior leaving it messy. Linings should be smooth, made of materials that feel comfortable on the skin and allow for stretching. 

If not constructed well, the lining is one of the first places to come apart at the seams.

![Good Quality Lining](${LingingQuality})

When they don't cut the fabric properly you get side seams that twist. This happens when pattern pieces are not aligned vertically with the fabric.


~~~~light,${colors.white}
##### 2.2 - Key Areas for Wear and Tear

**Necklines and armpits:** 

We’ve all seen those distinct yellow sweat stains that stubbornly cling to the armpits of a shirt, or the faint, tell-tale smudges of makeup on a neckline or the faint, tell-tale smudges of makeup on a neckline.

As you inspect a garment, let your fingers and eye glide over the fabric, feeling for any rough patches or discolorations. A quick sniff might reveal a lingering scent of perfume mixed with deodorant. These are the hidden histories of clothing, the whispers of owners past.

These areas often bear the brunt of wear and can be a challenge to clean. With a bit of stain remover and elbow grease, these imperfections can be erased. However, this is not always the case, so try to avoid picking up items may require a great deal of time an effort to restore them.

**Cuffs and Sleeves:** 

Cuffs often fray due to constant friction against surfaces and frequent washing. They’re also prone to stains from being so close to your food. Run your fingers along the cuff’s edge and feel for roughness or see if it’s darkened from stains.

**Elbows:** 

Over time, you might notice the fabric becoming thin or even developing small holes, particularly on shirts and jackets. Press your fingers against the material and feel for any areas that lack the usual thickness or elasticity.

**Knees:** 

Like elbows, the fabric that makes contact with your knees is prone to wear and tear. Look for any shiny spots or areas that feel more rigid than the rest of the fabric. Kneeling down, you might even hear a subtle crinkle that indicates the fabric’s fatigue.

**Inner Thighs:** 

Slide your hand over the inner thigh section of the pants to detect any thinning fabric or pilling. Inspect the seams of the crotch as this is usually the first part of the pants where holes form. A slight roughness, seam separation an uneven texture can be the first signs of wear.

**Pockets:**

Often stretching and tearing from overuse. Check the openings for any frayed edges or loose threads. Stick your hand inside and feel for holes that could let your treasures slip away.

**Waistbands:** 

Elastic waistbands can stretch out, losing their snap and becoming more of a suggestion than support. Stretch the waistband gently and see if it bounces back. If it stays loose or feels limp, leave it right where it is.



~~~~light,${colors.white},${colors.slatePurple}
#### Chapter 3 - Closures, Pockets & Hardware

Hardware details are often a dead giveaway, stripping, cracking and changing color quicker than you can blink. If it's attached more precariously than the Leaning Tower of Pisa, take it as a clear signal; You're dealing with construction that's less about longevity and more about making a quick buck. 

Here are the critical elements that merit a closer look:

~~~~light,${colors.white}
##### 3.1 - Buttons & Buttonholes

High-quality buttons are made from natural materials like tortoise shells, mother-of-pearl, horn, wood or metal. The stitching on the buttonhole should be neat, and there should be no loose threads. Once the garment is buttoned, it should stay buttoned. 

Slipping buttons is a bad sign. The manufacturer did not make the buttonhole the correct size. This makes it hard for you to feel comfortable and confident in the garment as you always wonder if the next gust of wind will blow open your shirt. One last thing to look out for are shanked buttons; this is when the button is reinforced with more thread wrapped behind the button.

![Well sewn buttons vs. poorly sewn buttons](${Buttons})


##### 3.2 - Zippers

They should lie flat, glide smoothly, and have clean stitching and seams. Look for hooks or snap closures at the top of the zipper, as this helps to keep it flat. YKK zippers are good quality, so look out for the YKK stamp on the zipper. A zipper that catches or jams? It's a definite no-go. 

![Well sewn zippers vs. poorly sewn zippers](${Zippers})


##### 3.3 - Pockets

Pockets should, like everything else, lie flat and be made with the same material as the garment, they should feel sturdy and have strong stitching as this is a high-stress and tensioned part of the garment. 

Lower-quality garments often use lining materials to make the pockets, making them weaker and prone to tearing.

![Well sewn pockets vs. poorly sewn pockets](${Pockets})


##### 3.4 - Snaps, Fastners & Buckles

Snaps should click with satisfying certainty, not leave you guessing if a wardrobe malfunction is a breeze away. They're the silent affirmations in the background, holding things together.

A buckle should cinch confidently. The clasp says, "I've got you," holding everything in place with style.

![Well sewn closures vs. poorly sewn closures](${Closures})

~~~~light,${colors.white},${colors.slatePurple}
#### Chapter 4 - Tags

The quality of garment tags can offer insightful clues into the overall quality of an item. Keep an eye out for tags with these characteristics:

• Woven materials securely tacked with clean stitching

• Embossed on Leather

• Unique, custom fonts reflecting attention to detail and a commitment to branding consistency.

• Origin tags that display “Made in…” Italy, France, The UK, Portugal, Spain, Germany or Switzerland –- countries known for quality and craftsmanship.

![High quality tags](${HighQualityTags})

In contrast, tags of lower quality garments feel synthetic as they are usually made of lower grade materials like polyester, often use common or cheesy fonts, and may be made in China, Vietnam, India, and Cambodia. 

Recognizing these distinctions in tag quality can aid in quickly assessing a garment's overall craftsmanship and material quality during sourcing.

![Low quality tags](${LowQualityTags})


~~~~light,${colors.white}
#### Conclusion

Do not blindly trust brand names just because they are popular or expensive. The devil is in the details, you need to inspect each piece. To assess quality, engage all your senses. If it’s good for you, it’s likely good for your customer too. 

Although this might seem tedious in the beginning, look at it as a training of your eye as a top seller. Soon it will become second nature and you too will be an expert on selecting high quality items that will sell like hot cakes. Once you’ve perfected the skill, no one can take it away from you, and you’re going to stand out from all the other sellers. 

Because quality trumps quantity in the long run. Now get out there and get to inspecting! Make me proud!`;

export default howToIdentifyQuality;
