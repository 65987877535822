import axios from "axios";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { buildEndpoint } from "../utils/helpers";

// fetchListingAnalysis: "http://127.0.0.1:5001/alice-tools-a93f3/us-central1/app/api/analysis/"
// fetchCoverPhotoAnalysis: "http://127.0.0.1:5001/alice-tools-a93f3/us-central1/app/api/photo-analysis/"
// getClosetReport: "http://127.0.0.1:5001/alice-tools-a93f3/us-central1/app/api/v2/closet-report"

const BASE_URL = "https://poshwatch.io";

const ENDPOINTS = {
  fetchListingAnalysis: `${BASE_URL}/api/analysis/`,
  fetchCoverPhotoAnalysis: `${BASE_URL}/api/photo-analysis/`
};

const createApiInstance = async () => {
  const api = axios.create({
    baseURL: BASE_URL,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  try {
    const auth = getAuth();
    await new Promise((resolve) => {
      const unsubscribe = onAuthStateChanged(auth, (user) => {
        unsubscribe();
        resolve(user);
      });
    });

    const user = auth.currentUser;
    if (user) {
      const token = await user.getIdToken();
      api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
  } catch (error) {
    console.error("Error getting auth token:", error);
  }

  return api;
};

export const getChart = async (type = 'brands', includeLuxury = true) => {
  try {
    const api = await createApiInstance();
    const endpoint = `/api/v2/charts`;
    const url = `${endpoint}?type=${type}&includeLuxury=${includeLuxury}&limit=500`;
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error("getChart Error:", error);
    return null;
  }
};

export const getStats = async ({ userId = null, username = null, brand = null } = {}) => {
  try {
    if (!userId && !username && !brand) {
      throw new Error("Please provide either userId, username, or brand");
    }

    const api = await createApiInstance();
    const queryParams = new URLSearchParams();
    if (userId !== null) queryParams.append("userId", userId);
    if (username !== null) queryParams.append("username", username);
    if (brand !== null) queryParams.append("brand", brand);

    const url = `/api/stats/?${queryParams.toString()}`;
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error("getStats Error:", error);
    return null;
  }
};

export const getListingAnalysis = async ({ id = null } = {}) => {
  try {
    if (!id) {
      throw new Error("Please provide an id");
    }

    const api = await createApiInstance();
    const queryParams = new URLSearchParams();
    if (id !== null) queryParams.append("id", id);

    const url = `/api/analyze-listing/?${queryParams.toString()}`;
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error("getListingAnalysis Error:", error);
    return null;
  }
};

export const getRank = async ({ listingId = null, hostname = null, keywords = null, sort } = {}) => {
  try {
    if (!listingId || !hostname || !keywords) {
      throw new Error("No listingId, hostname or keywords present");
    }

    const api = await createApiInstance();
    const queryParams = new URLSearchParams();
    if (listingId !== null) queryParams.append("listingId", listingId);
    if (hostname !== null) queryParams.append("hostname", hostname);
    if (keywords !== null) queryParams.append("keywords", keywords);
    queryParams.append("sort", sort);

    const url = `/api/v2/listing-ranking?${queryParams.toString()}`;
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error("getRank Error:", error);
    return null;
  }
};

export const generateClosetReport = async ({ username = null, hostname = null } = {}) => {
  try {
    if (!username || !hostname) {
      throw new Error("No username present");
    }

    const api = await createApiInstance();
    const queryParams = new URLSearchParams();
    if (username !== null) queryParams.append("username", username);
    if (hostname !== null) queryParams.append("hostname", hostname);

    const url = `/api/v2/closet-report?${queryParams.toString()}`;
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error("generateClosetReport Error:", error);
    return null;
  }
};

export const getClosetReports = async ({ username = null, hostname = null } = {}) => {
  try {
    if (!username || !hostname) {
      throw new Error("No username present");
    }

    const api = await createApiInstance();
    const queryParams = new URLSearchParams();
    if (username !== null) queryParams.append("username", username);
    if (hostname !== null) queryParams.append("hostname", hostname);

    const url = `/api/v2/closet-reports?${queryParams.toString()}`;
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error("getClosetReports Error:", error);
    return null;
  }
};

export const fetchListingAnalysis = async ({ listingURL } = {}) => {
  if (!listingURL) {
    throw new Error("Please provide a listing url");
  }

  try {
    const api = await createApiInstance();
    const url = buildEndpoint(listingURL, ENDPOINTS.fetchListingAnalysis);
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error("fetchListingAnalysis:", error.message);
    throw new Error(JSON.stringify({
      message: `${error?.response?.statusText || error.message} ${listingURL}`,
      statusCode: error?.response?.status,
    }));
  }
};

export const fetchCoverPhotoAnalysis = async ({ photoURL } = {}) => {
  try {
    const api = await createApiInstance();
    const url = buildEndpoint(photoURL, ENDPOINTS.fetchCoverPhotoAnalysis);
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error("fetchCoverPhotoAnalysis Error:", error);
    throw new Error(`fetchCoverPhotoAnalysis ${error}, ${photoURL}`);
  }
};

export const subscribeWithEmail = async (email) => {
  try {
    const api = await createApiInstance();
    const url = `/api/subscribe/?email=${encodeURIComponent(email)}`;
    await api.get(url);
    return true;
  } catch (error) {
    console.error("subscribeWithEmail Error:", error);
    return false;
  }
};

export const subscriptions = async (userId) => {
  try {
    const api = await createApiInstance();
    const url = `/api/v2/subscriptions?userId=${userId}`;
    const response = await api.get(url);
    return response.data.active;
  } catch (error) {
    console.error("subscriptions Error:", error);
    return false;
  }
};

export const appWelcomeSequence = async (email) => {
  try {
    const api = await createApiInstance();
    const url = `/api/v2/welcome?email=${email}`;
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error("subscriptions Error:", error);
    return false;
  }
};

export const getUserSettings = async (userId) => {
  try {
    const api = await createApiInstance();
    const url = `/api/v2/users?userId=${userId}`;
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error("Error getting user:", error);
    return null;
  }
};

export const getDetails = async ({ type, id } = {}) => {
  try {
    if (!id && !type) {
      throw new Error("Missing type or id");
    }
    
    const api = await createApiInstance();

    console.log("Request headers:", api.defaults.headers);

    const queryParams = new URLSearchParams();
    if (id !== null) queryParams.append("id", id);
    if (type !== null) queryParams.append("type", type);
    queryParams.append("checkLimits", true);

    const url = `/api/v2/details?${queryParams.toString()}`;
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error("getDetails Error:", error);
    throw error;
  }
};

export const search = async (query) => {
  try {
    const api = await createApiInstance();
    const queryParams = new URLSearchParams({
      query: query,
      checkLimits: true
    });
    const url = `/api/v2/product-search?${queryParams.toString()}`;
    const response = await api.get(url);
    return response.data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
