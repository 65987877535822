import React from 'react';
import { Box, Typography, Button, Grid } from '@mui/material';
import ListingAnalyzerVideo from '../images/listing-analyzer-small.mp4';
import CheckIcon from '@mui/icons-material/Check';
import useMediaQuery from '@mui/material/useMediaQuery';

const AnalyzerVideo = ({ compact }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <Box py={4} sx={{ pt: 2, pb: 4 }}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Box mb={4}>
            {!compact && (
              <Typography variant='h4' style={{
                fontSize: '1.8rem',
                fontWeight: 'bold',
                fontFamily: "Libre Baskerville"
              }} align='left' gutterBottom>
                {`Try Our Chrome Extension`}
              </Typography>
            )}
          </Box>
          <Box>
            <Box display="flex" alignItems="left" mb={2}>
              <CheckIcon color="primary" style={{ marginRight: '8px' }} />
              <Typography variant="body1" color="textSecondary" align='left'>
                Its faster and easier than ever to analyze a listing
              </Typography>
            </Box>
            <Box display="flex" alignItems="left" mb={2}>
              <CheckIcon color="primary" style={{ marginRight: '8px' }} />
              <Typography variant="body1" color="textSecondary" align='left'>
                No more copying and pasting URLs
              </Typography>
            </Box>
            <Box display="flex" alignItems="left" mb={2}>
              <CheckIcon color="primary" style={{ marginRight: '8px' }} />
              <Typography variant="body1" color="textSecondary" align='left'>
                Simply click on the Poshwatch icon to analyze any listing
              </Typography>
            </Box>
          </Box>
          <Box sx={{ pt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => window.open('https://chromewebstore.google.com/detail/poshmark-bot-poshwatch/mkcklccefjfbljhgabgdnapimbmejlpo?hl=en&authuser=0', '_blank')}
              style={{
                width: isMobile ? '100%' : 220,
                marginLeft: isMobile ? 0 : 10,
                marginTop: isMobile ? 10 : 0,
                borderRadius: 10,
                height: 44,
                boxShadow: 'none',
                backgroundColor: "#333",
              }}
            >
              Try it now!
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ pt: 4 }}>
            <video
              src={ListingAnalyzerVideo}
              width="100%"
              muted
              loop
              autoPlay
              style={{ borderRadius: '20px' }}
            ></video>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AnalyzerVideo;
