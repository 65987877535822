import React from 'react';
import { Box, Grid, Typography } from '@mui/joy';
import MetricsCard from './MetricCard.tsx';
import SectionTitle from './SectionTitle.tsx';
import { splitIntoParagraphs } from '../helpers/formatters.ts';

interface MetricData {
  title: string;
  subtitle: string;
  amount: string;
}

interface MetricsGridProps {
  title: string;
  description?: string;
  metrics: MetricData[];
}

const MetricsGrid: React.FC<MetricsGridProps> = ({ title, description, metrics }) => {
  const paragraphs = description ? splitIntoParagraphs(description) : [];

  return (
    <Box sx={{ pt: 3 }}>
      <SectionTitle title={title} />
      {paragraphs.map((paragraph, index) => (
        <Typography
          key={index}
          level="body-md"
          color="neutral"
          sx={{ pb: 2 }}
        >
          {paragraph}
        </Typography>
      ))}
      <Grid container spacing={2}>
        {metrics?.map((metric, index) => (
          <Grid key={index} xs={12} sm={6} md={4}>
            <MetricsCard
              title={metric.title}
              subtitle={metric.subtitle}
              amount={metric.amount}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default MetricsGrid;