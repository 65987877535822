import React from "react";
import { Analyzer } from "../Analyzer";
import { fetchListingAnalysis } from "../../api/client";
import PATHS from '../../utils/paths';

export const MercariAnalyzer = () => {
  return (
    <Analyzer 
      platform="Mercari"
      canonicalUrl ={PATHS.MERCARI_LISTING_ANALYZER}
      getListingAnalysisCallback={fetchListingAnalysis}
    />
  );
};

// https://www.mercari.com/v1/api?