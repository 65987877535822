import * as React from 'react';
import { useLocation } from 'react-router-dom';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Avatar from '@mui/joy/Avatar';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import Divider from '@mui/joy/Divider';
import IconButton from '@mui/joy/IconButton';
import List from '@mui/joy/List';
import ListItem from '@mui/joy/ListItem';
import ListItemButton, { listItemButtonClasses } from '@mui/joy/ListItemButton';
import ListItemContent from '@mui/joy/ListItemContent';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';
import Stack from '@mui/joy/Stack';
import ScreenSearchDesktopTwoToneIcon from '@mui/icons-material/ScreenSearchDesktopTwoTone';
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone';
import AutoFixHighTwoToneIcon from '@mui/icons-material/AutoFixHighTwoTone';
import FormatListNumberedTwoToneIcon from '@mui/icons-material/FormatListNumberedTwoTone';
import AttachMoneyTwoToneIcon from '@mui/icons-material/AttachMoneyTwoTone';
import DiamondTwoToneIcon from '@mui/icons-material/DiamondTwoTone';
import GroupsTwoToneIcon from '@mui/icons-material/GroupsTwoTone';
import LocalMallTwoToneIcon from '@mui/icons-material/LocalMallTwoTone';
import AutoStoriesTwoToneIcon from '@mui/icons-material/AutoStoriesTwoTone';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { useNavigate } from 'react-router-dom';
import PATHS from '../../../utils/paths';

// import ColorSchemeToggle from './ColorSchemeToggle.tsx';
import { closeSidebar } from '../utils.ts';
import { useAuth } from '../../../contexts/AuthContext.js';
interface NavItem {
  segment?: string;
  title?: string;
  icon?: React.ReactElement;
  mode?: 'beta';
  kind?: 'header' | 'spacer';
}

const navItems: NavItem[] = [
  {
    kind: 'header',
    title: 'Tools',
  },
  {
    segment: 'product-research',
    title: 'Product Research',
    icon: <ScreenSearchDesktopTwoToneIcon />,
  },
  {
    segment: 'listing-analyzer',
    title: 'Listing Analyzer',
    icon: <StarTwoToneIcon />,
  },
  {
    segment: 'closet-report',
    title: 'Closet Report',
    icon: <ScreenSearchDesktopTwoToneIcon />,
    mode: 'beta',
  },
  {
    segment: 'poshmark-assistant',
    title: 'Closet Sharer',
    icon: <AutoFixHighTwoToneIcon />,
  },
  {
    segment: 'listing-rank-checker',
    title: 'Listing Rank Checker',
    icon: <FormatListNumberedTwoToneIcon />,
  },
  {
    segment: 'poshmark-fee-calculator',
    title: 'Fee Calculator',
    icon: <AttachMoneyTwoToneIcon />,
  },
  {
    kind: 'header',
    title: 'Charts',
  },
  {
    segment: 'top-selling-brands-on-poshmark',
    title: 'Top Brands',
    icon: <DiamondTwoToneIcon />,
  },
  {
    segment: 'top-sellers-on-poshmark',
    title: 'Top Sellers',
    icon: <GroupsTwoToneIcon />,
  },
  {
    segment: 'top-categories-on-poshmark',
    title: 'Top Categories',
    icon: <LocalMallTwoToneIcon />,
  },
  {
    kind: 'header',
    title: 'Resources',
  },
  {
    segment: 'guides',
    title: 'Guides',
    icon: <AutoStoriesTwoToneIcon />,
  },
  {
    kind: 'spacer',
  },
  {
    segment: 'settings',
    title: 'Settings',
    icon: <SettingsRoundedIcon />,
  },
];

const PremiumCard = () => {
  const navigate = useNavigate();
  const { isPremium } = useAuth();

  return (
    <>
      {isPremium === false ?
        <Card
          invertedColors
          variant="soft"
          color="success"
          size="sm"
          sx={{ boxShadow: 'none', mt: 2 }}
        >
          <Stack
            direction="row"
            sx={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            <Typography level="title-sm">Poshwatch Premium</Typography>
          </Stack>
          <Typography level="body-xs">
            Gain access to all features
          </Typography>
          <Button size="sm" variant="solid" onClick={() => {
            navigate(PATHS.PRICING);
          }}>
            Get Started
          </Button>
        </Card> : <></>
      }
    </>
  )
}

export default function Sidebar() {
  const location = useLocation();
  const { isAdmin } = useAuth();

  return (
    <Sheet
      className="Sidebar"
      sx={{
        position: { xs: 'fixed', md: 'sticky' },
        transform: {
          xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
          md: 'none',
        },
        transition: 'transform 0.4s, width 0.4s',
        zIndex: 10000,
        height: '100dvh',
        width: 'var(--Sidebar-width)',
        top: 0,
        p: 2,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        backgroundColor: '#f0f2f5',
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Sidebar-width': '220px',
            [theme.breakpoints.up('lg')]: {
              '--Sidebar-width': '240px',
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: 'fixed',
          zIndex: 9998,
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          opacity: 'var(--SideNavigation-slideIn)',
          backgroundColor: 'var(--joy-palette-background-backdrop)',
          transition: 'opacity 0.4s',
          transform: {
            xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
            lg: 'translateX(-100%)',
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
        <Typography
          level="title-lg"
          component="a"
          href="/"
          style={{
            fontSize: 14,
            fontFamily: "Libre Baskerville",
            fontWeight: "bold",
            textDecoration: "none",
            cursor: "pointer"
          }}
        >
          POSHWATCH
        </Typography>
        {/* <ColorSchemeToggle sx={{ ml: 'auto' }} /> */}
      </Box>
      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          [`& .${listItemButtonClasses.root}`]: {
            gap: 1.5,
          },
        }}
      >
        <List
          size="sm"
          sx={{
            gap: 1,
            '--List-nestedInsetStart': '30px',
            '--ListItem-radius': (theme) => theme.vars.radius.sm,
          }}
        >
          {navItems.map((item, index) => (
            item.kind === 'header' ? (
              <Typography key={index} level="body-xs" textTransform="uppercase" fontWeight="md" sx={{ pt: 1 }}>
                {item.title}
              </Typography>
            ) : item.kind === 'spacer' ? (
              <Box key={index} sx={{ flexGrow: 1 }} />
            ) : item.mode === 'beta' && !isAdmin ? (
              null
            ) : (
              <ListItem key={item.segment}>
                <ListItemButton
                  role="menuitem"
                  component="a"
                  href={`/${item.segment}`}
                  selected={location.pathname === `/${item.segment}`}
                >
                  {item.icon}
                  <ListItemContent>
                    <Typography level="title-sm" sx={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}>{item.title}</Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
            )
          ))}
        </List>
        <PremiumCard />
      </Box>

      {false &&
        <>
          <Divider />
          <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
            <Avatar
              variant="outlined"
              size="sm"
            />
            <Box sx={{ minWidth: 0, flex: 1 }}>
              <Typography level="title-sm">Alice</Typography>
              <Typography level="body-xs" sx={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}>hello@poshwatch.io</Typography>
            </Box>
            <IconButton size="sm" variant="plain" color="neutral">
              <LogoutRoundedIcon />
            </IconButton>
          </Box>
        </>
      }
    </Sheet>
  );
}