import React from 'react';
import { Link, Box, Typography, LinearProgress, Avatar, Button } from '@mui/joy';
import SectionTitle from './SectionTitle.tsx';
import { splitIntoParagraphs } from '../helpers/formatters.ts';
import PATHS from '../../../utils/paths.js';
import { formatName } from '../../../utils/helpers.js';

interface ProgressItem {
  name: string;
  amount: number;
  href?: string;
  icon?: React.ReactNode;
  picture_url?: string;
}

interface ProgressChartProps {
  title: string;
  description?: string;
  items: ProgressItem[];
  formatAmount?: (amount: number) => string;
  signedIn?: boolean;
}

const ProgressChart: React.FC<ProgressChartProps> = ({
  title,
  description,
  items,
  formatAmount,
  signedIn = false,
}) => {
  const maxAmount = Math.max(...items.map(item => item.amount));
  const paragraphs = description ? splitIntoParagraphs(description) : [];
  const visibleItems = signedIn ? items : items.slice(0, 3);
  const hiddenItems = !signedIn ? items.slice(3) : [];

  return (
    <Box sx={{ width: '100%', position: 'relative' }}>
      <SectionTitle title={title} />
      {paragraphs.map((paragraph, index) => (
        <Typography
          key={index}
          level="body-md"
          color="neutral"
          sx={{ pb: 2 }}
        >
          {paragraph}
        </Typography>
      ))}
      <Box sx={{ position: 'relative' }}>
        {/* Visible Items */}
        {visibleItems.map((item) => {
          const relativePercentage = (item.amount / maxAmount) * 100;
          const formattedAmount = formatAmount ? formatAmount(item.amount) : item.amount.toString();

          return (
            <Box
              key={item.name}
              sx={{ mb: 2 }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                {item.picture_url && (
                  <Avatar
                    src={item.picture_url}
                    alt={item.name}
                    size="sm"
                    sx={{ mr: 1 }}
                  />
                )}
                {item.icon && <Typography sx={{ mr: 1 }}>{item.icon}</Typography>}
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                  {item.href ? (
                    <Link
                      style={{ textDecoration: 'none' }}
                      href={item.href}
                      target="_self"
                    >
                      {item.name}
                    </Link>
                  ) : (
                    <Typography>{formatName(item.name)}</Typography>
                  )}
                </Box>
                <Typography sx={{ ml: 2, minWidth: '80px', textAlign: 'right' }}>
                  {formattedAmount}
                </Typography>
              </Box>
              <LinearProgress
                determinate
                value={relativePercentage}
                variant="soft"
              />
            </Box>
          );
        })}

        {/* Blurred Items */}
        {!signedIn && hiddenItems.length > 0 && (
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                filter: 'blur(4px)',
                opacity: 0.7,
                pointerEvents: 'none',
              }}
            >
              {hiddenItems.map((item) => {
                const relativePercentage = (item.amount / maxAmount) * 100;
                const formattedAmount = formatAmount ? formatAmount(item.amount) : item.amount.toString();

                return (
                  <Box
                    key={item.name}
                    sx={{ mb: 2 }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      {item.picture_url && (
                        <Avatar
                          src={item.picture_url}
                          alt={item.name}
                          size="sm"
                          sx={{ mr: 1 }}
                        />
                      )}
                      {item.icon && <Typography sx={{ mr: 1 }}>{item.icon}</Typography>}
                      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <Typography>{formatName(item.name)}</Typography>
                      </Box>
                      <Typography sx={{ ml: 2, minWidth: '80px', textAlign: 'right' }}>
                        {formattedAmount}
                      </Typography>
                    </Box>
                    <LinearProgress
                      determinate
                      value={relativePercentage}
                      variant="soft"
                    />
                  </Box>
                );
              })}
            </Box>

            {/* Sign In Overlay */}
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 100%)',
                borderRadius: 'md',
                padding: 2,
              }}
            >
              <Button
                variant="solid"
                color="primary"
                href={PATHS.REGISTER}
                component="a"
                sx={{
                  minWidth: 120,
                  backdropFilter: 'blur(4px)',
                }}
              >
                Sign up for free
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ProgressChart;