import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  styled,
  Typography,
} from '@mui/material';
import { toJpeg } from 'html-to-image';

const getRandomGradient = () => {
  const gradients = [
    { name: 'purple', start: '#261b3a', end: '#6e4a85' },
    { name: 'forestGreen', start: '#0B3B24', end: '#1E5945' },
    { name: 'navyBlue', start: '#0A2342', end: '#2C4A7F' },
    { name: 'deepBurgundy', start: '#4A0E1C', end: '#7E2539' },
    { name: 'charcoal', start: '#1C2321', end: '#3E4A3D' },
    { name: 'midnightBlue', start: '#0C1B33', end: '#34495E' },
    { name: 'darkOlive', start: '#232B2B', end: '#41584B' },
    { name: 'aubergine', start: '#2D0F2E', end: '#5B2E48' },
  ];

  const randomIndex = Math.floor(Math.random() * gradients.length);
  const { start, end, name } = gradients[randomIndex];

  return {
    gradientString: `linear-gradient(135deg, ${start} 0%, ${end} 100%)`,
    colorName: name
  };
};

const GradientBackground = styled('div')<{ gradient: string }>(({ gradient }) => ({
  background: gradient,
  width: '1024px',
  height: '1024px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '16px',
  position: 'relative',
}));

const CenteredContent = styled('div')({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
});

interface PostTemplateProps {
  children: React.ReactNode;
  numScreenshots: number;
  theme: 'light' | 'dark';
}

const PostTemplate: React.FC<PostTemplateProps> = ({
  children, numScreenshots, theme,
}) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [gradient, setGradient] = useState('');
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const { gradientString } = getRandomGradient();
    setGradient(gradientString);
  }, [currentIndex]);

  const takeScreenshot = useCallback(async (index: number) => {
    if (containerRef.current) {
      try {
        const dataUrl = await toJpeg(containerRef.current, {
          width: 1024,
          height: 1024,
          pixelRatio: 2,
        });

        const link = document.createElement('a');
        link.download = `post_${index + 1}.jpeg`;
        link.href = dataUrl;
        link.click();
      } catch (err) {
        console.error('Error taking screenshot:', err);
      }
    }
  }, []);

  const takeAllScreenshots = useCallback(async () => {
    for (let i = 0; i < numScreenshots; i++) {
      setCurrentIndex(i);
      await new Promise(resolve => setTimeout(resolve, 100)); // Wait for re-render
      await takeScreenshot(i);
    }
  }, [numScreenshots, takeScreenshot]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 's') {
        event.preventDefault();
        takeScreenshot(0); // Save only the first image
      } else if ((event.metaKey || event.ctrlKey) && event.key === 'a') {
        event.preventDefault();
        takeAllScreenshots(); // Save all images
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [takeScreenshot, takeAllScreenshots]);

  return (
    <GradientBackground gradient={theme === 'light' ? 'white' : gradient} ref={containerRef}>
      <CenteredContent>
        {children}
        <Typography
          variant="body1"
          fontFamily="Libre Baskerville"
          style={{
            marginTop: '60px',
            color: theme === 'light' ? 'black' : 'white',
            fontWeight: 'bold',
            fontSize: '1.4rem',
            opacity: 0.5
          }}
        >
          Poshwatch.io
        </Typography>
      </CenteredContent>
    </GradientBackground>
  );
};

export default PostTemplate;