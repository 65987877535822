import * as React from 'react';
import { Box, Card, Grid, Skeleton, Typography, AspectRatio } from '@mui/joy';

const StatCardSkeleton = () => (
  <Card variant="outlined">
    <Typography>
      <Skeleton>Statistics Title</Skeleton>
    </Typography>
    <Typography level="h4">
      <Skeleton>123,456</Skeleton>
    </Typography>
  </Card>
);

const StatTableSkeleton = () => (
  <Card variant="outlined">
    <Typography level="h4" sx={{ mb: 2 }}>
      <Skeleton>Table Title</Skeleton>
    </Typography>
    {[...Array(5)].map((_, i) => (
      <Box key={i} sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        <Typography>
          <Skeleton>Table Row Item</Skeleton>
        </Typography>
        <Typography>
          <Skeleton>Value</Skeleton>
        </Typography>
      </Box>
    ))}
  </Card>
);

export const SearchSkeleton = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Grid container spacing={2}>
        {Array.from({ length: 4 }).map((_, index) => (
          <Grid key={index} xs={6} sm={4} md={3}>
            <StatCardSkeleton />
          </Grid>
        ))}
      </Grid>

      {/* Image Grid Skeleton */}
      <Card variant="outlined">
        <Typography level="h4" sx={{ mb: 2 }}>
          <Skeleton>Image Gallery</Skeleton>
        </Typography>
        <Grid container spacing={2}>
          {Array.from({ length: 8 }).map((_, i) => (
            <Grid key={i} xs={3} sm={1.5}>
              <AspectRatio ratio="1">
                <Skeleton variant="overlay"/>
              </AspectRatio>
            </Grid>
          ))}
        </Grid>
      </Card>

      {/* Stat Tables Grid */}
      <Grid container spacing={2}>
        {Array.from({ length: 3 }).map((_, index) => (
          <Grid key={index} xs={12} sm={6} md={4}>
            <StatTableSkeleton />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}; 