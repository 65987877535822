/* eslint-disable no-unused-vars */
import React, { useMemo, useState, useRef } from 'react';
import { lightTheme } from '../../customTheme';
import { ResponsiveAppBar } from '../../components/ResponsiveAppBar';
import Panel from '../../components/Panel';
import { Container, Typography, Button, Box, Card } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { colors } from '../../customTheme';
import Footer from '../../components/Footer';
import TinderCard from 'react-tinder-card'
import { styled } from '@mui/material/styles';

const StyledTinderCard = styled(TinderCard)(({ theme }) => ({
  position: "absolute"
}));

const StyledCard = styled('div')(({ theme, backgroundImage }) => ({
  margin: '8px',
  borderRadius: '6px',
  position: 'relative',
  backgroundColor: '#fff',
  width: '80vw',
  maxWidth: '260px',
  height: '300px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundImage: `url(${backgroundImage})`
}));

const StyledCardContainer = styled('div')({
  width: '90vw',
  maxWidth: '260px',
  height: '300px',
  display: 'flex',
  justifyContent: 'center', // horizontally centers children
  alignItems: 'center', // vertically centers children
  margin: '0 auto' // horizontally center container itself
});

const ListingAssistant = ({ title, subtitle, description, href }) => {

  const db = [
    {
      title: "Women's Free People Do It Again Minidress Dragon Fruit Combo M",
      url: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcSSNWU2w9jZ85hkUEQW3d2JubrhtcKrHyhidQpOABpYzrk6MVHy'
    },
    {
      title: "Free People Dresses | Do It Again Fp Dress | Color: Pink | Size: L | Graceneals's Closet",
      url: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcQhBxnQtJNu-awN1qaEP2nm596AKxGzuzfknO3JnQqeNbAG7S6n'
    },
    {
      title: "Women's Free People Do It Again Minidress Dragon Fruit Combo M | eBay",
      url: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcSrFGnjL2nEv1v36TX8oNEmZx-DnuSqoHQgmAOlhqaHXWE2x8EM'
    },
    {
      title: 'Carriage House Boutique',
      url: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcSPbzzQ6JfxtNldKx6nqquQGYha0NhkpoBy84AbcIdPwnYDSxLA'
    },
    {
      title: 'Free People 100% Cotton Stripes Pink Casual Dress Size S - 66% off | ThredUp',
      url: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQLnCBEMXdvGfesko_pJfeYO0Wc5CNw2Slou5vESENsIKNRtzv1'
    },
    {
      title: "Women's Sage Double Cloth Maxi Dress | Pink | XS by Marine Layer",
      url: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQHvB4YZyWKEyEMvVTNo8M0J5-84RFcsfzXZh8kos8qcyxQErXa'
    },
    {
      title: 'Striped Summer Dress - Dresses - Fashionstyleny | Online Clothing Store in Danbury',
      url: 'https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcQ6k1vQQu8oLPJqJKlLqEX8UeO3tZzmp_CriUwKHigOW0QozrKQ'
    },
    {
      title: 'New_Looktz (@new_looktz) • Instagram photos and videos',
      url: 'https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcS3RVVKFqeEgH3RvadXGbypMDAjZOkGcd6G3cFeNLt5FLlFzYfe'
    },
    {
      title: 'SHEIN VCAY Striped Print Ruffle Hem Cami Dress | SHEIN',
      url: 'https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcSQhk9a5GVzdSXNa5mSDQyAYig-hYlAPabngYiwrym_c8ypUsdb'
    },
    {
      title: 'Maxi Casual Dresses for Women | Nordstrom',
      url: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRkxTvh8s20TAH3ZJyrFJQu_yzzyv6qYxRVCLPi5EeR7WEDytWd'
    }
  ];

  const [currentIndex, setCurrentIndex] = useState(db.length - 1)
  const [lastDirection, setLastDirection] = useState()
  // used for outOfFrame closure
  const currentIndexRef = useRef(currentIndex)

  const childRefs = useMemo(
    () =>
      Array(db.length)
        .fill(0)
        .map((i) => React.createRef()),
    [db.length]
  )

  const updateCurrentIndex = (val) => {
    setCurrentIndex(val)
    currentIndexRef.current = val
  }

  const canGoBack = currentIndex < db.length - 1

  const canSwipe = currentIndex >= 0

  // set last direction and decrease current index
  const swiped = (direction, nameToDelete, index) => {
    setLastDirection(direction)
    updateCurrentIndex(index - 1)
  }

  const outOfFrame = (name, idx) => {
    console.log(`${name} (${idx}) left the screen!`, currentIndexRef.current)
    // handle the case in which go back is pressed before card goes outOfFrame
    currentIndexRef.current >= idx && childRefs[idx].current.restoreCard()
    // TODO: when quickly swipe and restore multiple times the same card,
    // it happens multiple outOfFrame events are queued and the card disappear
    // during latest swipes. Only the last outOfFrame event should be considered valid
  }

  const swipe = async (dir) => {
    if (canSwipe && currentIndex < db.length) {
      await childRefs[currentIndex].current.swipe(dir) // Swipe the card!
    }
  }

  // increase current index and show card
  const goBack = async () => {
    if (!canGoBack) return
    const newIndex = currentIndex + 1
    updateCurrentIndex(newIndex)
    await childRefs[newIndex].current.restoreCard()
  }

  return (
    <div>
      <ResponsiveAppBar />
      <Helmet
        bodyAttributes={{ style: `background-color : ${colors.alabaster}` }}
      >
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content="Poshmark Listing Assistant" />
        <meta property="og:description" content="Get help improving your listings" />
        <meta property="og:url" content="https://poshwatch.io/" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={href} />
      </Helmet>

      <Panel theme={lightTheme}>
        <Container maxWidth='md' style={{ paddingBottom: '40px' }}>
          <Typography variant='h4' align='center' paddingBottom={1}>{title}
          </Typography>
          <Typography align='center' color="textSecondary">{subtitle}
          </Typography>
        </Container>
        <StyledCardContainer>
          {db.map((item, index) => (
            <StyledTinderCard
              ref={childRefs[index]}
              key={item.title}
              onSwipe={(dir) => swiped(dir, item.title, index)}
              onCardLeftScreen={() => outOfFrame(item.title, index)}
            >
              <Card variant='outlined' sx={{
                borderRadius: '10px',
                height: '320px',
                width: '260px'
              }}>
                <Box display="flex" flexDirection="column">
                  <Box flex={2}> {/* Image takes up 2/3 of the box */}
                    <StyledCard backgroundImage={item.url} />
                  </Box>
                  <Box flex={1}> {/* Description takes up 1/3 of the box */}
                    <Typography align='center'>
                      {item.title}
                    </Typography>
                  </Box>
                </Box>
              </Card>
            </StyledTinderCard>
          ))}
        </StyledCardContainer>

        <Box m={4}>
          <Container align="center">
            <Typography variant='body' align='center'>
              Does this look like your item?
            </Typography>
            <Box mt={2}>
              <Container align="center">
                <Button variant='outlined' style={{ marginRight: "10px" }} onClick={() => swipe('left')}>
                  No
                </Button>
                <Button variant='contained' style={{ marginLeft: "10px" }} onClick={() => swipe('right')}>
                  Yes
                </Button>
              </Container>
            </Box>
          </Container>
        </Box>
      </Panel >
      <Footer disableBgColor />
    </div >
  );
};

export default ListingAssistant;
