import { colors } from '../customTheme';
import Landfill from '../images/landfill.jpg';
import Clothing from '../images/clothing.jpg';
import WomanConfused from '../images/woman-confused.jpg';
import WomanEmpowered3 from '../images/woman-empowered3.jpg';
import LetsGo from '../images/lets-go.jpg';
import PATHS from '../utils/paths';
import ListingAnalyzer from '../images/how_to_list/analyzer.png';

const howToStartSellingOnPoshmark = `
~~~~light,${colors.white}
# How to Start Selling on Poshmark: A Guide for New Sellers

<div style="text-align: center; font-family: Inter; margin-bottom: 6px">
Last updated Jun. 26, 2024
</div>
<div style="text-align: center; font-family: Inter">
Written by Alice
</div>
\n&nbsp;\n

So, the whispers on the street mention that you want to start selling on Poshmark.

You’ve been toying with the idea for some time now, going back and forth with yourself, teetering on the edge of this decision because you don't know how to begin.

![Confused woman noBorder](${WomanConfused})

Of course, it's natural to feel a cocktail of nerves and excitement when trying something new. But let me tell you, fear has no place here.
The stars have aligned in your favor, as this guide was written just for you. Here, you'll find everything you need to transform hesitation into bold, decisive action.

It's not just an opportunity, it’s your reseller destiny waiting to unfold.

~~~~light,${colors.white}
#### Chapter 1 - Why You Should Become a Poshmark Reseller

I, will be your mentor, taking you from zero to your first sale and beyond. Helping you to unlock the delight and exhilaration of reselling and establishing a lucrative reselling empire.

Do you still need some convincing?

Ok, let's spark that flame for reselling. It's much more than just a way to make a living.

~~~~~light,${colors.white}
##### 1.1 - Environmental Impact

Despite its allure, fashion is not without its faults, being a major global polluter. Manufacturing and shipping puts an unfathomable strain on our water systems, energy and natural resources. Every single item we help find a new home is one less resident in our landfills.

![Landfill full of fast fashion](${Landfill})

As resellers, we're extending their lifecycle and allowing many more cherished moments. As this movement grows, it will encourage us to invest in quality pieces that hold their value and beg to be treasured and resold. 

It's a chic and sustainable choice that stands in direct opposition to fast fashion and it’s environmental degredation. In their [11th annual resale industry report](https://newsroom.thredup.com/news/thredups-11th-annual-resale-report-reveals-consumers-continue-to-embrace-secondhand-amid-economic-uncertainty), ThredUP states with its current growth trajectory, the global secondhand market is set to nearly double by 2027, reaching $350 billion.

~~~~light,${colors.white}
##### 1.2 - Creative Fashion Expression

Reselling is where our fashion fantasies take flight. It's your own personal runway, where we curate, express, and breathe new life into new and old trends.

![Creative expression](${Clothing})

Each piece in your collection tells a story, reflects a mood, and embodies a style. We're not merely sellers but fashion alchemists connecting fashionistas with their dream pieces.

~~~~light,${colors.white}
##### 1.3 - Freedom to Set Your Own Schedule

Let's not forget the luxury this world of reselling affords us. 

Freedom! We're the ones in charge – deciding when to work and when to indulge in our creative pursuits. Balancing our passion with our lifestyle, all on our own terms.

~~~~light,${colors.white},${colors.slatePurple}
#### Chapter 2 - Steps in the Poshmark Selling Process

Now that you understand and are intrigued by the benefits of becoming a Poshmark reseller. It’s time to go through the step by step process to not just start selling but to thrive in the Poshmark marketplace. 

Roll up your sleeves; we’re going in!

~~~~light,${colors.white}
##### 2.1 - Defining Your Poshmark Business Model

Someone has to say it, and that someone is me – Don’t think about selling on Poshmark before defining your business model. This will be your grand plan for profits, who you'll sell to, and how you'll operate. 

There are two primary business models to consider: the Low Cost of Goods model and the High-Value sales model.

###### Low Cost Goods

This model involves sourcing items at the lower end of the price spectrum and selling them for a higher price to make a profit. It's all about finding bargains and turning them into treasure.

For example, you might find a vintage vinyl record at a garage sale for $1 and sell it for $12, making a solid profit from a minimal investment.

###### High-Value Sales

This model focuses on sourcing more premium products that often sell at higher price points. It's about finding high-quality items that have significant resale value.

For example, you might discover a designer bag at a thrift store for $12 and resell it for $300, significantly boosting your earnings with a high-value flip.


###### Differences Between the Models

- **Volume**

    Low Cost of Goods: To achieve substantial profits, you need to sell a higher volume of items. The margins on each sale are smaller, so increasing the quantity sold is essential.

    High-Value Sales: Fewer sales are needed to reach your profit goals because each item has a higher profit margin.

- **Initial Investment**

    Low Cost of Goods: The initial investment is typically lower. You can start with a small budget and gradually scale up as you reinvest your profits.

    High-Value Sales: The initial investment is higher because you're purchasing more expensive items. However, the potential returns are significantly greater.

- **Profit Margins**

    Low Cost of Goods: The profit margins per item are generally lower. You might make a few dollars on each sale, but it adds up with volume.

    High-Value Sales: The profit margins are much higher. Selling one high-value item can result in a substantial profit, sometimes equivalent to selling dozens of lower-cost items.

##### 2.2 - Setting Up Your Poshmark Closet

This part of the process is nothing like adding the latest clothes to your wardrobe; it’s a lot easier. And if done well, it will be quite rewarding. 

Let’s go through the steps:

- **Create an account:** Download the Poshmark app or visit the website. Then, take your time to fill out the required information.

- **Add a username:** Your username should be clear, easy to read, and align well with your business model.

- **Add a profile picture:** Choose a clear, friendly image that represents your style, or use your brand’s logo to make a strong first impression. Your profile picture will add a personal touch to your closet and help buyers connect with you

- **Write a bio:** So, your bio is one of the best ways to introduce and sell yourself to your buyers. Write something that is concise and easy to understand.



##### 2.3 - Finding Items to Sell

Great, you’ve created your Poshmark profile. Easy right?

Now it's time to fill your closet with all your unique finds and start selling.

By now, you should have some idea about what you want to sell, so it’s time to figure out where to source your inventory. You have several sourcing options to choose from; you just have to ensure they are reliable and can be trusted.

Some options include thrift stores like 

- Goodwill

online market places like:

- Facebook Marketplace

- eBay, 

Garage Sales, and Wholesalers. Don’t forget that if you have quality items to sell, you could be your own source.


##### 2.4 - Taking Great Photos for Your Listings

I know you didn’t sign up to be a photographer, but photos are the first thing buyers notice. You have to get in the groove of taking pictures of your items so they stand out and entice buyers. 

I’m more of a selfie girl, but I have a few tips to share:

- Invest in a good camera or a smartphone with a high-quality camera.

- If possible, use natural lighting or purchase a proper lighting kit.

- Use a clean, uncluttered background (you could create or buy a backdrop).

- Take 4 - 6 photo in the following angles Front, Back, Brand Tag, Retail tag (if NWT), Flaws (If any).

- Use editing tools to help enhance your photos, but avoid over-editing. Natural-looking images tend to perform better as they accurately represent the item. Simple adjustments like brightness, contrast, and cropping can make your photos more appealing without misleading buyers.


For more on photos for your listings check out our post [How to create Poshmark listings that sell](https://poshwatch.io/guides/how-create-poshmark-listings-that-sell#2-2---why-good-photos-are-important)




##### 2.5 - Pricing Your Items Competitively

Not everyone is comfortable with the price talk. But it’s unavoidable; 

Otherwise, you won't be able to make a healthy profit. And as business owners, we ain’t about that life, so let’s get to it.

Start by researching market prices across other platforms to establish a competitive reference point for your items. When setting your prices, consider the condition and status of your item – is it gently used or like new? Is it a popular brand, or is it currently trending? 

Understand Poshmark's shipping fee structure, including the flat-rate fee for items under 5 pounds, and incorporate this into your pricing calculations. Likewise, factor in its commission fees (vary based on the sale price) to ensure your pricing strategy aligns with your profit goals. 

To simplify this process, take advantage of our [Poshmark fee calculator](https://poshwatch.io/poshmark-fee-calculator) for precise calculations.

For optimizing your listings to attract buyers, use [round numbers](https://poshwatch.io/guides/reseller-tips#3--smart-pricing--hit-the-sweet-spots) rather than odd figures. Additionally, consider enhancing your offers with bundle discounts or promotions to incentivize multiple purchases and increase your sales volume.

##### 2.6 - Writing Compelling Descriptions

Remember when I said words build bridges? It turns out they sell too – well, only if they hit the spot for your buyers. 

This is how you hit the spot:

- **Be specific:** Add every possible detail to properly describe your item: the brand, size, color, etc.

- **Highlight unique features:** If your item is a standout, talk about it. Who doesn’t love a piece that’s unique?

- **Be transparent:** Let’s be honest about any imperfections your items have. Honesty builds trust with buyers.

- **Use relevant keywords:** Incorporate key terms like brand names, specific models, sizes, and trending descriptors to optimize search visibility.

We have a dedicated section on writing descriptions in our post [How to create Poshmark listings that sell](https://poshwatch.io/guides/how-create-poshmark-listings-that-sell#2-2---understanding-the-description)


###### A little lesson on SEO

As a Poshmark seller, it would serve you well to understand [SEO](https://poshwatch.io/guides/how-create-poshmark-listings-that-sell#chapter-1---the-basics-of-seo-for-poshmark).

Creating optimized listings will increase visibility and attract more potential buyers to their listings. 

By optimizing listings with relevant keywords, detailed descriptions, and high-quality images, sellers can improve their chances of appearing in search results both within Poshmark and on external search engines. This visibility can lead to more clicks, views, and ultimately, sales.

Understanding and implementing SEO best practices not only enhances the discoverability of your Poshmark listings but also contributes to long-term success in online selling by driving organic traffic to your items.


##### 2.7 - Packaging and Shipping Your Sold Items

I hope you are smiling, because this is the last lap! 

I mean, if you got this far, you’re already selling. However it’s important to remember: you’re not just selling an item; you’re selling an experience. 

Once your item sells, prompt and professional shipping is key to maintaining good customer relations. Poshmark provides a pre-paid shipping label, making the process straightforward.

Package your items securely using tissue paper, bubble wrap, or poly mailers. This protects the items during transit, and it adds an aesthetic appeal. To add to a beautifully packaged item, add a small gift or a thank you note.


~~~~light,${colors.white},${colors.slatePurple}
#### Chapter 3 - The Reseller Mindset

Let’s pause for a moment to discuss mindset. 

This is the cornerstone of any venture – a resilient, unwavering mind is crucial. Reselling, like any business, its path is speckled with many challenges and victories.

Without determination and an unshakable mindset, when the hurdles of fierce competition, income fluctuations, unsold inventory, customer woes, and all other issues come swinging around the corner, they will knock you flat out and have you seeing dancing stars.

![Empowered woman noBorder](${WomanEmpowered3})

##### 3.1 - Benefits of a reinforced mindset

A solid mindset is your armour fortifies you against tough times, enabling you to:

- Experience the thrill of sourcing rare hidden gems.

- Enjoy the luxury of having your own business.

- Reap the sweet financial rewards. 

Managing your emotions and taking nothing personally is essential to maintaining this mindset.
Here are some common challenges you may face:

- Dealing with the ever-changing Poshmark platform

- Handling issues with customer service and your customerss.

- Managing stress from all other aspects of the business like shipping etc.


None of these should ruffle your feathers. It's all strictly business.

\n&nbsp;\n
##### 3.2 - Key mindset tips

Countless others have conquered reselling – you, my friend, are no exception. 

If they can do it, you can too. Here are my tips:

- Breathe, remain calm, never be discouraged

- Don't be hard on yourself on those rough weeks, take a break if needed.

- Whatever you do, don't… break… character.


Just stay on course, things almost always work out; even if they don’t go exactly as you hoped, consider it a lesson. 

It's just another gem of knowledge to enrich your treasure chest of experience that will compound as you move forward.

![Let's get moving](${LetsGo})

We'll immerse ourselves in the art of sourcing, listing, managing our treasure trove of inventory, and driving sales.

Alright, let’s get moving. Gather your belongings, it's time to depart on this first-class voyage to reseller success. Once we start, there’s no turning back.

In the next lesson we'll learn all about [listing on Poshmark](https://poshwatch.io/${PATHS.GUIDES}/how-create-poshmark-listings-that-sell).

~~~~light,${colors.white},${colors.slatePurple}
#### Tools and Resources

Congratulations! You are on your way to start selling on Poshmark. And I will be with you through some of the most challenging parts. I know first-hand that a little support goes a long way because I was once a beginner. Here is a little more about [my journey](https://poshwatch.io/guides/my-poshmark-journey).

Today, I’ll leave you with a gift, a free analyzer tool: [Poshmark Listing Analyzer](https://poshwatch.io/listing-analyzer-poshmark). It will help you evaluate and improve your listings. 


![Example of listing titles on Poshmark](${ListingAnalyzer})

~~~~light,${colors.white},${colors.slatePurple}
#### Conclusion

Remember that pesky old friend, fear—the one that insisted on holding you back? We've shown her the door!

You now have a comprehensive understanding of the Poshmark selling process and understand the mindset shift required to succeed. You're ready to embark on your reselling journey with confidence. 

All you have to do is start.
`;

export default howToStartSellingOnPoshmark;
