import React from 'react';
import { Typography, Accordion, AccordionDetails, AccordionSummary, Box } from '@mui/joy';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// Styling constants
const STYLES = {
  sectionHeader: {
    fontSize: '1.8rem',
    mb: 3,
    fontFamily: "Libre Baskerville",
    fontWeight: 'bold'
  },
  paragraph: {
    mb: 2,
    lineHeight: 1.6
  },
  accordionTitle: {
    fontSize: '1.4rem',
    pb: 1
  }
};

// FAQ data
const FAQ_ITEMS = [
  {
    question: "How do I use the Poshmark fee calculator?",
    answer: [
      "Using our Poshmark Fee Calculator is straightforward. Just input your item's sales and cost price, and the calculator will instantly display Poshmark's fee and your net earnings.",
      "This helps you understand exactly how much you'll make from each sale, allowing you to price your items confidently.",
      "Understanding Poshmark fees is crucial for you as a seller as it directly impacts your profit margins. It enables better planning by helping you to price items in a way that best suits your sales goals and avoid unexpected deductions."
    ]
  },
  {
    question: "What is Poshmark's standard fee structure?",
    answer: [
      "Poshmark charges a flat fee of $2.95 for sales under $15. For sales of $15 or more, Poshmark takes a 20% commission. This fee structure is designed to simplify the selling process and ensure transparency.",
      "While the calculator is designed for sellers, buyers can also use it to understand how much sellers are paying in fees, which can help in negotiations and making informed purchasing decisions."
    ]
  },
  {
    question: "How does the Poshmark fee calculator account for shipping costs?",
    answer: [
      "The fee calculator focuses on Poshmark's selling fees. Shipping costs are typically paid by the buyer, but in cases where the seller offers discounted or free shipping, those costs are not included in the calculator's fee deduction."
    ]
  },
  {
    question: "Can the Poshmark fee calculator help me decide my listing price?",
    answer: [
      "It can definitely help! That is the goal of making this calculator, to help you with pricing. By understanding the fees deducted from your potential sale price, you can set a price that maximizes your profit while remaining competitive."
    ]
  },
  {
    question: "Does the Poshmark fee calculator include any additional costs or taxes?",
    answer: [
      "The calculator only includes Poshmark's standard selling fees. Additional costs such as shipping, taxes, or promotional expenses are not included. Poshmark does not deduct taxes from your payout, it leaves that for the seller to handle. It's important to consider these when finalizing your pricing strategy.",
      "Poshmark Canada operates differently, as of July 1 2022, Poshmark is required to charge and collect GST/HST, QST, and PST to canadian shoppers."
    ]
  }
];

// Reusable section header component
const SectionHeader = ({ children }) => (
  <Typography level="h2" sx={STYLES.sectionHeader}>
    {children}
  </Typography>
);

// FAQ Item component
const FAQItem = ({ question, answer }) => (
  <Accordion sx={{ mb: 2 }}>
    <AccordionSummary indicator={<KeyboardArrowDownIcon />}>
      <Typography level="h3" sx={STYLES.accordionTitle}>
        {question}
      </Typography>
    </AccordionSummary>
    <AccordionDetails>
      {Array.isArray(answer) ? (
        answer.map((paragraph, index) => (
          <Typography 
            key={index} 
            sx={{ ...STYLES.paragraph, mb: index === answer.length - 1 ? 0 : 2 }}
          >
            {paragraph}
          </Typography>
        ))
      ) : (
        <Typography sx={STYLES.paragraph}>{answer}</Typography>
      )}
    </AccordionDetails>
  </Accordion>
);

const FeeCalculatorDetails = ({ compact }) => {
  return (
    <Box sx={{ py: 10 }}>
      <SectionHeader>Poshmark fee calculator FAQ</SectionHeader>
      {FAQ_ITEMS.map((item, index) => (
        <FAQItem key={index} {...item} />
      ))}
    </Box>
  );
};

export default FeeCalculatorDetails;