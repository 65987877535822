import { colors } from '../customTheme';

const howToTakePhotos = `
~~~~light,${colors.white}
# How to Take Professional Photos for Poshmark

<div style="text-align: center; font-family: Inter; margin-bottom: 6px">
Last updated Oct. 14, 2024
</div>
<div style="text-align: center; font-family: Inter">
Written by Alice
</div>
\n&nbsp;\n

#### Introduction

So, you’ve curated your resale closet, and you're just about to hit the ‘upload’ button on Poshmark. But before you do, ask yourself—are these photos doing my listings any justice?

If you want your listings to stand out, your photos need to do more than just show the item—they need to create a connection. When buyers can picture themselves wearing it, they’ll feel way more confident hitting that 'Buy' button.

And you know what they say—a picture is worth a thousand words—but on Poshmark, it might just be worth a thousand dollars. Therefore, it’s a skill worth perfecting.

You know the drill, get your blanket, favorite beverage, and a cozy spot. Ready? Okay, let’s break down how to capture high-quality images that can help your listings grab attention and drive sales.


~~~~light,${colors.white}
#### Why You Should Take Great Photos for Poshmark

##### First Impressions Matter

Your listing photos are the first thing buyers see when browsing, and they’re often the deciding factor on whether or not to click. Your photos set the tone for your entire listing, helping to showcase the quality and style of each item. When they are sharp and visually appealing, they immediately convey professionalism and care.

##### Build Trust and Credibility

If your photo quality is low, buyers are less likely to trust the accuracy and quality of your listing. Crisp, high-quality photos increase your credibility and set you apart as a trusted seller.

##### Better for SEO

Eye-catching, high quality visuals boost your chances of showing up in search results on Google.

~~~~light,${colors.white}
#### Types of Photos in a Poshmark Listing

##### 2.1 - Covershot

Your cover photo, or “Covershot,” is the most important image in your listing—it’s the first thing potential buyers see. Think of it as the storefront window to your item. This image should represent the item accurately. One of the best ways to present the item is to model it. However, modelling photos increases processing time.

##### 2.2 - Detail Shots

Poshmark allows up to 16 photos and one video per listing, giving you plenty of space to showcase every detail of your item. However, we recommend using around 5 photos as to not overwhelm the buyer and you can showcase everything in 5 photos.

~~~~light,${colors.white}
#### The Key Elements of Taking Great Poshmark Photos

##### 3.1 - Lighting

Lighting can make or break your photo.

Dull, lackluster photos are not the vibe we’re going for, so why risk losing sales over poor images? Especially when it doesn't take much effort to set up a good lighting source. A well-lit photo highlights the best features of your item, makes colors pop, and draws in more buyers.

Natural light works best. It gives your photos a soft, even glow that enhances colors and details without harsh shadows or distortions, showing your item at its best. The ideal time for natural light is mid to late morning or early afternoon, when the sun is bright but not directly overhead. 

If you're shooting indoors, setting up near a window with indirect light is perfect for clean, well-lit photos. If your workspace isn’t blessed with abundant natural light, a simple lighting kit can still do the trick and work wonders for your photos.

##### 3.2 - Background

Always remember this: Attention spans are short and you have only a few seconds to capture your buyer's attention. Therefore, your item should always be the star of the show, not the items or clutter lingering in your room in the background.

There are countless photos with distracting backgrounds on Poshmark, so having a simple clean background can help your listing to stand out in the search results and works best to keep the focus where it belongs, and that’s on the item you’re selling.

Also, don’t overthink your background; 

a plain sheet or wall is all you need to achieve a clean look, especially if they have a neutral color like white, cream, or grey. But if you don’t have light solid-colored surfaces, you can use a background-editing app like [Pixelcut](https://www.pixelcut.ai/) to remove distractions.

##### 3.3 - Angles

Buyers can’t physically touch or examine your items, so it’s your job to give them the full picture—literally. This means snapping from every angle: front, back, sides, and tags. And don’t forget close-ups of important details like buttons or fabric texture if you think that is important.

Don't forget about the flaws-they're  extremely important details too. 

If your item has a little wear or a small stain, show it. Being upfront about imperfections builds trust and helps manage buyer expectations. No one likes surprises when they open a package, so save yourself the hassle of returns by keeping it real with those flaw shots.

##### 3.4 - Editing

Taking a great photo is only half the battle. 

The finishing touch comes in the editing process, where small adjustments can elevate your images. Notice I said “small adjustments?” You don’t want to overedit images to distort the true appearance of the item. Buyers deserve an accurate representation.

Keep the editing simple: adjust brightness, contrast, and sharpness just enough to enhance the photo without changing the item's real look. And let’s not forget cropping—it’s an essential step to remove distractions and focus on the product itself.

Try to limit the amount of editing as it adds to your overall processing time. Only use when needed or if you can be quick with it.


##### 3.5 - Consistency

Consistency? 

Yes, you don’t want to be all over the place with different styles and techniques for your listing images. Instead, you want to achieve a cohesive look. Stick to similar lighting, backgrounds, and angles for all of your listings. This not only makes your closet more professional but also builds trust with buyers who will come to recognize your style.

~~~~light,${colors.white}
#### Practical Techniques for Capturing Better Photos

##### 4.1 - Keep it Steady

Blurry photos are a big no-no for Poshmark listings. So don't waste shoppers' time with blurry photos.

To avoid shaky or unfocused images, work on keeping your camera steady. A trick you can implement if you're using your phone is to rest your elbows on a stable surface. Honestly, its best to invest in a tripod.

##### 4.2 - Shoot in Square Mode

Since Poshmark displays all listing photos in a square format, it’s smart to start by shooting in square mode. 

This saves you from having to crop your images later and ensures that your item fits perfectly in the frame without cutting off important details. Most smartphones have a built-in square mode in the camera app—just switch to that setting before you start snapping.

##### 4.3 - Rule of Thirds

The rule of thirds is a basic photography principle that helps create balanced and aesthetically pleasing images. To use it, turn on the grid setting on your phone’s camera, which divides your screen into nine equal squares. Instead of placing your item in the center of the shot, position it along the lines or where they cross. This naturally draws attention to key details and makes the image more visually appealing.

~~~~light,${colors.white}
#### Have the Right Tools

Knowing the right elements and techniques of good photography is substantial knowledge. 

But to make everything easier, more efficient, and professional, get a few tools. The best part is that you don’t have to break the bank to make these investments. Let’s look at the most relevant ones:

**Smartphone or Camera:** Both a smartphone and a camera are sufficient for capturing clear, high-quality images. Most modern smartphones have excellent cameras that can handle your Poshmark photos with ease. However, if you already own a DSLR or mirrorless camera, it offers even more control over focus, depth of field, and overall clarity.

**Backdrop:** As you know, your backdrop should be simple and neutral, helping the item stand out without distractions. A plain white sheet works great, but if you want to up your game, consider investing in a roll-up vinyl backdrop. Vinyl backdrops are durable, easy to clean, and give a consistent, professional appearance to all your photos. If you’re short on space, choose a backdrop that can be easily hung and removed. Wrinkle-free vinyl backdrops are affordable and convenient to store when not in use.

**Lighting:** While natural light is the most ideal, it’s not always accessible or available (say, on a rainy day or during the evening). So, having a ring light or softbox could be a temporary replacement. If a ring light feels too bulky or unnecessary, consider a clip-on light for your phone or a tabletop LED light. These portable lighting options are affordable and effective for smaller items.

**Tripod:** A tripod ensures stability and consistency, especially if you’re shooting multiple listings in one session. It allows you to keep your shots steady and your hands-free, whether you're taking full item shots or close-ups of details. Look for a tripod with adjustable height and a phone mount if you’re using your smartphone. For extra efficiency, consider a remote shutter release. This allows you to take photos hands-free, which is especially helpful when you’re styling items or shooting from a distance.

**Mannequin:** Using a mannequin is a great way to show how clothes fit and drape naturally, helping buyers visualize how they’ll wear the item. It also provides structure to your items and showcases them better than hanging or flat-lay photos.

**Stuffing for Clothing Structure:** To give your clothing items a more appealing, structured look, especially for items like jeans or handbags, consider using tissue paper or light padding to fill out the garment. This trick helps buyers better visualize the item’s fit and condition.

##### Photo Checklist

Make your job easier by sticking to a checklist. Here’s a great one to keep your process streamlined:

1. Full shot of the item. Nothing should be cut off.
2. Close-up, detailed shots.
3. Clear photos of any flaws on the item.
4. Measurement shots for key dimensions 
5. Brand label, size tag, and fabric content tag
6. Front, back, and side angles of the garment

Optional: Photos of areas prone to wear (e.g., armpits and cuffs) to show their condition​


~~~~light,${colors.white}
#### Tools and Resources

• [Product Research](https://poshwatch.io/product-research): Find Poshmark's fast-selling products so you can source with confidence.

• [Listing Analyzer](https://poshwatch.io/listing-analyzer): This tool helps you evaluate your listings for improvement, offering insights on titles, descriptions, and pricing so you can fine-tune your approach.

• [Closet Sharer](https://poshwatch.io/poshmark-assistant): Automatically share your closet to keep your listings visible to potential buyers. 

• [Listing Rank Checker](https://poshwatch.io/listing-rank-checker): Understand how your listings rank in Poshmark’s search results and take actionable steps to improve visibility.


~~~~light,${colors.white}
#### Conclusion

Ready, Set, Snap! Take what you’ve learned and start applying it to your next listing. But don’t stop there. For even more real-time data, insights, and tips to keep improving and staying ahead of the game, subscribe to Poshwatch’s newsletter
`;

export default howToTakePhotos;