import React, { useState, useEffect } from 'react';
import { FormControl, FormLabel, Select, Option } from '@mui/joy';
import { Box } from '@mui/joy';

interface FilterOption {
  value: string;
  label: string;
}

interface Filter {
  name: string;
  label: string;
  placeholder: string;
  options: FilterOption[];
  defaultOption?: string | null;
}

interface FilterChange {
  filterName: string;
  value: string | null;
}

interface FilterComponentProps {
  filters: Filter[];
  onChange: (change: FilterChange) => void;
  [key: string]: any;
}

const DataTableFilters: React.FC<FilterComponentProps> = ({ filters, onChange, ...props }) => {
  const [selectedValues, setSelectedValues] = useState<Record<string, string | null>>({});

  useEffect(() => {
    const initialValues: Record<string, string | null> = {};
    filters.forEach((filter) => {
      initialValues[filter.name] = filter.defaultOption ?? filter.options[0]?.value ?? null;
    });
    setSelectedValues(initialValues);
  }, [filters]);

  const handleChange = (filterName: string) => (
    event: React.SyntheticEvent | null,
    value: string | null,
  ) => {
    setSelectedValues((prev) => ({ ...prev, [filterName]: value }));
    onChange({ filterName, value });
  };

  return (
    <Box {...props}>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }} >
        {filters.map((filter) => (
          <FormControl key={filter.name} size="sm" sx={{ minWidth: 120 }}>
            <FormLabel>{filter.label}</FormLabel>
            <Select<string, false>
              size="sm"
              placeholder={filter.placeholder}
              onChange={handleChange(filter.name)}
              value={selectedValues[filter.name] ?? ''}
              slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
            >
              {filter.options.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </FormControl>
        ))}
      </Box>
    </Box>
  );
};

export default DataTableFilters;