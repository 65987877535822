import React from 'react';
import { Grid, Box, Typography } from '@mui/joy';
import DonutChart from './DonutChart.tsx';
import SectionTitle from './SectionTitle.tsx';
import { splitIntoParagraphs } from '../helpers/formatters.ts';

interface ChartData {
  name: string;
  value: number;
}

interface DonutChartProps {
  title: string;
  centerLabel: string;
  data: ChartData[];
}

interface DonutChartGridProps {
  title: string;
  description?: string;
  charts: DonutChartProps[];
}

const DonutChartGrid: React.FC<DonutChartGridProps> = ({ title, description, charts }) => {
  const paragraphs = description ? splitIntoParagraphs(description) : [];

  return (
    <Box sx={{ mt: 4 }}>
      <SectionTitle title={title} />
      {paragraphs.map((paragraph, index) => (
        <Typography
          key={index}
          level="body-md"
          color="neutral"
          sx={{ pb: 2 }}
        >
          {paragraph}
        </Typography>
      ))}
      <Grid container spacing={2}>
        {charts?.map((chart, index) => (
          <Grid key={index} xs={12} sm={6} md={4}>
            <DonutChart
              data={chart.data}
              title={chart.title}
              centerLabel={chart.centerLabel}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}


export default DonutChartGrid;