import React from 'react';
import {
  Box,
  Card,
  Typography,
  Tooltip,
  Chip
} from '@mui/joy';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/joy/IconButton';

interface StatsCardProps {
  title: string;
  mainValue: string | number;
  secondaryValue?: string | number;
  mainLabel?: string;
  secondaryLabel?: string;
  description?: string;
  insight?: {
    label: string;
    level: 'neutral' | 'success' | 'warning' | 'danger';
  }
}

export const StatsCard = ({
  title,
  mainValue,
  secondaryValue,
  mainLabel = 'Sold',
  secondaryLabel = 'Active',
  description,
  insight,
}: StatsCardProps) => (
  <Card variant="outlined" sx={{ p: 2 }}>
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Typography level="title-md">{title}</Typography>
        {description && (
          <Tooltip
            title={description}
            sx={{ zIndex: 2147483647, maxWidth: '600px' }}
            variant="soft"
            placement="top"
            enterTouchDelay={0}
            leaveTouchDelay={5000}
          >
            <IconButton
              variant="plain"
              color="neutral"
              size="sm"
              sx={{ 
                '&:hover': { background: 'transparent' },
                '&:active': { background: 'transparent' }
              }}
            >
              <InfoIcon fontSize="medium" />
            </IconButton>
          </Tooltip>
        )}
      </Box>
      {insight && (
        <Chip
          size="sm"
          variant="soft"
          color={insight.level}
          sx={{ mt: 0.5 }}
        >
          {insight.label}
        </Chip>
      )}
    </Box>
    <Box
      sx={{
        display: 'flex',
        gap: { xs: 1 },
        alignItems: 'left',
        flexWrap: 'nowrap',
      }}
    >
      <Box sx={{ minWidth: 0, flex: 1 }}>
        <Typography
          level="h2"
          sx={{
            color: 'primary.500',
            fontSize: { xs: '1.25rem', sm: '1.3rem', md: '1.5rem' },
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {mainValue}
        </Typography>
        <Typography level="body-sm" color="neutral" sx={{ whiteSpace: 'nowrap' }}>
          {mainLabel}
        </Typography>
      </Box>
      {secondaryValue != null && (
        <>
          <Box
            sx={{
              height: '40px',
              width: '1px',
              bgcolor: 'divider',
              mx: { xs: 0.5, sm: 1 },
            }}
          />
          <Box sx={{ minWidth: 0, flex: 1 }}>
            <Typography
              level="h2"
              sx={{
                color: 'success.500',
                fontSize: { xs: '1.25rem', sm: '1.3rem', md: '1.5rem' },
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {secondaryValue}
            </Typography>
            <Typography level="body-sm" color="neutral" sx={{ whiteSpace: 'nowrap' }}>
              {secondaryLabel}
            </Typography>
          </Box>
        </>
      )}
    </Box>
  </Card>
);
