interface CacheItem<T> {
  data: T;
  timestamp: number;
}

export default class LocalCache<T> {
  private cache: Record<string, CacheItem<T>> = {};
  private readonly CACHE_EXPIRATION = 5 * 60 * 1000; // 5 minutes in milliseconds
  private readonly STORAGE_KEY = 'searchCache';
  private readonly MAX_CACHE_SIZE = 100; // Maximum number of items in the cache
  private storageAvailable: boolean = true;

  constructor() {
    this.loadFromStorage();
  }

  private loadFromStorage(): void {
    try {
      const storedCache = localStorage.getItem(this.STORAGE_KEY);
      if (storedCache) {
        this.cache = JSON.parse(storedCache);
      }
    } catch (error) {
      console.warn('Failed to load from localStorage:', error);
      this.storageAvailable = false;
    }
  }

  private saveToStorage(): void {
    if (!this.storageAvailable) return;

    try {
      localStorage.setItem(this.STORAGE_KEY, JSON.stringify(this.cache));
    } catch (error) {
      console.warn('Failed to save to localStorage:', error);
      this.storageAvailable = false;
      // Optionally, you can try to clear some items and retry
      this.clearOldestItems(10); // Clear 10 oldest items
      try {
        localStorage.setItem(this.STORAGE_KEY, JSON.stringify(this.cache));
      } catch (retryError) {
        console.warn('Failed to save to localStorage after clearing items:', retryError);
      }
    }
  }

  private isCacheFull(): boolean {
    return Object.keys(this.cache).length >= this.MAX_CACHE_SIZE;
  }

  private clearExpiredItems(): void {
    const now = Date.now();
    for (const key in this.cache) {
      if (now - this.cache[key].timestamp >= this.CACHE_EXPIRATION) {
        delete this.cache[key];
      }
    }
  }

  private clearOldestItems(count: number): void {
    const sortedKeys = Object.keys(this.cache).sort((a, b) => 
      this.cache[a].timestamp - this.cache[b].timestamp
    );
    sortedKeys.slice(0, count).forEach(key => delete this.cache[key]);
  }

  get(key: string): T | null {
    const item = this.cache[key];
    if (item) {
      if (Date.now() - item.timestamp < this.CACHE_EXPIRATION) {
        return item.data;
      } else {
        // Item is expired, remove it from the cache
        delete this.cache[key];
        this.saveToStorage();
      }
    }
    return null;
  }

  set(key: string, data: T): void {
    if (this.isCacheFull()) {
      this.clearExpiredItems();
      if (this.isCacheFull()) {
        this.clearOldestItems(1);
      }
    }

    this.cache[key] = {
      data,
      timestamp: Date.now()
    };
    this.saveToStorage();
  }

  clear(): void {
    this.cache = {};
    this.saveToStorage();
  }
}