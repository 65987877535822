import React, { useState } from 'react';
import { lightTheme } from '../customTheme';
import { ResponsiveAppBar } from '../components/ResponsiveAppBar';
import Panel from '../components/Panel';
import { colors } from '../customTheme';
import { Container, Typography, Tabs, Tab, Box } from '@mui/material';
import Footer from '../components/Footer';
import NewsletterPanel from '../components/newsletter-panel/NewsletterPanel';
import { Helmet } from 'react-helmet-async';
import PATHS from '../utils/paths';
import bagsImg from '../images/style_glossary/bags.jpeg';
import beltsImg from '../images/style_glossary/belts.jpeg';
import collarsImg from '../images/style_glossary/collars.jpeg';
import cuffsImg from '../images/style_glossary/cuffs.jpeg';
import dressesImg from '../images/style_glossary/dresses.jpeg';
import hatsImg from '../images/style_glossary/hats.jpeg';
import jacketsImg from '../images/style_glossary/jackets.jpeg';
import jewelryImg from '../images/style_glossary/jewelry.jpeg';
import lingerieImg from '../images/style_glossary/lingerie.jpeg';
import necklinesImg from '../images/style_glossary/necklines.jpeg';
import pantsImg from '../images/style_glossary/pants.jpeg';
import shoesImg from '../images/style_glossary/shoes.jpeg';
import skirtsImg from '../images/style_glossary/skirts.jpeg';
import sleevesImg from '../images/style_glossary/sleeves.jpeg';
import sunglassesImg from '../images/style_glossary/sunglasses.jpeg';
import swimImg from '../images/style_glossary/swim.jpeg';
import waistelinesImg from '../images/style_glossary/example.jpeg';

const TABS = {
  bags: {
    title: "Bags",
    img: bagsImg,
  },
  belts: {
    title: "Belts",
    img: beltsImg,
  },
  collars: {
    title: "Collars",
    img: collarsImg,
  },
  cuffs: {
    title: "Cuffs",
    img: cuffsImg,
  },
  dresses: {
    title: "Dresses",
    img: dressesImg,
  },
  hats: {
    title: "Hats",
    img: hatsImg,
  },
  jackets: {
    title: "Jackets",
    img: jacketsImg,
  },
  jewelry: {
    title: "Jewelry",
    img: jewelryImg,
  },
  lingerie: {
    title: "Lingerie",
    img: lingerieImg,
  },
  Necklines: {
    title: "Necklines",
    img: necklinesImg,
  },
  pants: {
    title: "Pants",
    img: pantsImg,
  },
  shoes: {
    title: "Shoes",
    img: shoesImg,
  },
  skirts: {
    title: "Skirts",
    img: skirtsImg,
  },
  sleeves: {
    title: "Sleeves",
    img: sleevesImg,
  },
  sunglasses: {
    title: "Sunglasses",
    img: sunglassesImg,
  },
  swim: {
    title: "Swim",
    img: swimImg,
  },
  waistelines: {
    title: "Waistelines",
    img: waistelinesImg,
  }
};


const StyleGlossary = () => {
  const canonicalUrl = `${window.location.origin}${PATHS.STYLE_GLOSSARY}`;
  const [selection, setSelection] = useState(TABS.bags.title.toLowerCase());

  // const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  // const isMd = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const handleChange = (event, newValue) => {
    setSelection(newValue);
  };

  const renderTabs = () => {
    return (
      <>
        <Tabs
          value={selection}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          sx={{ 
            mt: 0, 
            pt: 0, 
            mb: 6, // Add lower margin
            '& .MuiTabs-indicator': {
              left: 'auto',
              right: 0,
            }
          }}
        >
          {Object.keys(TABS).map((key, index) => (
            <Tab 
              key={index} 
              label={TABS[key].title} 
              value={key}
            />
          ))}
        </Tabs>
      </>
    );
  };

  const renderHeader = () => {
    return (
      <Container maxWidth='md' style={{ marginBottom: "30px" }}>
        <Typography 
          variant="h1" 
          style={{ fontSize: "2.5rem", marginBottom: "30px", fontFamily:"Libre Baskerville"}} 
          align="center">
            Reseller Style Glossary
        </Typography>
        <Typography 
          variant="body1" 
          align="center" 
          color="textSecondary"
        >
          Identify garment styles with ease, to create accurate and detailed poshmark listing descriptions.
        </Typography>
      </Container>
    )
  };

  const renderGraphics = () => {
    return (
      <>
        <img
          src={TABS[selection].img}
          alt={selection}
        />
      </>
    );
  };

  return (
    <div>
      <Helmet bodyAttributes={{ style: `background-color : ${colors.alabaster}` }}>
        <title>{`Style Glossary: Identify and Describe Garment Styles with Ease to Create More Accurate Poshmark Listings`}</title>
        <meta name="description" content={"With our free style glossary, you can accurately identify and describe various garment styles, from shoes, jeans, sleeves, etc. This shelps you create more accurate and discoverable Poshmark listings, making it easier for shoppers to find your items."} />
        <meta property="og:title" content="Reseller Style Glossary" />
        <meta property="og:description" content="Identify garment styles with ease, to create accurate and detailed poshmark listing descriptions." />
        <meta property="og:url" content="https://poshwatch.io/style-glossary" />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={`${window.location.origin}${canonicalUrl}`} />
      </Helmet>
      <ResponsiveAppBar />
      <Panel theme={lightTheme} style={{ minHeight: "200px" }}>
        { renderHeader() }
      </Panel>
      <Box bgcolor="#ffffff" py={4} >
        <Container style={{ marginBottom: "20px", marginTop: "20px" }}>
          { renderTabs() }
          <Container style={{ 
            display: 'flex', 
            flexDirection: 'column', 
            alignItems: 'center' 
          }}>
            { renderGraphics() }
          </Container>
        </Container>
      </Box>
      <Box bgcolor="#fafafa" py={4} >
        <Container style={{ marginBottom: "20px", marginTop: "20px" }}>
        </Container>
      </Box>
      <NewsletterPanel />
      <Footer />
    </div>
  );
};

export default StyleGlossary;