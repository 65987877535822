export const categoryColors = {
  'Other': '#808080', // Neutral gray
  'Handbags': '#228b22', // Vibrant green
  'Shoes': '#3398d8', // Bright blue
  'Pants': '#e74c33', // Strong red
  'Accessories': '#f1c410', // Vivid yellow
  'Jackets & Blazers': '#e67e21', // Deep orange
  'Tops': '#92399e', // Rich purple

  'Sweaters': '#66cc33', // Forest green
  'Denim': '#000080', // Navy blue
  'Dresses & Skirts': '#993333', // Darker red
  'Jewelry': '#b8860b' // Dark gold
};

export const formatName = (name) => {
  // URL decode the input name
  name = decodeURIComponent(name);

  // Check if the name is all uppercase
  if (name === name.toUpperCase()) {
    name = name.toLowerCase();
  }

  // Replace underscores with spaces
  let prettyName = name.replace(/_/g, " ");

  // Replace "&amp;" with "&"
  prettyName = prettyName.replace(/&amp;/g, "&");

  // Replace - with space
  prettyName = prettyName.replace(/-/g, " ");

  // Capitalize the first letter of each word, but not after apostrophes
  prettyName = prettyName.replace(/\b(\w)|'(\w)/g, (match, p1, p2) => {
    if (p2) return "'" + p2; // If the letter follows an apostrophe, return it as is
    return p1.toUpperCase(); // Otherwise, capitalize the letter
  });

  return prettyName;
};

export const formatCondition = (condition) => {
  if (condition === 'nwt') return 'New with tags';
  if (condition === 'not_nwt') return 'New without tags';
  if (condition === 'ret') return 'Return';
  return condition;
};

export const hyphenateName = (brand) => {
  return brand.toLowerCase().replace(/\s+/g, '-');
}

export const formatTotalPrice = (value) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
    notation: 'compact',
  });
  return formatter.format(value);
};

export const formatPrice = (value) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
  return formatter.format(value);
};

export const formatNumberCompact = (value, maximumFractionDigits = 0) => {
  const formatter = new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    maximumFractionDigits,
  });
  return formatter.format(value);
};

export const formatNumberStandard = (value, maximumFractionDigits = 0) => {
  const formatter = new Intl.NumberFormat('en-US', {
    notation: 'standard',
    compactDisplay: 'short',
    maximumFractionDigits,
  });
  return formatter.format(value);
};

export const calculateSourcePrice = (margin = 15, averagePrice) => {
  const targetMargin = margin / 100; // Convert percentage to a decimal
  const sourcePrice = averagePrice * (1 - targetMargin);
  return sourcePrice.toFixed(2); // Return the source price rounded to 2 decimal places
};

export const getListingIdFromUrl = (url) => {
  const poshListingRegex = /^(https?:\/\/(www\.)?poshmark\.(ca|com)\/listing\/(?:[A-Za-z0-9-]+-)?([0-9a-fA-F]{24}))$/;
  const cleanURL = stripQueryStringAndHashFromPath(url);

  const match = cleanURL.match(poshListingRegex);
  if (match) {
    return match[4];
  }
  return null;
};

export const isLocalhost = () => {
  return window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
};

export const capitalizeFirstLetter = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

const ACTIONS = {
  LIKE: 'like',
  FOLLOW: 'follow',
  UNFOLLOW: 'unfollow',
  SHARE: 'share',
  SHARE_PARTY: 'share_party',
  OFFER_TO_LIKERS: 'offer_to_likers',
  CHECK_LOGIN: 'check_login',
  GET_CURRENT_URL: 'get_current_url',
  STOP_TASK: 'stop_task',
  RESUME_TASK: 'resume_task',
  REDIRECT_TO_SETTINGS: 'redirect_to_settings', // This action is handled by the options page to redirect to settings
};

export const getPastTense = action => {
  switch (action) {
    case ACTIONS.LIKE:
      return 'liked'
    case ACTIONS.FOLLOW:
      return 'followed'
    case ACTIONS.UNFOLLOW:
      return 'unfollowed'
    case ACTIONS.SHARE:
      return 'shared to followers'
    case ACTIONS.SHARE_PARTY:
      return 'shared to party'
    case ACTIONS.OFFER_TO_LIKERS:
      return 'made an offer to likers for'
    default:
      return 'unknown'
  }
};

const stripQueryStringAndHashFromPath = (url) => {
  return url.split("?")[0].split("#")[0];
};

export const buildEndpoint = (listingURL, endpoint) => {
  const queryParams = new URLSearchParams();
  queryParams.append("url", listingURL);
  return `${endpoint}?${queryParams.toString()}`;
};

export const dePluralize = (word) => {
  if (PLURAL_CATEGORY[word]) {
    return PLURAL_CATEGORY[word];
  }
  // Handle words ending in 'ies'
  if (word.endsWith('ies')) {
    return word.slice(0, -3) + 'y';
  }
  // Handle words ending in 'es' but not in 'ses', 'xes', 'zes', 'ches', 'shes'
  if (word.endsWith('es')) {
    // console.log("word endsWith: es")
    const exceptions = ['ses', 'xes', 'zes', 'ches', 'shes'];
    const suffix = word.slice(-4, -2); // get the part before 'es'
    if (!exceptions.includes(suffix + 'es')) {
      return word.slice(0, -2);
    }
  }
  // Handle words ending in 's'
  if (word.endsWith('s') && word.length > 1) {
    return word.slice(0, -1);
  }
  // Return the original word if no conditions matched
  return word;
};

const PLURAL_CATEGORY = {
  shoes: 'shoe',
  wearables: 'wearables',
  headphones: 'headphone',
  games: 'game',
  costumes: 'costume',
  jeans: 'jeans',
  tops: 'tops',
  'one-pieces': 'one piece',
  'jackets-and-coats': 'jacket and coat',
  'intimates-and-sleepwear': 'intimate and sleepwear',
}

export const getUsernameFromUrl = (url) => {
  // Use URL object to parse the URL
  const urlObj = new URL(url);

  // Split the pathname by '/' and get the last segment
  const pathSegments = urlObj.pathname.split('/');

  // Return the last segment which should be the username
  return pathSegments[pathSegments.length - 1];
};

export const calculateListingAge = (publishedDate) => {
  const now = new Date();
  const published = new Date(publishedDate);
  const diffTime = Math.abs(now - published);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  return diffDays;
};

export const formatPath = (name) => {
  return decodeURIComponent(name) // Decode URL-encoded characters, including spaces
    .replace(/_/g, "-") // Replace underscores with hyphens
    .replace(/&/g, "and") // Replace & with 'and'
    .replace(/,/g, "") // Remove commas
    .replace(/\./g, "") // Remove dots
    .replace(/'/g, "") // Remove single quotes
    .replace(/\s+/g, "-") // Replace any number of spaces with a single dash
    .replace(/-+/g, "-") // Replace any number of hyphens with a single hyphen
    .toLowerCase(); // Convert everything to lowercase
};