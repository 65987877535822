import React, { useEffect, useState } from 'react';
import {
  Paper,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Box,
  Skeleton,
  Alert,
  Chip,
  Avatar,
  Container,
  Tabs,
  Tab,
  styled,
} from '@mui/material';
import { getChart } from '../../../api/client';
import { useNavigate } from 'react-router-dom';
import { formatName, formatPath } from '../../../utils/helpers';
import GroupsTwoToneIcon from '@mui/icons-material/GroupsTwoTone';
import DiamondTwoToneIcon from '@mui/icons-material/DiamondTwoTone';
import LocalMallTwoToneIcon from '@mui/icons-material/LocalMallTwoTone';
import { colors } from '../../../customTheme';
import PATHS from "../../../utils/paths";
import useMediaQuery from '@mui/material/useMediaQuery';

const CustomTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  color: '#666',
  '&.Mui-selected': {
    color: colors.mineShaft,
  },
  '& .MuiSvgIcon-root': {
    color: '#999',
  },
  '&.Mui-selected .MuiSvgIcon-root': {
    color: colors.mineShaft,
  }
}));

interface TopItem {
  name: string;
  quantity: number;
  sales: number;
}

interface CategoryData {
  name: string;
  quantity: number;
  sales: number;
}

interface ChartData {
  id: number;
  name: string;
  totalPrice: number;
  soldItemsCount: number;
  averageSalePrice: number;
  categories: CategoryData[];
  brands: TopItem[];
  sellerInfo: {
    name: string;
    imageUrl: string;
  };
}

interface DashboardState {
  data: ChartData[] | null;
  loading: boolean;
  type: 'creators' | 'brands' | 'categories';
  tabValue: number;
}

const INITIAL_STATE: DashboardState = {
  data: null,
  loading: true,
  type: 'creators',
  tabValue: 0,
};

const TAB_CONFIG = [
  { type: 'creators', label: 'Top Resellers', icon: <GroupsTwoToneIcon /> },
  { type: 'brands', label: 'Top Brands', icon: <DiamondTwoToneIcon /> },
  { type: 'categories', label: 'Top Categories', icon: <LocalMallTwoToneIcon /> },
] as const;

const Charts: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => (theme as any).breakpoints.down('sm'));
  const [state, setState] = useState<DashboardState>(INITIAL_STATE);

  const fetchData = async (type: DashboardState['type']) => {
    setState(prev => ({ ...prev, loading: true }));

    try {
      const result = await getChart(type, true);
      const filteredData = result?.results
        ?.filter(item => item.name.toLowerCase() !== "unknown")
        .slice(0, 15)
        ?.map((item, index) => ({
          id: index + 1,
          name: item.name,
          totalPrice: item.sales,
          soldItemsCount: item.itemsSold,
          averageSalePrice: item.averagePrice,
          categories: item.topCategories,
          brands: item.topBrands,
          sellerInfo: {
            name: item.name,
            imageUrl: item.image_url,
          }
        }));

      setState(prev => ({
        ...prev,
        data: filteredData,
        loading: false
      }));
    } catch (error) {
      console.error(error);
      setState(prev => ({
        ...prev,
        data: null,
        loading: false
      }));
    }
  };

  useEffect(() => {
    fetchData(state.type);
  }, [state.type]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    const newType = TAB_CONFIG[newValue].type;
    setState(prev => ({
      ...prev,
      tabValue: newValue,
      type: newType
    }));
  };

  const handleRowClick = (name: string) => {
    if (state.type === 'creators') {
      navigate(`seller/${name}`);
    }
    else if (state.type === 'brands') {
      navigate(`brand/${formatPath(name)}`);
    }
    else if (state.type === 'categories') {
      navigate(`category/${formatPath(name)}`);
    }
  };

  const renderTableContent = () => {
    if (state.loading) {
      return (
        <Table sx={{
          tableLayout: 'fixed',
          '& .MuiTableCell-head': {
            fontWeight: 600,
            py: 1.5,
            px: 2,
            whiteSpace: 'nowrap',
            fontSize: '0.875rem',
          },
          '& .MuiTableCell-body': {
            py: 1.5,
            fontSize: '0.875rem',
          },
        }}>
          <TableHead style={{ backgroundColor: "#f7f7f7" }}>
            <TableRow>
              <TableCell style={{ width: '50px' }}>Rank</TableCell>
              <TableCell style={{ width: isMobile ? '50%' : '20%' }}>Name</TableCell>
              <TableCell style={{ width: isMobile ? '40%' : '15%' }} align="right">Total Sales</TableCell>
              {!isMobile && (
                <>
                  <TableCell style={{ width: '10%' }} align="right">Items Sold</TableCell>
                  <TableCell style={{ width: '10%' }} align="right">Avg. Price</TableCell>
                  <TableCell style={{ width: '35%' }}>{state.type === 'brands' ? 'Top Categories' : 'Top Brands'}</TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {[...Array(15)].map((_, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Skeleton variant="rounded" width={40} height={25} />
                </TableCell>
                <TableCell>
                  <Skeleton variant="rounded" width="80%" height={25} />
                </TableCell>
                <TableCell align="right">
                  <Skeleton variant="rounded" width={60} height={25} />
                </TableCell>
                {!isMobile && (
                  <>
                    <TableCell align="right">
                      <Skeleton variant="rounded" width={40} height={25} />
                    </TableCell>
                    <TableCell align="right">
                      <Skeleton variant="rounded" width={60} height={25} />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="rounded" width="70%" height={25} />
                    </TableCell>
                  </>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      );
    }

    if (!state.data || state.data.length === 0) {
      return (
        <Box sx={{ p: 4 }}>
          <Alert severity="info">No sales data available.</Alert>
        </Box>
      );
    }

    return (
      <Table sx={{
        tableLayout: 'fixed',
        '& .MuiTableCell-head': {
          fontWeight: 600,
          py: 1.5,
          px: 2,
          whiteSpace: 'nowrap',
          fontSize: '0.875rem',
        },
        '& .MuiTableCell-body': {
          py: 1.5,
          fontSize: '0.875rem',
        },
        '& .MuiTableRow-root:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.04)',
          cursor: 'pointer',
          transition: 'background-color 0.2s ease-in-out',
        },
      }}>
        <TableHead style={{ backgroundColor: "#f7f7f7" }}>
          <TableRow>
            <TableCell style={{ width: '50px' }}>Rank</TableCell>
            <TableCell style={{ width: isMobile ? '50%' : '20%' }}>Name</TableCell>
            <TableCell style={{ width: isMobile ? '40%' : '15%' }} align="right">Total Sales</TableCell>
            {!isMobile && (
              <>
                <TableCell style={{ width: '10%' }} align="right">Items Sold</TableCell>
                <TableCell style={{ width: '10%' }} align="right">Avg. Price</TableCell>
                <TableCell style={{ width: '35%' }}>{state.type === 'brands' ? 'Top Categories' : 'Top Brands'}</TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {state.data.map((row) => (
            <TableRow key={row.name} onClick={() => handleRowClick(row.name)} hover>
              <TableCell>
                <Chip
                  label={`#${row.id}`}
                  size="small"
                  sx={{
                    minWidth: '2.5rem',
                    justifyContent: 'center',
                    fontWeight: 500,
                    fontSize: '0.75rem',
                  }}
                />
              </TableCell>
              <TableCell>
                <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1.5,
                  '& > div:last-child': {
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap'
                  }
                }}>
                  <Avatar
                    sx={{ width: 28, height: 28 }}
                    src={row.sellerInfo.imageUrl}
                    alt={row.name.toUpperCase()}
                  >
                    {row.name.charAt(0).toUpperCase()}
                  </Avatar>
                  <Typography variant="body2" fontWeight={500} noWrap>
                    {formatName(row.name)}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell align="right">
                <Typography variant="body2" color="success.main" fontWeight={500}>
                  ${row.totalPrice.toLocaleString()}
                </Typography>
              </TableCell>
              {!isMobile && (
                <>
                  <TableCell align="right">
                    <Typography variant="body2">
                      {row.soldItemsCount.toLocaleString()}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body2">
                      ${row.averageSalePrice.toFixed(2)}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                      {(state.type === 'brands' ? row.categories : row.brands).slice(0, 3).map((item, index) => (
                        <Chip
                          key={index}
                          label={formatName(item.name)}
                          size="small"
                          sx={{
                            backgroundColor: 'rgba(0, 0, 0, 0.08)',
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.12)',
                            },
                          }}
                        />
                      ))}
                    </Box>
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  return (
    <div>
      <Container maxWidth="md"
        sx={{
          pb: 4,
          p: { xs: 2, lg: 2 },
        }}>
        <Typography
          fontFamily="Libre Baskerville"
          variant="h3"
          align="center"
          sx={{ fontWeight: 'bold' }}
          paddingBottom={4}
        >
          Poshmark's top sellers
        </Typography>
        <Typography
          variant="h4"
          align="center"
          color="textSecondary"
          paddingBottom={4}
          style={{
            fontSize: "1.2em",
            fontWeight: 400,
            lineHeight: 1.6,
          }}
        >
          Uncover the brands and styles that are driving sales, spot pricing trends, and <br /> see how successful sellers are building their businesses.
        </Typography>
      </Container>

      <Paper
        variant="outlined"
        elevation={1}
        sx={{
          maxWidth: '1200px',
          minWidth: isMobile ? 'auto' : '800px',
          borderRadius: 2,
          overflow: 'auto',
          mx: { xs: 2, sm: 'auto' },
        }}
        style={{
          border: `1px dashed #939598`,
          boxShadow: `-8px 8px 0px 0px ${colors.wafer}`,
        }}
      >
        <Box sx={{ borderBottom: 1, pl: 0, pt: 1, borderColor: 'divider' }}>
          <Tabs
            value={state.tabValue}
            onChange={handleTabChange}
            aria-label="sales dashboard tabs"
            variant={isMobile ? 'standard' : 'fullWidth'}
            TabIndicatorProps={{ sx: { display: 'none' } }}
            sx={{
              pb: 1,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {TAB_CONFIG.map((tab, index) => (
              <CustomTab
                key={tab.type}
                icon={tab.icon}
                label={tab.label}
              />
            ))}
          </Tabs>
        </Box>

        {renderTableContent()}
      </Paper>
      <Box
        display="flex"
        justifyContent="center"
        width="100%"
      >
        <Button
          variant="text"
          href={
            state.type === 'creators'
              ? PATHS.TOP_SELLERS
              : state.type === 'brands'
                ? PATHS.TOP_BRANDS
                : PATHS.TOP_CATEGORIES
          }
          sx={{
            marginTop: 6,
            fontSize: "1.2em",
            textTransform: 'none',
          }}
        >
          See 100+ more →
        </Button>
      </Box>
    </div>
  );
};

export default Charts;
