import { Container, Divider } from '@mui/material';

const StyledDivider = () => {
  return (
    <Container maxWidth="lg">
      <Divider color='#555' />
    </Container>
  );
};

export default StyledDivider;