import React from 'react';
import Typography from '@mui/material/Typography';

const Paragraph = ({ variant = 'body1', paragraphStyle, children }) => {
  // Split the children by double line breaks
  const paragraphs = children.split(/\n\s*\n/);

  return (
    <>
      {paragraphs.map((paragraph, index) => (
        <Typography key={index} variant={variant} style={paragraphStyle} paragraph>
          {paragraph.trim()}
        </Typography>
      ))}
    </>
  );
};

export default Paragraph;
