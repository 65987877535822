import React from 'react';
import { Container, Box, Typography } from '@mui/material';
import Panel from './Panel';

const FeaturePanel = ({
  title,
  subtitle,
  style,
  theme,
  backgroundColor,
  children,
}) => {
  return (
    <Panel
      theme={theme}
      backgroundColor={backgroundColor}
      backgroundImage={`url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23f0f0f00a' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E")`}
      style={{
        ...style,
        display: 'flex',
        flexDirection: 'column',
        minHeight: '55vh',
      }}
    >
      <Box
        sx={{
          paddingTop: 10,
          width: '100%',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
        }}
      >
        <Container sx={{ maxWidth: '700px !important' }}>
          {title}
          <Typography
            variant="h2"
            align="center"
            color="textSecondary"
            style={{
              fontSize: "1.3em",
              fontWeight: 400,
              lineHeight: 1.6,
            }}
            sx={{
              pl: {
                xs: 4,
                sm: 0,
              },
              pr: {
                xs: 4,
                sm: 0,
              },
              pb: 5
            }}
          >
            {subtitle}
          </Typography>
        </Container>
        <Container sx={{ maxWidth: '700px !important' }}>
          {children}
        </Container>
      </Box>
    </Panel>
  );
};

export default FeaturePanel;