import React from 'react';
import { Box } from '@mui/material';
import BrandsPageDetails from './BrandsPageDetails';
import CategoryPageDetails from './CategoryPageDetails';
import SellersPageDetails from './SellersPageDetails';

const ToolDetailSection = ({ type, children }) => {

  const setDetailsType = () => {
    let contentType;
    if (type === "brands") {
      contentType = <BrandsPageDetails />
    } else if (type === "categories") {
      contentType = <CategoryPageDetails />
    } else if (type === "creators") {
      contentType = <SellersPageDetails />
    } else {
      contentType = <></>
    }
    return contentType;
  }

  return (
    <Box sx={{ pt: 4 }}>
      {setDetailsType()}
      {children}
    </Box>
  );
};

export default ToolDetailSection;
