import React from 'react';
import { ResponsiveAppBar } from '../components/ResponsiveAppBar';
import { Container, Typography } from '@mui/material';
import Panel from '../components/Panel';
import Footer from '../components/Footer';
import { Helmet } from 'react-helmet-async';
import PATHS from '../utils/paths';

const NotFound = () => {
  return (
    <div>
      <Helmet>
        <title>Page not found</title>
        <meta name="description" content={"Page not found"} />
        <link rel="canonical" href={`${window.location.origin}${PATHS.GUIDES}`} />
      </Helmet>
      <ResponsiveAppBar />
      <Panel style={{ minHeight: '900px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Container maxWidth="md" style={{ textAlign: 'center' }}>
          <Typography variant='h1' sx={{ pb: 1 }}>404</Typography>
          <Typography variant='h3' color="textSecondary">Page not found</Typography>
        </Container>
      </Panel>
      <Footer />
    </div>
  );
};

export default NotFound;