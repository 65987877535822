import React from 'react';
import { formatTotalPrice, formatName, formatPath, formatNumberStandard } from '../utils/helpers';
import { TopPage, NameCell } from './TopPage';
import PATHS from '../utils/paths';
import CustomChip from '../components/CustomChip.tsx';

const showAvatar = true;

const columns = [
  {
    field: 'id',
    headerName: 'Rank',
    width: 80,
    sortable: true,
  },
  {
    field: 'name',
    headerName: 'Brand',
    flex: 2,
    renderCell: (params) => (
      <NameCell name={formatName(params.value)} href={`/brand/${formatPath(params.value)}`} />
    ),
    sortable: true,
  },
  {
    field: 'totalPrice',
    headerName: 'Sales',
    type: 'number',
    flex: 2,
    valueFormatter: ({ value }) => formatTotalPrice(value),
    sortable: true,
  },
  {
    field: 'soldItemsCount',
    headerName: 'Items Sold',
    type: 'number',
    flex: 2,
    true: ({ value }) => formatNumberStandard(value),
    sortable: true,
  },
  {
    field: 'averageSalePrice',
    headerName: 'Average Price',
    type: 'number',
    flex: 2,
    valueFormatter: ({ value }) => formatTotalPrice(value),
    sortable: true,
  },
  {
    field: 'categories',
    headerName: 'Top Categories',
    sortable: false,
    flex: 4,
    renderCell: (params) => (
      <div>
        {params.value
          .slice(0, 3)
          .map((category, index) => (
            <CustomChip
              key={index}
              showAvatar={showAvatar}
              name={category.name}
              count={category.quantity}
              index={index}
            />
          ))}
      </div>
    ),
  },
];

const TopBrands = () => {
  return (
    <TopPage
      type={"brands"}
      columns={columns}
      title={"The 100 Best Selling Brands on Poshmark"}
      description={"Curious about what's trending? Use this resource to help you source top-selling high value items for your Poshmark closet to boost your sales."}
      href={PATHS.TOP_BRANDS}
      toggleOptions={[
        {
          name: 'date-range',
          label: 'Date Range',
          placeholder: 'Filter by date range',
          options: [
            { value: '1m', label: '1 Month' },
          ],
        },
        {
          name: 'include-luxury',
          label: 'Show Luxury Brands',
          placeholder: 'Show luxury brands',
          options: [
            { value: true, label: 'Yes' },
            { value: false, label: 'No' },
          ],
        },
      ]}
      subtitle={
        < div >
          <p>
            Insights from Poshmark sales data to help you source high demand items for your closet so you can make faster sales.
          </p>
        </div >
      }
    />
  );
};

export default TopBrands;
