import TimeToShare from '../images/time-to-share.jpg';
import { colors } from '../customTheme';

const resellerTips = `
~~~~light,${colors.white}
# 5 Things Every Poshmark Reseller Should Do In 2023

<div style="text-align: center; font-family: Inter; margin-bottom: 6px">
Last updated Dec. 18, 2023
</div>
<div style="text-align: center; font-family: Inter">
Written by Alice
</div>
\n&nbsp;\n

Over the past three years, I've learned a thing or two about making a living by selling clothes online, especially on Poshmark. Here are my top five tips to help you boost your sales and enjoy the journey.

![Best time to share on Poshmark](${TimeToShare})

~~~~light,${colors.white}
#### 1. List Daily, Sell More

Listing new items daily is my golden rule. It's not about playing to an algorithm; it's about constantly offering fresh picks to your buyers. If you’re short on inventory, relist older items. It keeps your closet active and improves visibility.

~~~~light,${colors.white}
#### 2. Share Your Items, Not Others'

Focus on sharing your own closet. When you share your items, they climb higher in search results. Other people's shares? They don’t impact your sales. So, prioritize your closet and save time.

~~~~light,${colors.white}
#### 3. Smart Pricing: Hit the Sweet Spots

Pricing is crucial. Avoid odd numbers like $55 or $105. Instead, aim for $50 or $100. These are magic numbers in Poshmark’s price filters, making your items visible in more searches.

~~~~light,${colors.white}
#### 4. Fast Offers to Likers

When someone likes an item, send them an offer quickly. They're more likely to buy when the item is fresh in their mind. I find this strategy far more effective than waiting to send bulk offers.

~~~~light,${colors.white}
#### 5. Stop the Follower Chase

A huge follower count feels great but doesn't necessarily boost sales. Focus on quality listings and connecting with genuine buyers. That's where the real business happens.

Running my online reselling business is an adventure. These tips have been my roadmap to success. Remember, every small step you take refines your strategy and brings you closer to your goals. Let’s keep the hustle fun and rewarding!

`;

export default resellerTips;