import React from 'react';
import { Typography, Table, Sheet, Button } from '@mui/joy';
import PATHS from '../../../utils/paths';

interface SummaryColumn {
  key: string;
  label: string;
  format?: (value: any) => string | number;
}

interface SummaryRow {
  [key: string]: any;
}

interface SummaryTableProps {
  title: string;
  items: SummaryRow[];
  columns: SummaryColumn[];
  signedIn?: boolean;
}

const SummaryTable: React.FC<SummaryTableProps> = ({
  title,
  items,
  columns,
  signedIn = false,
}) => {
  const visibleItems = signedIn ? items : items.slice(0, 3);
  const hiddenItems = !signedIn ? items.slice(3) : [];

  return (
    <Sheet
      variant="outlined"
      sx={{
        borderRadius: 'sm',
        overflow: 'hidden',
        marginBottom: 2,
        height: '100%',
        position: 'relative'
      }}
    >
      <Typography sx={{ padding: 2 }}>{title}</Typography>
      <Table hoverRow>
        <thead>
          <tr>
            {columns.map((column, index) => (
              <th
                key={index}
                style={{
                  padding: '12px 16px',
                  textAlign: index === 1 ? 'right' : 'left'
                }}
              >
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* Visible Rows */}
          {visibleItems.map((item, index) => (
            <tr key={index}>
              {columns.map((column, colIndex) => (
                <td
                  key={colIndex}
                  style={{
                    padding: '12px 16px',
                    textAlign: colIndex === 1 ? 'right' : 'left'
                  }}
                >
                  {column.format ? column.format(item[column.key]) : item[column.key]}
                </td>
              ))}
            </tr>
          ))}

          {/* Blurred Rows */}
          {!signedIn && hiddenItems.length > 0 && (
            <tr style={{ position: 'relative' }}>
              <td colSpan={columns.length} style={{ padding: 0 }}>
                <div style={{
                  filter: 'blur(4px)',
                  opacity: 0.7,
                  pointerEvents: 'none'
                }}>
                  {hiddenItems.map((item, index) => (
                    <tr key={index}>
                      {columns.map((column, colIndex) => (
                        <td
                          key={colIndex}
                          style={{
                            padding: '12px 16px',
                            textAlign: colIndex === 1 ? 'right' : 'left'
                          }}
                        >
                          {column.format ? column.format(item[column.key]) : item[column.key]}
                        </td>
                      ))}
                    </tr>
                  ))}
                </div>
                {/* Sign In Overlay */}
                <div style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: 'linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.9) 100%)',
                  padding: '16px'
                }}>
                  <Button
                    variant="solid"
                    color="primary"
                    href={PATHS.REGISTER}
                    component="a"
                    sx={{
                      minWidth: 120,
                      backdropFilter: 'blur(4px)',
                    }}
                  >
                    Sign up for free
                  </Button>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </Sheet>
  );
};

export default SummaryTable;