/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { colors } from '../customTheme';
import { Card, CardContent, List, ListItem, ListItemText, Button, Typography, Box, TextField, Divider, LinearProgress, Link } from '@mui/material';
import Report from '@mui/icons-material/Report';
import Verified from '@mui/icons-material/Verified';
import { HoverRating } from '../components/HoverRating';
import useMediaQuery from '@mui/material/useMediaQuery';
import AnalyzerBenefits from '../components/AnalyzerBenefits';
import AnalyzerDetails from '../components/AnalyzerDetails';
import AnalyzerVideo from '../components/AnalyzerVideo';
import { Helmet } from 'react-helmet-async';
import PATHS from "../utils/paths";
import Dashboard from './dashboard/Dashboard.tsx';
import { Experimental_CssVarsProvider } from '@mui/material/styles';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import LocalOffer from '@mui/icons-material/LocalOffer';
import Title from '@mui/icons-material/Title';
import Description from '@mui/icons-material/Description';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import Share from '@mui/icons-material/Share';
import Style from '@mui/icons-material/Style';
import Palette from '@mui/icons-material/Palette';
import Label from '@mui/icons-material/Label';
import Info from '@mui/icons-material/Info';

// import { fetchCoverPhotoAnalysis } from "../api/client";
// import LocalCache from '../utils/LocalCache.ts';

export const Analyzer = ({ platform, getListingAnalysisCallback, canonicalUrl }) => {
  // const listingCache = useRef(new LocalCache());
  const navigate = useNavigate();
  const location = useLocation();
  const [query, setQuery] = useState('');
  const [result, setResult] = useState(null);
  // const [photoResult, setPhotoResult] = useState(null);
  // const [loadingPhotoAnalysis, setLoadingPhotoAnalysis] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const initialQueryRun = useRef(false);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const isLg = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const handleAnalyzeClick = useCallback(async (q = query) => {
    try {
      if (loading) {
        return;
      }
      if (platform.toLowerCase() !== "poshmark" && !q.includes(platform.toLowerCase())) {
        throw new Error(JSON.stringify({ message:`Invalid URL: Please use a ${platform} listing URL`}));
      } else if (!(q.includes(platform.toLowerCase()) || q.includes("posh.mk"))) {
        throw new Error(JSON.stringify({ message:`Invalid URL: Please use a ${platform} listing URL`}));
      } else if (q.includes("/closet/")) {
        throw new Error(JSON.stringify({ message:`Invalid URL: Please use a listing URL not a closet URL`}));
      }

      if(platform.toLowerCase() === "ebay" && !q.includes("itm")){
        throw new Error(JSON.stringify({ message: `Invalid URL: Please use a ${platform} listing URL`}));
      }
      setLoading(true);
      setError(null);

      const res = await getListingAnalysisCallback({ listingURL: q });

      setResult(res);
      setLoading(false);

      // Update URL with query parameter
      const searchParams = new URLSearchParams(location.search);
      searchParams.set('q', q);
      navigate(`${location.pathname}?${searchParams.toString()}`, { replace: true });
    } catch (error) {
      const parsedError = JSON.parse(error.message);
      if (parsedError.statusCode >= 500 || parsedError.message.includes("500")) {
        setError("Invalid Url");
      } else if (parsedError.statusCode >= 400) {
        setError(`Oops, ${parsedError.message}` || "Oops, system error");
      } else {
        setError(parsedError.message);
      }
      setLoading(false);
    }
  }, [getListingAnalysisCallback, loading, location.pathname, location.search, navigate, platform, query]);

  useEffect(() => {
    if (!initialQueryRun.current) {
      const searchParams = new URLSearchParams(location.search);
      const queryParam = searchParams.get('q');

      if (queryParam) {
        setQuery(queryParam);
        handleAnalyzeClick(queryParam);
      }
      initialQueryRun.current = true;
    }
  }, [location.search, handleAnalyzeClick]);

  // useEffect(() => {
  //   const analyzePhoto = async () => {
  //     const cachedPhotoFeedback = listingCache.current.get(result?.coverShot)

  //     if (cachedPhotoFeedback) {
  //       setPhotoResult(cachedPhotoFeedback);
  //     } else if (!photoResult && result) {
  //       setLoadingPhotoAnalysis(true);
  //       const res = await fetchCoverPhotoAnalysis({ photoURL: result.coverShot });
  //       setLoadingPhotoAnalysis(false);
  //       setPhotoResult(res);
  //       listingCache.current.set(result.coverShot, res);
  //     }
  //   };

  //   const shouldSkipAnalysis = () => {
  //     const searchParams = new URLSearchParams(window.location.search);
  //     return searchParams.has('skipPhotoAnalysis'); // Change 'skipPhotoAnalysis' to the specific query param you want to check
  //   };

  //   if (platform.toLowerCase() === "poshmark" && !shouldSkipAnalysis()) {
  //     analyzePhoto();
  //   }

  // }, [result, photoResult, platform]);

  const renderHeading = () => {
    return (
      <div>
        <Box sx={{ pb: 2 }}>
          <Typography mb={2} variant='h1' style={{
            fontSize: "1.8rem",
            fontWeight: 'bold',
            fontFamily: "Libre Baskerville"
          }} >{platform} Listing Analyzer</Typography>
          <Typography mb={1} variant='body1' color="textSecondary">
            {`Struggling with your listings?`}
          </Typography>
          <Typography mb={6} variant='body1' color="textSecondary">
            {`Get insights to optimize, boost traffic, and sell more—then sell some more.`}
          </Typography>
          <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems={isMobile ? 'stretch' : 'center'}>
            <TextField
              size="small"
              label="Enter a listing URL to get feedback..."
              variant="outlined"
              fullWidth
              value={query}
              style={{
                paddingRight: '12px',
              }}
              onChange={(e) => setQuery(e.target.value)}
              error={error ? true : false}
              helperText={error}
            />

            <Button
              variant="contained"
              color="primary"
              onClick={() => handleAnalyzeClick()}
              style={{
                borderRadius: 10,
                height: 44,
                width: isMobile ? '100%' : 220,
                marginLeft: isMobile ? 0 : 10,
                marginTop: isMobile ? 10 : 0,
                boxShadow: 'none',
                backgroundColor: colors.mineShaft,
              }}
            >
              Analyze
            </Button>
          </Box>
        </Box>
      </div>
    )
  }

  // const renderPhotoAnalysisResults = () => {
  //   return(
  //     <Container style={{ marginTop: "10px", marginBottom: "10px" }}>
  //       {
  //         loadingPhotoAnalysis ?
  //           <>
  //             <CircularProgress color="secondary" />
  //             <Typography variant="h6">Performing photo analysis</Typography>
  //           </>
  //         :
  //         <List>
  //         { 
  //           photoResult?.rules?.map((item, index) => (
  //             <React.Fragment key={index}>
  //               <ListItem key={index}
  //                 secondaryAction={
  //                   item.value ? <Verified style={{ color: '#33cc99' }} /> : <Report style={{ color: '#f64f45' }} />
  //                 }>
  //                 <ListItemText
  //                   key={index}
  //                   primary={item.title}
  //                   secondary={
  //                     <React.Fragment key={index}>
  //                       {item.subtitle}
  //                       {item.improvements && (
  //                         <ul style={{ marginTop: '15px' }}>
  //                           {item.improvements.map((improvement, idx) => (
  //                             <li key={idx} style={{ marginBottom: '7px' }}>{improvement}</li>
  //                           ))}
  //                         </ul>
  //                       )}
  //                     </React.Fragment>
  //                   }>
  //                 </ListItemText>
  //               </ListItem>

  //             </React.Fragment>
  //           ))}
  //           </List>
  //       }
  //     </Container>  
  //   )
  // }

  const renderResults = () => {
    return (
      <Box display="flex" sx={{ pb: 4 }} flexDirection={isLg ? "row" : "column"} alignItems="stretch">
        {loading &&
          <Card variant="outlined" style={{ marginTop: '12px', flex: 1 }}>
            <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Box sx={{ width: '100%' }}>
                <LinearProgress />
              </Box>
            </CardContent>
          </Card>}

        {!loading && result?.rules?.length > 0 &&
          <>
            <Card variant="outlined" padding={2} style={{ marginTop: '12px', marginRight: isLg ? '12px' : '0px' }}>
              <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant="h5" fontFamily={"Libre Baskerville"} style={{ marginTop: '20px', marginBottom: '20px' }}>Listing Score</Typography>

                <HoverRating value={result.score.rating} label={result.score.word_rating} style={{ paddingTop: '12px' }} />

                <Typography variant="body1" color="textSecondary" textAlign="center" mt={1} padding={2} style={{ maxWidth: 240 }}>
                  {result.score.sentence_rating}
                </Typography>
                <Link href={`${PATHS.GUIDES}/how-create-poshmark-listings-that-sell`} variant="body2">Learn to Create Listings that Sell</Link >
              </CardContent>
            </Card>

            <Card variant="outlined" style={{ marginTop: '12px', flex: 1 }}>
              <CardContent style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {/* { renderPhotoAnalysisResults() }
                { platform.toLowerCase() === "poshmark" ? <Divider /> : null } */}
                <List>
                  {result?.rules?.map((item, index, array) => {
                    const isFirstOfType = index === 0 || array[index - 1]?.type !== item.type;
                    
                    return (
                      <React.Fragment key={index}>
                        <ListItem
                          secondaryAction={
                            item.value ? <Verified style={{ color: '#33cc99' }} /> : <Report style={{ color: '#f64f45' }} />
                          }>
                          <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                            <Box sx={{ mr: 4, display: 'flex', alignItems: 'center', width: 24 }}>
                              {isFirstOfType && getFeedbackIcon(item.type)}
                            </Box>
                            <ListItemText
                              secondary={
                                <React.Fragment>
                                  <Typography component="span" variant="subtitle1" sx={{ display: 'block', mb: 1.5, color: 'text.primary', fontStyle: 'italic' }}>
                                    {item.title}
                                  </Typography>
                                  <Typography sx={{ color: 'text.secondary', mt: 1 }}>
                                    {item.subtitle}
                                  </Typography>
                                </React.Fragment>
                              }
                            />
                          </Box>
                        </ListItem>
                        {index !== array.length - 1 && array[index + 1]?.type !== item.type && (
                          <Divider component="li" />
                        )}
                      </React.Fragment>
                    );
                  })}
                </List>
              </CardContent>
            </Card>
          </>
        }
      </Box>
    );
  };

  const getFeedbackIcon = (type) => {
    switch (type) {
      case 'freshness':
        return <AccessTimeFilledIcon fontSize="medium" sx={{ mr: 1, color: 'text.secondary' }} />;
      case 'condition':
        return <LocalOffer fontSize="medium" sx={{ mr: 1, color: 'text.secondary' }} />;
      case 'title':
        return <Title fontSize="medium" sx={{ mr: 1, color: 'text.secondary' }} />;
      case 'description':
        return <Description fontSize="medium" sx={{ mr: 1, color: 'text.secondary' }} />;
      case 'photos':
        return <PhotoCamera fontSize="medium" sx={{ mr: 1, color: 'text.secondary' }} />;
      case 'shares':
        return <Share fontSize="medium" sx={{ mr: 1, color: 'text.secondary' }} />;
      case 'styleTags':
        return <Style fontSize="medium" sx={{ mr: 1, color: 'text.secondary' }} />;
      case 'color':
        return <Palette fontSize="medium" sx={{ mr: 1, color: 'text.secondary' }} />;
      case 'brandTag':
        return <Label fontSize="medium" sx={{ mr: 1, color: 'text.secondary' }} />;
      default:
        return <Info fontSize="medium" sx={{ mr: 1, color: 'text.secondary' }} />;
    }
  };

  return (
    <Dashboard>
      <Helmet>
        <title>{`Listing Analyzer for ${platform}: Evaluate, and optimize your listings`}</title>
        <meta name="description" content={"With Listing Analyzer, you can effortlessly enhance your listings to boost visibility and increase sales with a single click."} />
        <meta property="og:title" content={`${platform} Listing Analyzer`} />
        <meta property="og:description" content="Improve your listings and make more sales." />
        <meta property="og:url" content={`https://poshwatch.io/listing-analyzer-${platform}`} />
        <meta property="og:type" content="website" />
        <link rel="canonical" href={`${window.location.origin}${canonicalUrl}`} />
      </Helmet>
      <Experimental_CssVarsProvider>
        <Box>
          {renderHeading()}
          {renderResults()}
          {
            result && result.listing && (
              <Box display="flex" justifyContent="center" width="100%" mb={6}>
                <Button
                  variant="text"
                  href={`${PATHS.PRODUCT_RESEARCH}?q=${result.listing.title}`}
                  sx={{
                    fontSize: "1.2em",
                    textTransform: 'none',
                  }}
                >
                  See sales stats for this product →
                </Button>
              </Box>
            )
          }
        </Box>
        {platform === 'Poshmark' ? <AnalyzerVideo /> : <AnalyzerBenefits />}
        <AnalyzerDetails platform={platform} />
      </Experimental_CssVarsProvider>
    </Dashboard>
  );
};