import { colors } from '../customTheme';
import ProductResearchTool from '../images/product_research/product-research.png';
import SellThroughRateFormula1 from '../images/product_research/sell-through-rate-formula1.png';
import SellThroughRateExample from '../images/product_research/sell-through-example.png';
import NikeAirJordan1Research from '../images/product_research/research-details-nike.png';
import NikeAirJordan1Stats from '../images/product_research/key-stats-nike.png';
import NikeAirJordan1SoldListings from '../images/product_research/product-research-results.png';
import TopSellers from '../images/product_research/top-sellers.png';

const productResearch = `
~~~~light,${colors.white}
# Product Research: Finding Best-Selling Products on Poshmark

<div style="text-align: center; font-family: Inter; margin-bottom: 6px">
Last updated Nov 23, 2024
</div>
<div style="text-align: center; font-family: Inter">
Written by Alice
</div>
\n&nbsp;\n

With over 11,000 brands on Poshmark, deciding what to stock can feel overwhelming.

Spoiler alert: not every brand, style, or category will deliver home runs. 

Even within popular brands, only specific items consistently perform well. And just when you think you’ve cracked the code, buyer trends change.

But here’s the good news: you don’t need to memorize every brand’s track record. In fact, don’t even try; it’s impossible. That’s where product research comes in. Product research allows you to cut through this complexity by focusing on what matters: identifying high-demand items likely to sell quickly and avoiding those that won’t. 

It helps you determine whether an item from brand or category you find while sourcing is worth adding to your inventory, so you can stock items that sell consistently and spot items you might otherwise overlook.

Proper research lets you zero in on the categories and styles that align with current buyer preferences, helping you confidently source items that will turn over fast.

~~~~light,${colors.white}
#### Chapter 1: Why is Product Research Important?

As more resellers enter the market, competition is only going to grow.

Fortunately, product research can help you identify the most profitable items, and if you do it often, you can spot trends before they become saturated, capturing the highest returns at the earliest stages of a trend.

Here are the main reasons why product research is important:

1. **Generate Higher Profit Margins**

2. **Avoiding Saturated Items or Low Demand Items**

3. **Increase Sell-Through Rate**


Imagine it this way: You’re standing in your favorite thrift store, the buzzing of the overhead lights blending with the quiet chatter of other shoppers, and the air fills your lungs with the smell of old leather. Scanning the aisles, you spot everything from vintage Levi’s to high-end brands like Coach.

Your heart races with the excitement of all the resale potential. But without market knowledge and a plan, your enthusiasm can turn into uncertainty—how can you be sure you’re making the right choice?

This is where research helps to put your mind at ease. 

Let’s say you spot a Free People top or an Anthropologie dress—both are usually popular brands that resell well. You grab them, feeling confident, and rush home to list them, thinking you’re about to make a quick sale. But after a few weeks, the items are still sitting in your inventory, with no sales in sight, not even a single like for you to send an offer.

What went wrong? 

It’s likely not the brand, but low demand for the item or market for that item is saturated. 

Without product research, you might think these brands will sell quickly, but the truth is that some items are just too oversaturated or not in demand at the moment. Product research helps you avoid this mistake. By checking metrics like sell-through rates, pricing trends, the number of available listings vs sold listings, you can make better decisions about what to source.

Every seasoned reseller knows the disappointment of watching items linger in their closet, collecting dust, but no sales. However, with thorough research, you can avoid that outcome and instead source items with a track record of success.

Research gives you clarity, ensuring that every dollar you spend—and the time you spend listing—is a smart investment, not a gamble. Additionally, research allows you to build inventory that’s stocked with items you know will sell fast, so you can make sales consistently so you’re not left hoping for a sale. 

With research you can consistently capture buyer interest and build a reputation as a go-to seller for items that resonate with buyers in the marketplace.


~~~~light,${colors.white},${colors.slatePurple}
#### Chapter 2: When should you do product research?

The short answer is: all the time.

Understand this from the start: product research isn’t just a one-and-done task that you do when you are just starting out, it’s an essential part of the sourcing process. At first it may seem burdensome, but it's fun to discover new top selling items and it eventually becomes a game to identify them.

There are different types of research you can do at different times. For example:

- **When you are just starting:** Research specific brands or categories that you already know and are interested in. This will help you familiarize yourself with the market for different brands and categories.

- **Just before a sourcing trip:** Compare the performance of different brands and categories month-to-month to see if any need to be replaced with faster moving items.

- **Just before listing** Quickly research similar items to see how they are priced so you can price your listing competitively.

- **When looking for new opportunities** you might want to research what’s selling well and what’s not. This can be done periodically, but also right before a sourcing.

As you can see, research is an ongoing task that evolves with trends, seasons, and buyer behavior. Whether you’re preparing to source inventory, listing new items, or simply staying ahead of market trends, consistent research keeps you well informed and ahead of the competition.


~~~~light,${colors.white},${colors.slatePurple}
#### Chapter 3: Key Factors to Consider in Product Research

There are a few key factors that can make or break your success. 

Each item you source should be evaluated not just for its source price or brand but also by its overall potential to generate a sale and higher profit. Here's what to consider when doing product research:

~~~~light,${colors.white},${colors.slatePurple}
##### 3.1 Demand

Without demand, even the most talked about or most unique items will struggle to sell. 

There has to be a strong buyer interest in the category, style, or brand of any item you’re considering. Here's how to gauge demand:

- **Identify the Item:** It will be hard to gauge demand if you have no idea what you're looking at, and it's just a fact that some brands perform better than others. Just take a look at our [top selling brands list](https://poshwatch.io/top-selling-brands-on-poshmark) for proof of this. So start by identifying the brand, then the style. If you’re unable to find an exact match, try searching for similar items from the same brand or comparable brands. <br>
To help with this, you can use Google Lens to do an image search and if that doesn't work, then you can post a question in different reseller groups to see if anyone can identify it.

- **Review Sold Listings:** Enter the item’s name, brand, or category in Poshmark’s search bar, then filter by “Sold” listings. The number of sold listings is the best way to gauge buyer interest. Play with filters to get a sense of what things are sold for at different price ranges.

- **Check for Seasonal Demand:** Certain items, like sweaters, jackets, and outdoor gear, surge in colder months, while swimwear and lightweight clothing perform better in spring and summer. Aligning your inventory with seasonal demand can help ensure faster sales.

- **Notice Popular Style Tags:** Tags like “boho,” “minimalist,” or “90s” often reflect current trends. If these tags frequently appear in sold listings, it may indicate active demand for similar styles.

~~~~light,${colors.white},${colors.slatePurple}
##### 3.2 Competition

Even in high-demand categories, competition influences how fast your items sell. 

Researching competition means looking at the number of similar items listed and assessing how other sellers are pricing, presenting, and promoting their inventory. Here’s how to effectively evaluate competition:

- **Review Active Listings:** Enter the item’s name, brand, or category in Poshmark’s search bar, then filter by “Active” listings. The number of similar items that are listed will help you to understand the level of competition for an item. Play with filters to get a sense of what things are sold for at different price ranges.

- **Analyze Top-Performing Listings:** Look at the first few active listings to see what sets them apart, such as high-quality photos, clear titles, descriptions, and competitive pricing. Understanding these elements can help you refine your own listings.

- **Assess Saturation:** Compare the number of active listings to sold listings. If sold listings significantly outnumber active ones, it’s a strong sign of demand even if there is a large number of active listings.

~~~~light,${colors.white},${colors.slatePurple}
##### 3.3 Profit Margins

Profit margins are crucial in reselling, as they determine how much you’ll earn from each sale. When evaluating an item, consider:

- **All Associated Costs:** Account for sourcing, Poshmark’s fees (typically 20% for sales over $15), and any additional expenses like shipping materials, cleaning, or minor repairs. <br>
Don't forget to account for your time spent sourcing, photographing, and listing the item. Many people forget to account for their time, but it's a crucial part of the cost.

- **Set Clear Profit Goals:** Aim for a profit margin of at least 55% (including all associated costs). For example, if you buy an item for $15, target a selling price of $35 to $45 to cover costs and generate meaningful profit. Always aim higher to account for low-ball offers.

Most resellers in forums seem to aim for a profit margin of at least 55% but some are able to get as high as 70% or 80%. Ensure the profit margin is high enough to make it worth your time to list the item.

~~~~light,${colors.white},${colors.slatePurple}
##### 3.4 Sell-Through Rate

The sell-through rate measures the percentage of inventory that is sold within a given time period. An essential metric for managing your inventory and cash flow. It is important to calculate your sell-through rate on a regular basis. Here are 3 reasons why:

1. To identify your most popular items.

2. Minimize inventory holding costs.

3. It's a good indicator of profitability and cash flow.

###### How to calculate your sell-through rate

To calculate your sell-through rate, you need the number of units sold during the month and the number of units you had in inventory at the end of the month.

Depending on your sales goals you can calculate your sell-through rate monthly, weekly, or daily.

*(# items sold / # of items sourced for the time period) x 100 = % Sell-Through Rate*

![sell-through rate formula](${SellThroughRateFormula1})

###### What is a good sell-through rate?

This is for you to decide.

However, we recommend that you aim for a sell-through rate of *100% in 90 days or less*.

It depends on your sales goals and the size of your storage. For example, if you have a small closet, you might aim for a sell-through rate of 100% in 90 days or less to avoid overstocking your storage, make space for new items and faster sales.

###### Sell-through rate example:

Let’s look at how you would calculate the sell-through rate on a new item to make a decision on your inventory.

Let's say for October, you sourced 100 items and by the end of the month 75 of those items sold, your sell-through rate for October would be: (75 / 100) × 100 = 75%

![sell-through rate example](${SellThroughRateExample})

###### What to do if your sell-through rate is trending lower?

If your sell-through rate is trending lower, consider decreasing your inventory levels until you move the remaining items by adjusting your price. Then adjust your strategy by sourcing more items that are moving faster.

###### Tracking sell-through rate

In the past, it would have been difficult to assess the sell-through rate for a category or item as Poshmark and other plaforms. As they didn't share this data for the marketplace as a whole, but now you can easily do so using Poshwatch’s Product Research Tool. With this tool you can:

- **Monitor Average Time to Sell:** This shows how long similar items typically take to sell, helping you gauge how fast the item is likely to move in your closet.

- **Monitor Sell-Through Rate:** You can see the sell-through rate for any item in the marketplace.

To track your own closet’s sell-through rate, many resellers use spreadsheet or inventory management tools.


~~~~light,${colors.white},${colors.slatePurple}
##### 3.5 Product Quality and Condition

Quality and condition are non-negotiable, buyers expect pre-loved items to be in good shape. To ensure you're sourcing items with lasting appeal, it’s important to know [how to identify quality clothing](https://poshwatch.io/guides/reseller-guide-to-identifying-high-quality-clothing).

Do this when sourcing:

- **Inspect Thoroughly:** Check each item for signs of wear, damage, or flaws that could lower its resale value. Focus on common high-wear areas like hems, zippers, and fabric. If an item does have flaws but you can confirm that it's high-value, it’s still okay to list, but make sure to mention the flaws in the description and include photos showing them. Not disclosing damage could increase the likelihood of a complaint or return, which you want to avoid.

- **Prioritize Quality Brands and Durable Materials:** Higher-quality items are more likely to appeal to buyers and can often be sold at higher prices. For instance, shoes from brands like Nike, Birkenstock, and Ugg are consistently in demand due to their quality and durability. In the bags category, luxury brands such as Louis Vuitton, Coach, and Chanel remain top sellers, often commanding higher resale prices. Dresses from brands like Reformation and Free People maintain their appeal for their stylish designs and fabric longevity. 

- **Showcase Quality in Listings:** Use clear, well-lit photos and detailed descriptions to highlight the condition. Mention if an item is in “like new” or “excellent” condition to build trust with buyers. If the item is NWT (New With Tag) or NEW (New Without Tag), be sure to add this to your title as condition is a major selling point. Buyers often search for brand-new or never-worn items, and including this in your listing title helps attract more attention and sets your item apart in a crowded marketplace.

~~~~light,${colors.white},${colors.slatePurple}
#### Chapter 4: Step-by-Step Product Research Process

Now that we’ve covered the key factors in product research, let's look at how to apply these insights practically. 

~~~~light,${colors.white},${colors.slatePurple}
##### Step 1: Choose a focus category or niche

Start by choosing a few specific categories or niches to focus on. 

This approach makes sourcing less overwhelming, allowing you to develop an eye for pieces within any given niche. Whether it’s timeless vintage, trendy athleisure, or versatile accessories, having a clear focus lets you create a closet that attracts buyers looking for those specific styles. 

It also makes sourcing easier, as you're focused on gathering items and doing research for a particular category.

Choose categories based on:

- **Your Interests:** What brands or categories are you interested in learning about?

- **Your Sources:** What can you find at your local thrift stores?

- **Market Trends:** What’s selling well?

- **Your Goals:** How much profit do you want to make?

- **Your Storage:** How much space do you have?

- **Your Capital:** How much do you have to invest to get started?

~~~~light,${colors.white},${colors.slatePurple}
##### Step 2: Conduct Historical Research

Once you’ve chosen a category, look into how similar items have sold in the past. 

Historical research helps you avoid trial and error as it can show you which brands, sizes, colors, and styles have performed well. Poshmark’s “Sold” listings are a great resource to start with for spotting patterns. You can also use tools like the Poshwatch Product Research Tool to get a quick overview of what’s working—more on that later.

Here’s what to do:

- **Leverage research tools:** Look for tools that provide at least 3-6 months of historical sales data to identify consistent trends.

- **Focus on key metrics:** Pay attention to subcategories, styles, sizes, colors, and their average selling prices. These details will help you spot high-demand items.

~~~~light,${colors.white},${colors.slatePurple}
##### Step 3: Research Demand (Forecasting)

After getting a sense of past performance, research current and upcoming trends. 

This step helps you understand what buyers are actively looking for now or are about to start searching for. Platforms like Google Trends, Poshmark’s "Today’s Trends" page, Instagram, and TikTok can highlight what’s gaining traction, while sites like Revolve and Shopbop are excellent for spotting early trends before they hit the mainstream. 

Combining these insights ensures you’re sourcing items with proven success while staying ahead of emerging buyer preferences.

~~~~light,${colors.white},${colors.slatePurple}
##### Step 4: Competitive Analysis

Next, check out the competition for your chosen niche.

Try to sus out their strategy. Are they stocking a lot of a particular brand or style? Look at their sold listings to see how much inventory they are moving. What could they do better and what could you do to set yourself apart?

Use our top lists for inspiration:

[top sellers on Poshmark](https://poshwatch.io/top-sellers-on-poshmark) to learn from the best resellers on Poshmark. <br>
[top categories on Poshmark](https://poshwatch.io/top-categories-on-poshmark) for the best sellers and brands in a category. <br>
[top selling brands on Poshmark](https://poshwatch.io/top-selling-brands-on-poshmark) for the best sellers and categories for any brand.

![top sellers on Poshmark](${TopSellers})

Here’s what to do:

- **Assess different closets:** Compare the number of active listings to sold listings and use our sellers detail page like this one for [elady](https://poshwatch.io/seller/elady) to take note of what they stock.

- **Assess their listings:** Examine how they are photographing, describing, and presenting their items. Look for opportunities to improve or differentiate.

The goal is to choose items with high-demand and moderate or low competition keeps your sell-through rate and profits high.

~~~~light,${colors.white},${colors.slatePurple}
##### Step 5: Calculate Profit Margins

Finally, evaluate the profit potential of each item. 

Subtract the cost of sourcing, Poshmark fees, and any additional expenses from your expected selling price to calculate your profit. Look for items that will at least double or triple your initial investment, ensuring each sale is worth your effort. By focusing on items with healthy profit margins, you’re building a closet that consistently supports your business goals.

Use [Poshwatch’s fee calculator](https://poshwatch.io/poshmark-fee-calculator) to simplify profit calculations. Just input your item’s sales and cost price, and the calculator will instantly display Poshmark’s fee and your net earnings.

~~~~light,${colors.white},${colors.slatePurple}
#### Chapter 5: Poshwatch’s Product Research Tool

[Poshwatch’s Product Research Tool](https://poshwatch.io/product-research) takes the guesswork out of sourcing.

Instead of spending hours manually tracking trends or sales data, you get a clear, easy-to-understand overview of an item or category just by entering the name in the search.

In a nutshell, it helps resellers find the most profitable and fast-moving items on Poshmark by offering detailed information on each item’s resale performance. Key metrics including, sell-through rate, competitiveness, and average time to sell, pricing data and more. Helping you identify items with strong resale potential and avoid those that may struggle to sell.

![product research tool](${ProductResearchTool})

~~~~light,${colors.white},${colors.slatePurple}
##### 5.1 How to Find Profitable Products

Using the Poshwatch Product Research Tool is straightforward. Here’s a quick guide to get you started:

1. **Search for a Specific Item or Brand Category:** Begin by entering the name of the item or category you’re interested in, such as “Vintage watch”. The tool will generate data such as average time to sell, typical price ranges, and recent sales, giving you a clear picture of the item’s performance.

2. **Review Key Metrics:** Check important metrics like:

- Average Time to Sell: This shows how long similar items typically take to sell, helping you estimate how fast the item will likely move in your closet.

- Price Range and Median Price: Knowing the average and median prices lets you set competitive but profitable price points.

- Profit Margin Estimates: By combining the pricing data with your estimated sourcing costs, you can calculate potential profits and determine whether an item fits your business goals.

3. **Identify Key Trends:** The tool also highlights trending colors, sizes, and style tags based on marketplace activity. This data allows you to source items that align with current buyer preferences and stand out in the market.

4. **Evaluate Recent Sales Data:** Recently sold listings provides a glimpse of what similar items sold for, helping you set realistic expectations for your own listings.

The tool is a powerful resource for keeping your closet stocked with items buyers are looking for—helping you maximize efficiency and profitability.

~~~~light,${colors.white},${colors.slatePurple}
#### Chapter 6: Product Research in Action

Let's walk through an example.

To truly understand the power of this tool and product research in general, we will demonstrate how to evaluate an item’s sales performance, estimate profits, and optimize your listings.

##### Example: Researching Nike Air Jordan 1

###### 1. Search for the Item

Enter *"Nike Air Jordan 1"* into the Product Research search bar. The tool will then show a detailed breakdown of the item’s performance with the following key metrics:

- **Average Price:** $65.8
- **Average Time to Sell:** 46 days
- **Sell-Through Rate:** 20% in 60 days

![nike air jordan 1 research](${NikeAirJordan1Research})

These numbers provide a realistic expectation of what buyers are willing to pay and how long it may take to sell the item. It will also show you the estimated market saturation.

###### 2. Analyze Key Metrics

The tool also highlights trends and characteristics that influence the item's demand:

- **Popular Sizes:** Sizes 6, 8, and 8.5 dominate the market, with size 6 showing same-day sales in some cases.
- **Price Trends:** High-end sales, such as $100 for retro or exclusive styles, suggest an opportunity to price premium items competitively. Mid-range sales ($60–$80) reflect consistent demand for popular styles and colors.

![nike air jordan key stats](${NikeAirJordan1Stats})

###### 3. Review Recent Sales Data

Looking at the 49 recently sold listings, you can spot interesting patterns:

- **Higher-end sales (e.g., $99)** often feature items in excellent condition or include trending colorways like "Court Purple" or "Retro High OG."

- **Lower-end sales ($50–$55)** are typically for less popular styles or pre-owned items with minor flaws.

This helps you set competitive yet profitable pricing for your listing.

![nike air jordan 1 sold listings](${NikeAirJordan1SoldListings})

###### 4. Estimate Profit Margins

Using the tool’s profit calculator, input the sourcing cost (e.g., $20.57 for Nike Air Jordan 1). The tool allows you to calculate the kind of profit you can expect based on your sourcing cost

- **Estimated Profit:** $48
- **Profit Margin:** 70%

This shows the potential for a solid return on investment, making the item a viable choice for your closet.

###### 5. Use Trends to Improve Listings

The tool also suggests using relevant style tags like “Streetwear,” “Retro,” or “Casual,” which are currently trending. Including these tags in your title and description can increase the item’s visibility in search results.

**Example listing title:** “Nike Air Jordan 1 Retro High OG Size 8.5 black and white”

**Example description:**  "Nike Air Jordan 1 sneakers in size 8.5. Retro High OG design with a ‘Court Purple’ colorway in excellent condition.”

With these insights, you’ll be one step closer to turning your closet into a hot spot for top-performing items.

~~~~light,${colors.white},${colors.slatePurple}
#### Chapter 7: Interpreting Data for Better Product Decisions

Once you’ve gathered product research data, the next step is making sense of it all. 

Interpreting this information effectively helps you make smarter sourcing decisions, refine your product offerings, and align your closet with buyers' needs.

~~~~light,${colors.white},${colors.slatePurple}
##### 7.1 Turning Data into Actionable Insights

Data tells the story of what makes an item a good investment.

Rather than relying on general trends or personal preferences, analyzing specific figures like average sale prices, median prices, and time to sell provides you with clarity and confidence.

For instance, if the data for an *Oasis Midi Dress* reveals an average sale price of $60.74 and a median price of $60, you know the pricing sweet spot. Coupled with the fact that the average time to sell is 44 days, you can decide if it fits your goals for inventory turnover.

Add the profit margin estimate of $42.52 (based on sourcing at $18.22 for a 70% margin), and you have a full picture of whether it’s a worthwhile investment.

This kind of analysis ensures you’re not taking unnecessary risks and that your sourcing decisions are rooted in profitability.

~~~~light,${colors.white},${colors.slatePurple}
##### 7.2 Key Metrics to Focus On

When interpreting data, these key metrics provide the most insight into an item’s potential:

1. **Sell-Through Rate**

The sell-through rate measures how quickly items in a category sell after being listed. A higher rate means faster-moving inventory, which keeps your closet active and profitable.

To calculate sell-through rate:

-  Compare the number of active listings to sold listings on Poshmark. If sold listings significantly outnumber active ones, it’s a strong sign of demand.

2. **Pricing Trends**

Understanding average and median prices helps you set competitive, realistic price points. Look for:

- **Consistency:** If prices for an item are relatively stable within a certain range (e.g., $45–$81 for the Oasis Midi Dress), you can confidently price your listing without undervaluing it.

- **Outliers:** Extremely high or low prices in sold listings might signal unique factors (e.g., rare sizes or NWT items). Use the typical range to guide your pricing for pre-loved pieces.


3. **Buyer Preferences**

Trends in colors, sizes, and style tags are critical for aligning your inventory with buyer demand. For example:

- If data shows that blue and black are the most popular colors for a dress, prioritize sourcing those over less popular colors like brown.

- Data often shows that certain sizes perform better. Like size M and L in jeans.

- Style tags like Bohemian or Festival often signal trends buyers are actively searching for, so tagging your listings accordingly can increase visibility.

~~~~light,${colors.white},${colors.slatePurple}
##### 7.3 Refining Your Product Offerings Over Time

The goal of interpreting data isn’t just to make individual sourcing decisions but to continuously refine your overall strategy. Here’s how:

- **Track What’s Working:** For example, if maxi dresses from certain brands sell faster and at higher prices, focus more on sourcing those. <br> 
Resellers track what’s working by using spreadsheets to log details like sourcing costs, selling prices, and profits, helping identify top-performing items over time. Analytics tools also provide valuable insights, automating much of the analysis. Additionally, customer feedback, such as likes, favorites, and comments, offers real-time clues about buyer preferences and demand.

- **Eliminate Underperformers:** Identify items or categories that consistently take too long to sell or bring low margins. Shift your resources to more profitable inventory.

- **Spot Emerging Trends:** Review data regularly to identify shifts in buyer preferences. Pair these insights with your own sales data to ensure your closet stays relevant.

Over time, this iterative approach will help you curate a closet that aligns with buyer demand while maximizing profitability.

~~~~light,${colors.white},${colors.slatePurple}
##### 7.4 Using Data to Stay Competitive

As trends shift and buyer behavior evolves, the ability to analyze and adapt your strategy becomes your greatest advantage. Here are my top three tips to stay competitive:

- **Spot New Opportunities:** Consistently reviewing metrics like sell-through rates and pricing trends can reveal emerging categories or styles that match buyer demand. For example, if you notice maxi dresses gaining traction while another category slows down, you can adjust your sourcing to capitalize on that trend.

- **Adjust Quickly:**  Data enables you to pivot in real-time. If an item you listed isn’t performing as expected, reviewing average prices or buyer preferences can help you identify whether a price adjustment, refreshed description, or better tagging might increase visibility and appeal.

- **Stay Proactive, Not Reactive:** Don’t wait for a drastic change to make certain decisions; always stay informed to anticipate what buyers want next, rather than reacting to what’s already oversaturated.


~~~~light,${colors.white},${colors.slatePurple}
#### Chapter 8: Adapting to Market Changes

The resale market is dynamic, with trends shifting and buyer preferences evolving. Adapting to these changes is essential for keeping your Poshmark store profitable and ensuring your inventory stays relevant.

~~~~light,${colors.white},${colors.slatePurple}
##### 8.1 Adapting Your Research Strategies Over Time

Styles, colors, and even certain brands go in and out of demand. It’s essential to regularly update your research strategies to stay in tune with these shifts.

This might mean revisiting trending pages on Poshmark, following seasonal trends, and using tools to see which categories are gaining popularity. By continuously refreshing your approach, you can avoid stale inventory and instead stock items that buyers are currently looking for.

~~~~light,${colors.white},${colors.slatePurple}
##### 8.2 Using Sales Data and Buyer Feedback

Sales data and buyer feedback are invaluable for refining your future sourcing. Buyer feedback can be especially useful on a category or brand level. While thrifting, you’re limited to what’s available, so even if a particular item sells fast, you might not find it again anytime soon.

It’s tempting to chase after these “gold” items with high resale value, but they’re often rare finds. Instead, focus on categories or styles you can source consistently and maintain buyer interest without flooding the market.

Analyzing your own sales data can also reveal useful insights about what’s performing well. For example, if you notice that certain categories, colors, or sizes sell quickly, prioritize these types of items in your future sourcing. By aligning with data and feedback, you can build a closet that meets demand sustainably.

~~~~light,${colors.white},${colors.slatePurple}
##### 8.3 Stay Ahead of Trends

Keeping an eye on emerging trends through Poshmark insights, social media, and reseller communities helps you spot what’s coming next. Being an early adopter of new trends can help you capture more sales before the competition catches on.

Ultimately, adapting to market changes is about balancing your strategy between the items that sell quickly and those that are consistently available. By staying flexible and responsive to both data and buyer preferences, you’re more likely to maintain a profitable and reliable reselling business on Poshmark.


~~~~light,${colors.white},${colors.slatePurple}
#### Chapter 9: Avoiding Common Product Research Mistakes

Even with the best intentions, mistakes during product research can easily lead to unsold inventory, lower profits, and wasted effort. Here are some common pitfalls to watch out for—and how to avoid them:

~~~~light,${colors.white},${colors.slatePurple}
##### 9.1 Overemphasizing Personal Preferences

We are all guilty of this, at one point or another.

You walk by a rack and a cute Reformation dress catches your eye; the source price seems like a steal, and it’s Reformation, of course it’s going to sell (you think to yourself). You swipe it in a heartbeat. It turns out no one else felt the same way, it has been over 6 months and now it’s staring at you every time you go into your inventory.

It’s natural to lean toward items you personally love, but reselling is about understanding and catering to your buyers’ tastes, not your own. Just because a particular style or brand resonates with you doesn’t mean it will have high demand. Let data and research guide your choices, ensuring you’re stocking items that align with buyer interests. 

The more you step into your buyers' shoes and familiarize yourself with the market, the more effectively you’ll curate a closet they’re drawn to.

~~~~light,${colors.white},${colors.slatePurple}
##### 9.2 Overlooking Hidden Costs

If you are not careful, hidden costs can quietly eat into your profits.

Expenses like travel, cleaning, minor repairs, shipping materials, and platform fees can add up quickly, reducing your overall margin. However, one often overlooked factor is your time. Every step of the reselling process—sourcing, listing, shipping, and customer communication—takes time, and that time has value.

[Tracking the time](https://poshwatch.io/guides/where-to-source-poshmark-inventory#3-4---time-management-and-efficiency:~:text=process%20more%20items.-,3.4%20%2D%20Time%20Management%20and%20Efficiency,-Track%20Your%20Time) it takes to prepare and sell an item can help you determine whether it’s truly worth the effort.

~~~~light,${colors.white},${colors.slatePurple}
##### 9.3 Ignoring Competition or Market Saturation

Ignoring the levels of competition for an item can lead to slower sales or even pressure to lower your prices. 

This can also fill up your storage area to the brim with items that don’t move—an all too familiar issue for many resellers who often run out of space and feel overwhelmed by stagnant inventory. Always check how many similar listings exist for the items you’re considering. Choosing items with high-demand but moderate-competition can make the difference between a quick sale and a long wait.

~~~~light,${colors.white},${colors.slatePurple}
##### 9.4 Skipping the Research Process

Yes, we are talking about research, but I had to add this since one of the biggest mistakes is skipping research altogether.

Believe me, I’ve been there. It's tempting. 

I've picked up many items in the thrill of the moment that I thought would sell based on what I thought was popular. Only to end up donating it because it didn't sell or accepting a low offer just because I was in a hurry to move it out of my inventory.

Relying on intuition alone or just guessing what buyers want is bad practice and often results in picking items that don’t meet buyer demand. Thorough research is tedious, yes, but it’s the backbone of successful sourcing. Invest the time upfront, and it will pay off with increased sales and a more profitable closet.

My favorite saying is: *"Profits are made at the time of purchase"*

This means that the profit you make on an item is determined at the time of purchase, when you find a deal. The only way to know if something is a deal or a hidden gem is to do the research.

If you find yourself procrastinating on research, find ways to make research fun, and you’ll be surprised how quickly it becomes a habit. Start with brands that you know and love. Pick one of their categories, and dive in. Then, use tools that make it easier, and soon you’ll be amazed at how much you’ve learned.

~~~~light,${colors.white},${colors.slatePurple}
##### 9.5 Not Utilizing Poshmark’s Built-In Tools

Another mistake is not using resources within the Poshmark app to support your research.

[Today’s Trends](https://poshmark.com/trends) can give you insights into popular items and brands buyers are actively searching for, helping you target what’s in demand.

~~~~light,${colors.white}
#### Conclusion

Phew, that was a mouthful! (Well, an eyeful for you). If you made it to the end, you’re the real MVP.

Now, let’s wrap things up.

Mastering product research isn’t just a skill—it’s the foundation of building a thriving reselling business on Poshmark. With thousands of brands, categories, and styles to navigate, using tools and strategies that provide clarity and actionable insights is what separates successful sellers from the rest.

Through thoughtful and methodical research, you can go beyond guesswork and ensure every item in your closet has strong resale potential. Each step in the process builds toward a smarter, more efficient sourcing strategy.

So, take the tools, techniques, and examples shared in this guide and start building the reselling business you’ve always envisioned.

Every sale starts with a smart sourcing decision—and by doing proper product research with the help of Poshwatch’s product research tool, you’re poised to turn every thrifting trip into a success story.`;

export default productResearch;