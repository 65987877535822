import { colors } from '../customTheme';
import GoogleSearchResultsImage from '../images/how_to_list/google-search-results.jpg';
import TitlesImage from '../images/how_to_list/titles.jpg';
import MaterialsImage from '../images/how_to_list/materials.jpg';
import WayBackMachineImage1 from '../images/how_to_list/wayback-machine-1.jpg';
import WayBackMachineImage2 from '../images/how_to_list/wayback-machine-2.jpg';
import WayBackMachineImage3 from '../images/how_to_list/wayback-machine-3.jpg';
import WayBackMachineImage4 from '../images/how_to_list/wayback-machine-4.jpg';
import ListingAnalyzer from '../images/how_to_list/analyzer.png';
import PhotosImage1 from '../images/how_to_list/photos-1.jpg';
import PhotosImage2 from '../images/how_to_list/photos-2.jpg';
import PhotosImage3 from '../images/how_to_list/photos-3.jpg';
import PhotosImage4 from '../images/how_to_list/photos-4.jpg';
import PhotosImage5 from '../images/how_to_list/photos-5.jpg';
import PhotosImage6 from '../images/how_to_list/photos-6.jpg';
import CategoryImage from '../images/how_to_list/category.jpg';
import SizesImage from '../images/how_to_list/sizes.jpg';
import PhotoTagsImage from '../images/how_to_list/photo-tags.jpg';
import SelectBrandImage from '../images/how_to_list/select-brand.png';
import ColorsImage from '../images/how_to_list/colors.jpg';
import PricesImage from '../images/how_to_list/prices.jpg';
import SilkDressImage from '../images/how_to_list/silk-dress.jpg';

const howToList = `
~~~~light,${colors.white}
# How to Create Poshmark Listings that Sell

<div style="text-align: center; font-family: Inter; margin-bottom: 6px">
Last updated Mar. 23, 2024
</div>
<div style="text-align: center; font-family: Inter">
Written by Alice
</div>
\n&nbsp;\n

#### Introduction

Ok, let's talk listings.

As a reseller, when you are just starting out, it's easy to get excited about sourcing fabulous items. But what's the use if you have not even the slightest clue how to get traffic and capture the attention of motivated buyers? In my opinion, you should forget sourcing initially and start with items from your closet. Then, focus on mastering how to make your items shine brighter than the rest with an effective SEO strategy.

Now, to stay ahead of your competition, your listing needs to catch the eyes of both Poshmark and Google shoppers. But why Google? Even though you are listing on Poshmark, Google routinely crawls their listings, integrating them into their own search results. Optimizing for both increases visibility and your reach to potential buyers searching across the web, allowing you to compete with listings on other platforms by appearing in Google's results.

![Google SEO search results](${GoogleSearchResultsImage})

So, let's get your listings glammed up for the spotlight—they deserve nothing less!

First, a quick lesson on SEO.

~~~~light,${colors.white}
#### Chapter 1 - The Basics of SEO for Poshmark

SEO or Search Engine Optimization is the optimization of written and visual content on a webpage so it can be indexed by a search engine and easily found by users searching for keywords. The more relevant your content is to user search queries, the higher your content will rank in search results. The goal is always to appear as close to the top as possible.

Imagine your closet as a physical shop in a busy mall(Poshmark) and this mall is in a busy part of town(Google), where there is a ton of competition for attention. SEO is the equivalent to an eyecatching and relevant sign that would give you the best chance of getting noticed first. To tie this back to Poshmark listings, your task is to create a listing that proves to Google or any other search engine that your content is the most relevant to a particular keyword or search term.

~~~~light,${colors.white}
##### 1.1 - Understanding the Poshmark Algorithm

The more relevant your listing, the more likely search engines are to show your listing at the top of search results.

So, when someone searches using a keyword for something they need, SEO helps connect them to your listing if it's the closest match.

The endgame is to capture their gaze, sparking enough interest to click on your listing and, ultimately, making a sale. In SEO terms, capturing gaze (that's "impressions"), sparking interest ("click-through rate") and making a sale? Well, that's just "conversion".

**Impressions:** As discussed earlier, to get your listings noticed, they need to pop up early in search results, especially since Poshmark sorts by "Just Shared" by default. The trick? Regularly share your own listings to ensure your listings regularly get bumped to that prime top spot.

When it comes to crafting your listings, it's all in the details: *Titles* and *descriptions* should be super specific, packed with keywords buyers are actually typing into the search bar. Think condition, size, color, brand, style, and sometimes material.

**Click-through rate:** The power of a first impression can't be overstated – an attractive photo is your first, best chance to catch a buyer's eye. Make it count.

**Sales/Conversion:** Closing the deal comes down to building trust. Your listing should overflow with details that affirm the item’s quality, authenticity, and value for money. Remove any friction or obstacles that might make a buyer hesitate. Missing info? They might just move on to your competitor's listing. Price your items competitively – align with the market to ensure your offer is irresistible.


**Quick SEO Tips:**

1. **Put yourself in the shoes of the buyer**\n&nbsp;\n
    Ask yourself, If I had a specific Item I was looking to buy, what words would I use in my search for this item I am about to list. Be sure to include abbreviations and slang words for example, for Dr. Martens include docs.

2. **Be mindful of Length**\n&nbsp;\n
    You want to utilize as many characters as possible to describe the item. However if they are too long they will be cut off. 
    For Poshmark, the optimal length for a title is 22 characters and for google its between 40 - 80 characters.

3. **Be specific**\n&nbsp;\n
    Use the exact title of the item from the retailer and stick to how people type. So no need to add accents as most folks are typing quickly and dont include those in their search.

4. **Avoid including irrelevant info**\n&nbsp;\n
    Dont ramble, include Ascii characters or emojis this is not helpful to your buyer.

~~~~light,${colors.white}
#### Chapter 2 - Anatomy of a Poshmark Listing

​​With the basics of SEO complete, here’s where the fun starts. Your Poshmark closet is your personal lab, and view each listing as an experiment. The strategies I've shared are your starting point, but the real learning happens when you start tweaking. Try different keywords and play with pricing — see what gets more eyes on your items and what doesn’t. Remember, what works wonders for one seller’s closet might need a slight adjustment to sparkle in yours. So, don’t be afraid to experiment and find your own secret sauce. Keeping an eye on how these changes affect your views and sales can give you real insights into what your buyers love.

Here are our touch points: titles, descriptions, prices, photos, dropdowns, and tags

We will cover these in detail, diving deeper into each to equip you with the strategies needed to elevate your Poshmark listings and captivate buyers.

~~~~light,${colors.white}
##### 2.1 - How to Write the Perfect Title

They are one of the most critical factors for Poshmark SEO, specifically the first 22 characters of the title. This tiny slice of the title is visible on the site's mobile version, while the desktop version of the search results displays all 50 characters. Therefore, the title's most critical information must fit within the first 22 characters, as this is where your item makes its first impression.

![Example of listing titles on Poshmark](${TitlesImage})

Here are some important keywords to include when writing your title:
1. Condition - New or NWT (New With Tag)
2. Brand
3. Material (when necessary)
4. Style Name
5. Color
6. Keywords
7. Size

Here are some examples using this format:

> *NWT* Babaton Contour Squareneck Shortsleeve Bodysuit White Size Large

> *NEW* Babaton Contour Squareneck Sleeveless Bodysuit Snake print Size Medium

We start with conditions, as buyers want to know they are getting the best quality, impacting whether or not they purchase. 

On mobile, the brand name tag needs to be visible, and most buyers are shopping for brands they trust since they cannot touch or try on the item. Therefore, having the brand in the title makes it easier to get their attention.

Style names will attract shoppers who are searching for specific items. These shoppers are the most motivated. They have been combing through Google, Poshmark and potentially other sites, and as they spot the item they've been hunting for, there's hardly any need for convincing or extra selling; it just needs to catch their attention, and it will sell itself.

Be sure to note non-standard colors like snake print that can't be translated into the standard colors available on Poshmark. For example, fuchia can be translated to pink. It is still worth putting this color in the title and description.

It's best to include size, as only brand and color filters are not accounted for in the search, not size. So, if a buyer searches for a small item and your title or description fails to add that, your listing will be left out of the results.

Material is optional in most cases. However, there are some scenarios where adding the material makes sense. Suppose a brand makes a 100% silk item and a similar satin (polyester) item. In that case, you will want to include the material within the first 22 characters to be visible when searching on mobile. Materials you should consider, including if they are genuine, are Silk, Leather, Suede and Wool.

![Poshmark titles with materials description](${MaterialsImage})

~~~~light,${colors.white}
##### 2.2 - Understanding the Description

The description’s goal is to assist with listing exposure and to provide accurate and relevant information needed to make a quick purchase by providing additional keywords. At the same time, minimizing the potential for returns or customer service issues.

The best strategy is to find the original listing from the brand and copy their listing. Here's a clever little tip to make your life easier - take a peek at the brand’s own listing for that item. They’ve done the Google SEO keyword research and figured it out, so why not take advantage of their efforts for your listing?


Now, I know finding old brand listings might seem like a bit of a scavenger hunt, but don't worry, I've got you covered with some simple steps to guide you through:

1. Find the style number on the garment tag
2. Search the style number on the brand’s website, Google or Pinterest

If you cannot find the style this way, you may find it on the [Wayback Machine Internet archive](https://archive.org/web/).

You will need to know the year the style was available.

1. Paste the brand’s site URL in the search bar. Let's use the brand Lane Bryant for our example. ![WayBackMachine Step 1](${WayBackMachineImage1})
2. Once the page loads, select year ![WayBackMachine Step 2](${WayBackMachineImage2})
3. Then, select the snapshot ![WayBackMachine Step 3](${WayBackMachineImage3})

This will load the version of the site that existed on that day, allowing you to browse the site and find the item. This is time-consuming but can be helpful in excavating old listings. ![WayBackMachine Step 4](${WayBackMachineImage4})

Ok, with the old brand listing in hand, here are some important keywords to include when writing your description:
- Material content
- Length 
- Rise
- Silhouette
- Sleeve cut
- Neckline 
- Hem
- Hardware Detailing
- Care Instructions
- Measurements 
- Condition details (good vs flawed)

When crafting your description, make it a priority to weave in category-specific keywords that clearly define the item's intended use or occasion, such as *Business*, *Evening*, *Cruise*, *Cocktail*, *Casual*, *Formal*, *Outdoor*, *Vacation*, *Wedding*, *Athletic*, etc. These words act as beacons, drawing in potential buyers searching for specific needs. And remember, avoid using adjectives like "cute" and "beautiful" in both titles and descriptions; they're just filler words and are not as effective in steering search traffic toward your product.

~~~~light,${colors.white}
To put this into practice, here’s a template for a listing that utilizes strategic keywords effectively:

> NEW Babaton Contour Sleeveless Bodysuit White
> 
> Size M/ Medium Fits true to size.
> 
> Never worn, Excellent pre-owned condition. No flaws
> 
> - Style#: NSP1-1472-20
> - Article#: 69983
> - Machine wash cold
> - Bodysuit
> - Sleeveless
> - Square neckline
> - Thong-cut bottom
> - Material Body 94% Nylon, 6% Elastane

This example demonstrates how to write a clear, informative, and search-optimized listing description that will likely to attract motivated buyers. Feel free to copy this template for your own use.

~~~~light,${colors.white}
##### 2.3 - Why Good Photos are Important

Photos are the star of the show, the main attention snatcher of the listing. Without an attractive cover photo, it will be more challenging to get clicks on your listing. It’s that simple. 

Poshmark allows you you add up to 16 photos, however, the optimal number of photos is 4-5, depending on the condition. Overloading your listing with too many photos can detract from the mobile shopping experience. Since photos appear first on the mobile version of listings, and an excessive number of photos can lead to a lengthy scroll before shoppers reach the title and description. 

This is a user experience design flaw of Poshmark’s platform that we hope they improve in the future. For now, be mindful of this and only add the optimal number of photos.

What photos do you need for your listing?

**For clothing include:**

1. Front
2. Back
3. Brand Tag
4. Retail tag (if NWT)
5. Flaws (If any)

![Photos Example 1](${PhotosImage1})

**For shoes include:**

1. Front
2. Back
3. One on each side
4. Outer sole
5. Flaws (If any)

![Photos Example 2](${PhotosImage2})

~~~~light,${colors.white}
What are the characteristics of a good cover photo?

1. **Light-colored background** - This is preferred as is easier on the eyes and appears more professional, and Google prefers a light-colored background over busy stylized backgrounds. So, to ensure your image is crawled and integrated into Google search results, use light backgrounds. ![Photos Example 3](${PhotosImage3})

2. **Lighting** - Good lighting helps to portray the item accurately so the seller can clearly see the item’s color, texture and condition. This helps build buyer confidence and reduces the likelihood of returns or dissatisfaction. ![Photos Example 4](${PhotosImage4})

3. **Display the entire item** - Include the entire item and ensure no part of the clothing or item is cut off. This builds trust by showing there's nothing to hide, and it also helps buyers make informed decisions as they can see all aspects and details of the item. Accurate and comprehensive visuals are crucial to attracting and reassuring customers, significantly enhancing the likelihood of a sale. ![Photos Example 5](${PhotosImage5})

4. **Show fit** - A modelled photo is the best way to show how the garment will fit, and this will help the buyer know what to expect and reduce returns. You have three options for modelled photos: Brand stock photos, Mannequins or modelling yourself. Stock photos are usually good quality but are considered copyright infringement as you use the retailer’s branding for your commercial use. Mannequins and modelling yourself meet all the photo requirements but are not the most time-efficient, so use those options sparingly. ![Photos Example 6](${PhotosImage6})

~~~~light,${colors.white}
##### 2.3.1 - Tools for Top-Notch Photos

**Camera:** Think you need a high-end camera to take great photos? Not really. A modern iPhone or any recent smartphone will do. These devices are capable of capturing clear, detailed images that are perfect for your listings.

**Lighting:** Good lighting is key to showcasing the quality of your items. If your photo spot lacks natural sunlight or you find yourself snapping pictures during the darker hours, consider investing in a lighting kit. Unlike the unflattering shadows and yellow tones from overhead lighting, box lights offer a soft, even light that’s affordable. They help accurately represent your item's true colors and details.

A quick word on efficiency. While it's tempting to aim for perfection with countless shots and edits, time is of the essence, especially when you have multiple listings to process. A few well-taken photos are all you need. Focus on quality over quantity to streamline your workflow and keep your posting schedule efficient.

~~~~light,${colors.white}
##### 2.4 - Other Important Details

**Categories:** Including an accurate category and subcategory for items on Poshmark is crucial for enhancing searchability and ensuring your items appear in relevant searches and sale potential. It improves the buyer experience by making it easier for buyers to find what they want. Accurate categorization also aids in Poshmark's recommendation algorithms, increasing the chances of your item being suggested to interested buyers.

![Poshmark Categories](${CategoryImage})

**Size:** Poshmark supports standard US, AU, EU, and UK sizes in addition to Plus, Petit, Junior, and Maternity. Anything outside these ranges should be converted to a size that Poshmark supports, then make note of the original size in the listing’s description. 

Avoid custom sizing to ensure you are always included in the search. If the buyer uses the sizing filter, having a custom size prevents your listing from appearing in the search results. If the item is tall and Poshmark does not include that, just convert it to a regular size and include this in the description. 

Also, If you have the same item in different sizes, creating multiple listings rather than various sizes is best because the specificity improves the clickthrough rate. If a competitor has the exact size posted, why would they waste time clicking through to your listing only to find out your various sizes don't include the one they want?

![Poshmark Sizes](${SizesImage})

**Tags:** To maintain the perception of value, remove all clearance tags and tags from clearance outlets and discounted sellers like Nordstrom Rack, Winners, Marshalls, TJ Maxx, Ross, and Steinmart.

![Photo Tags](${PhotoTagsImage})

**Brand:** Use the brand name as it appears on Poshmark so that you align with Poshmark’s filters and appear in the search results. When selecting a brand, start typing the name and select the name from what appears in the drop-down results.

![Selecting the brand](${SelectBrandImage})

**Color:** This is straightforward. Try not to select more than two so you don't confuse the buyer.

![Poshmark Colors](${ColorsImage})

~~~~light,${colors.white}
#### Chapter 3 - Understanding Pricing

When deciding on pricing, we must take the psychology of pricing into consideration. Pricing is more than just the cost of an item and its unit economics; it can also send a message about quality. It can be used as an effective marketing and branding strategy and significantly impact buyers' purchasing decisions. 

Your goal is to meet your buyer’s psychological needs, either saving money by getting a good deal or investing in beautiful items of the highest quality. Ultimately, they are motivated by getting the best of something, whether a deal or quality.

However, at the end of the day, pricing must be competitive. If pricing is too high, they will lose interest and look elsewhere. If they are higher, you will tarnish the perception of value and quality for your listing, and buyers may assume it is defective.

~~~~light,${colors.white}
##### 3.1 - Pricing Strategies

There are many pricing strategies, but for Poshmark, there are two main strategies – Deal Pricing and Exclusive or Prestige pricing.

**Deal Pricing**

This communicates to your buyer that you are offering a deal and that it is best suited for a Low-Cost Goods model or when selling non-luxury products. Typically, it ends in a nine but can also end in a 7 or 8. E.g. 19.99, 19.98, 19.97. You can also consider the "Charm Pricing" technique. This involves setting prices slightly lower than a round number, e.g., 49.99 instead of 50. It's based on the perception that slightly lower prices seem much cheaper, thus appealing to bargain hunters

**Exclusive Pricing**

This communicates to your buyer that you are offering a higher quality product, and this strategy works well for high-end or luxury goods where the price signifies superior quality or status. In the case of these business models, you want your price to end with a 0 or 5. E.g. $50 or 75. to convey quality and exclusivity.

On Poshmark, it's advantageous to price items at 25, 50, 100, 250, or 500, as these amounts align with multiple search filter options. Aligning with popular filter thresholds maximizes the chances of your items being discovered by buyers. Avoid pricing items at 24, 49, 99, 249, or 499 since such prices fall just outside the common filter ranges, reducing the item's visibility in searches. Buyers often set filters at these rounded thresholds, so pricing items just below them can mean missing out on a significant portion of potential views and sales.

Always set your prices a bit higher on your listings. This way, you have the wiggle room to lower them over time or when negotiating with interested buyers. Adjust as you go to keep buyers engaged and make your deals more attractive.

![Poshmark Prices](${PricesImage})

~~~~light,${colors.white}
##### 3.2 - How to do pricing research?

Pricing is strictly based on market demand and not how you feel or how you think buyers feel about the item. We must lean on current or sold listings for pricing to understand the market demand. If you are not finding comps on Poshmark, you can also check other reseller sites like Depop, eBay, etc. 

Here are the scenarios to look out for:
- No active comps + Many sold comps → Item highly demanded
- No active comps + A few sold comps → Unknown demand
- High-priced active comps + Low-priced sold comps → Low demand or unrealistic pricing
- Low-priced active comps + High priced sold comps → Market-saturated or demand in decline 

~~~~light,${colors.white}
Let's walk through an example: You are on a sourcing trip and find a beautiful Kim + Ono silk robe in excellent condition. You want to determine if I should pick this up and how to price it for resale if you decide to take it.

![Silk Kimono](${SilkDressImage})

Step 1. Check the [active listings](https://poshmark.com/brand/Kim%20%2B%20Ono?availability=available).

Step 2. Check the [sold listings](https://poshmark.com/brand/Kim%20%2B%20Ono?availability=sold_out)

From the results, we can see many active and sold listings; However, if we look closer, most items are not 100% Silk but Satin(Polyester). Therefore, there aren’t as many comparables. From this, we could deduce that there isn’t much demand for pure silk robes or that the demand is unknown.

In that case, you will either have to put what you feel is realistic and wait for a buyer to make an offer, or you will have to match your competitor's pricing and try to compete by outranking them in the results – leveraging your knowledge to create a search-optimized listing.

If there are no direct sold comps, expand your search within the brand, similar brands, or another platform.

~~~~light,${colors.white}
#### Poshmark Listing Analyzer

Now, let all the above sink in. We've covered the most effective strategies for making your Poshmark listings pop. The next step? Bringing those strategies to life in your own shop.

The best way to start is with our [Poshmark Listing Analyzer](https://poshwatch.io/listing-analyzer-poshmark); It's the perfect companion to apply what you've learned. It assesses your listing and provides detailed feedback to enhance and refine it to perfection, ensuring it stands out.

![Poshmark Listing Analyzer](${ListingAnalyzer})

~~~~light,${colors.white}
#### Conclusion

To wrap it all up, I understand it might seem like a lot to take in at first. But remember, with each listing you create using these tips, you're building muscle memory. This means every new listing becomes a bit more intuitive and effective than the last. 

Successful listing creation involves positioning to attract, hook, and convert buyers. Each element is crucial to your listing’s success, from crafting polished SEO-optimized titles to taking attractive, well-lit photos, setting the right price and everything else in between. 

By embracing the strategies and tips outlined in this blog and utilizing our Listing Analyzer, you will create optimized listings that look professional, build trust and outperform Poshmark’s marketplace. 

Happy selling! Go and make those listings sparkle!
`;

export default howToList;