import React from 'react';
import { Chip, Avatar } from '@mui/joy';
import { formatNumberCompact } from '../utils/helpers';

const CustomChip = ({ showAvatar, name, count, index }) => {
  return (
    <Chip
      key={index}
      variant="soft"
      size="sm"
      sx={{
        marginRight: '4px',
        marginBottom: '4px',
        fontSize: '11px',
      }}
      startDecorator={
        showAvatar ? (
          <Avatar
            size="sm"
            sx={{
              // fontSize: '8px',
              // fontWeight: 'bold',
              // color: 'white',
              // bgcolor: '#888',
            }}
          >
            {formatNumberCompact(count)}
          </Avatar>
        ) : undefined
      }
    >
      {name}
    </Chip>
  );
};

export default CustomChip;