import { useState, useMemo, useCallback } from 'react';

function useFeeCalculator(initialCountry) {
  const [cost, setCost] = useState(0.0);
  const [salePrice, setSalePrice] = useState(0.0);
  const [salesTax, setSalesTax] = useState(0.0);
  const [country, setCountry] = useState(initialCountry);
  const [fee, setFee] = useState(null);

  const CANADA_SALES_THRESHOLD = 20;
  const US_SALES_THRESHOLD = 15;

  const sellerFee = useMemo(() => {
    const price = parseFloat(salePrice) || 0;
    
    if (country === 'US') {
      if (price > US_SALES_THRESHOLD) {
        setFee('-20%');
        return (price * 0.2);
      } else {
        setFee('-$2.95');
        return 2.95;
      }
    } else {
      if (price > CANADA_SALES_THRESHOLD) {
        setFee('-20%');
        return (price * 0.2);
      } else {
        setFee('-$3.95');
        return 3.95;
      }
    }
  }, [salePrice, country]);

  const salesTaxAmount = useMemo(() => {
    return (parseFloat(salePrice) || 0) * (parseFloat(salesTax) || 0) / 100;
  }, [salePrice, salesTax]);

  const grossProfit = useMemo(() => {
    return (parseFloat(salePrice) || 0) - salesTaxAmount - sellerFee - (parseFloat(cost) || 0);
  }, [salePrice, salesTaxAmount, sellerFee, cost]);

  const profitMargin = useMemo(() => {
    const price = parseFloat(salePrice) || 0;
    return price > 0 ? (grossProfit / price) * 100 : 0;
  }, [salePrice, grossProfit]);

  const handleCountryChange = useCallback((newCountry) => {
    setCountry(newCountry);
  }, []);

  return {
    cost,
    setCost,
    salePrice,
    setSalePrice,
    salesTax,
    setSalesTax,
    salesTaxAmount,
    sellerFee,
    grossProfit,
    profitMargin,
    country,
    setCountry: handleCountryChange,
    fee,
  };
}

export default useFeeCalculator;
