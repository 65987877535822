import React, { createContext, useState, useContext } from 'react';

// Define the shape of our context
interface DataContextType<T> {
  data: T[];
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
  currentData: T | null;
}

// Create the context with generic type
const DataContext = createContext<DataContextType<any> | null>(null);

// Props for the DataProvider
interface DataProviderProps<T> {
  children: React.ReactNode;
  initialData: T[];
}

// Create the provider component with generic type
export function DataProvider<T>({ children, initialData }: DataProviderProps<T>) {
  const [data] = useState<T[]>(initialData);
  const [currentIndex, setCurrentIndex] = useState(0);

  const value: DataContextType<T> = {
    data,
    currentIndex,
    setCurrentIndex,
    currentData: data[currentIndex],
  };

  return (
    <DataContext.Provider value={value}>
      {children}
    </DataContext.Provider>
  );
}

// Custom hook to use the context
export function useData<T>(): DataContextType<T> {
  const context = useContext(DataContext);
  if (context === null) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context as DataContextType<T>;
}