import { useEffect, useMemo, useState } from "react";
import { checkExtensionInstall } from "../utils/plasmo_helper";

const useExtensionVersion = () => {
  const MINIMUM_VERSION_REQUIRED = "2.0.9";

  // Version assigned to extension when version number cannot be determined
  const INDETERMINATE_VERSION = "1.0.0";

  // Version assigned when extension is not installed
  const NO_VERSION = "0.0.0";

  const [extensionVersion, setExtensionVersion] = useState<string | null>(null);

  const compareVersions = (version1: string, version2: string) => {
    const v1Parts = version1.split('.').map(Number);
    const v2Parts = version2.split('.').map(Number);

    for (let i = 0; i < Math.max(v1Parts.length, v2Parts.length); i++) {
      const v1Part = v1Parts[i] || 0;
      const v2Part = v2Parts[i] || 0;

      if (v1Part > v2Part) {
        return true;
      } else if (v1Part < v2Part) {
        return false;
      }
    }

    // If all parts are equal
    return true;
  };

  const isLatestVersion = useMemo(() => {
    return compareVersions(extensionVersion || NO_VERSION, MINIMUM_VERSION_REQUIRED);
  }, [extensionVersion]);

  const isExtensionInstalled = useMemo(() => {
    if (extensionVersion === null) return null;

    if (extensionVersion === NO_VERSION) return false;

    return true;
  }, [extensionVersion])

  const installExtensionMessage = useMemo(() => {
    if (isExtensionInstalled === false) {
      return "Click here to install the Poshwatch Chrome extension. It is required to use the sharing tool."
    } else if (isExtensionInstalled === true && isLatestVersion === false) {
      return `Click here to install the latest version of the Poshwatch Chrome extension. Installed version ${extensionVersion}. Required ${MINIMUM_VERSION_REQUIRED} or later.`
    }
  }, [extensionVersion, isExtensionInstalled, isLatestVersion]);

  const showExtensionInstallWarning = useMemo(() => {
    if (isExtensionInstalled === null) return null;

    return isExtensionInstalled === false || (isExtensionInstalled === true && isLatestVersion === false);
  }, [isExtensionInstalled, isLatestVersion])

  useEffect(() => {
    let interval;

    const checkInstall = async () => {
      const response = await checkExtensionInstall();

      if (response) {
        setExtensionVersion(response?.manifest?.version ?? INDETERMINATE_VERSION);
      }
      else {
        setExtensionVersion(NO_VERSION);
      }

      clearInterval(interval);
    };

    if (isExtensionInstalled === null || isExtensionInstalled === false) {
      checkInstall(); // Run immediately

      interval = setInterval(() => {
        checkInstall();
      }, 3000);
    }

    return () => clearInterval(interval);
  }, [isExtensionInstalled]);

  return { installExtensionMessage, showExtensionInstallWarning }
}

export default useExtensionVersion;