import React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ResponsiveAppBar } from '../../components/ResponsiveAppBar';
import Footer from '../../components/Footer';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Helmet } from 'react-helmet-async';
import Panel from '../../components/Panel';
import { lightTheme } from '../../customTheme';
import { useAuth } from '../../contexts/AuthContext';
import SignInCard from '../../components/SignInCard';
import Box from '@mui/material/Box';
import PATHS from '../../utils/paths';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';

const SignIn = () => {
  const { isSignedIn } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const fromPricing = searchParams.get('from') === 'pricing';

  React.useEffect(() => {
    if (isSignedIn) {
      if (fromPricing) {
        navigate(PATHS.PRICING);
      } else {
        navigate(PATHS.PRODUCT_RESEARCH);
      }
    }
  }, [isSignedIn, navigate, fromPricing]);

  return (
    <div style={{ position: 'relative' }}>
      {!isSignedIn && <>
        <Helmet >
          <title>Sign in</title>
          <meta name="description" content={"Sign in to Poshwatch"} />
        </Helmet>
        <ResponsiveAppBar hideLinks />
        <Panel theme={lightTheme}>
          <Container maxWidth='sm' style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
            <Card variant="outlined" style={{ minWidth: "400px" }}>
              <CardContent style={{ padding: "30px" }}>
                <Typography variant="h4" fontFamily={"Libre Baskerville"}>
                  Sign in
                </Typography>
                <Typography variant="body1" paddingTop={2} paddingBottom={4} >
                  To access to additional features.
                </Typography>
                <SignInCard />
                <Box paddingBottom={2} paddingTop={2}>
                  <Divider />
                </Box>
                <Button
                  onClick={
                    () => {
                      navigate(PATHS.REGISTER);
                    }
                  }
                  variant="text"
                  color="primary"
                  fullWidth
                  style={{ textTransform: 'none', textDecoration: 'underline' }}
                >
                  Don't have an account? Sign Up
                </Button>
              </CardContent>
            </Card>
          </Container>
        </Panel>
        <Footer hideLinks />
      </>}
    </div>
  );
};

export default SignIn;