const SaltBae = require('./images/salt-bae.png');
const Arthur1 = require('./images/arthur/arthur-ears-1.png');
const Arthur2 = require('./images/arthur/arthur-ears-2.png');
const Arthur3 = require('./images/arthur/arthur-ears-3.png');

const Aldo1 = require('./images/alo-top-bags/winterfun-tote-bag.png');
const Aldo2 = require('./images/alo-top-bags/cross-body-bucket-bag.png');
const Aldo3 = require('./images/alo-top-bags/the-sheer-duffle.png');

const Reformation = require('./images/dresses/reformation.png');
const FreePeople = require('./images/dresses/free-people.png');
const LillyPulitzer = require('./images/dresses/lilly-pulitzer.png');

const YellowSuit = require('./images/yellow-suit.png');

const KateSpade1 = require('./images/kate-spade/bags.png');
const KateSpade2 = require('./images/kate-spade/accessories.png');
const KateSpade3 = require('./images/kate-spade/jewelry.png');

const Anthro1 = require('./images/anthro/anthro1.png');
const Anthro2 = require('./images/anthro/anthro2.png');
const Anthro3 = require('./images/anthro/anthro3.png');

const JCrew1 = require('./images/jcrew/1.png');
const JCrew2 = require('./images/jcrew/2.png');
const JCrew3 = require('./images/jcrew/3.png');

const Dog = require('./images/dog.png');

export interface Tweet {
  name: string;
  username: string;
  message: string;
  images?: string[];
}

type ChartDataPoint = {
  label: string;
  count: number;
};

export type ChartData = {
  title: string;
  subtitle: string;
  formatting?: 'quantity' | 'dollar',
  data: ChartDataPoint[],
}

type TopItems = {
  rank: number;
  image: string;
  label: string;
  subtitle?: string;
}

export type TopData = {
  title: string;
  description: string;
  date?: string;
  items: TopItems[];
}

export const tweets: Tweet[] = [
  {
    "name": "Poshwatch",
    "username": "poshwatch.io",
    "message": "Me after taking photos and making 3 listings",
    "images": [Dog],
  },
  {
    "name": "Jake",
    "username": "AdventureSeeker",
    "message": "That moment when you find a designer item at the thrift store for $5... and it's actually authentic!"
  },
  {
    "name": "Poshwatch",
    "username": "poshwatch.io",
    "message": "Me eyeing the worker rolling out the fresh Goodwill bins",
    "images": [YellowSuit],
  },
  {
    "name": "Poshwatch",
    "username": "poshwatch.io",
    "message": "Poshmark customer support listening to their users",
    "images": [Arthur1, Arthur2, Arthur3],
  },
  {
    "name": "Emma",
    "username": "CoffeeAndClothes",
    "message": "When I die spread my ashes in the Goodwill bins 💀",
    "images": SaltBae,
  },
  {
    "name": "Jake",
    "username": "AdventureSeeker",
    "message": "That moment when you find a designer item at the thrift store for $5... and it's actually authentic!"
  },
  {
    "name": "Sarah",
    "username": "BookwormBaker",
    "message": "Dear potential buyers: Asking me to model a size XXS when I'm clearly not that size is... interesting."
  },
  {
    "name": "Mike",
    "username": "TechEnthusiast",
    "message": "Pro tip: If you're going to lowball, at least have the decency to use the offer button."
  },
  {
    "name": "Lily",
    "username": "GreenThumbGal",
    "message": "Just had a buyer leave 5 stars and the comment 'Perfect!' Then they opened a case. Make it make sense. 🤷‍♀️"
  },
  {
    "name": "Alex",
    "username": "FitnessFanatic",
    "message": "Spent my day off 'relaxing' by reorganizing my entire inventory. Tell me you're a reseller without telling me you're a reseller."
  },
  {
    "name": "Olivia",
    "username": "WanderlustDreamer",
    "message": "When you finally clear out your death pile and feel like you've conquered the world. Small victories!"
  },
  {
    "name": "Ethan",
    "username": "MusicMaestro",
    "message": "Note to self: Stop buying things 'for your closet' that are actually for your personal closet."
  },
  {
    "name": "Zoe",
    "username": "ArtisticSoul",
    "message": "That feeling when you get an offer notification... and it's actually reasonable! 😱"
  },
  {
    "name": "Noah",
    "username": "FoodieFanatic",
    "message": "Sometimes I wonder if my packages arrive safely, then I remember I put enough tape on them to survive the apocalypse."
  }
];

export const bars: ChartData[] = [
  // {
  //   title: "Most Popular Sizes for Skims",
  //   subtitle: "Sales data for Skims based on size for the past month",
  //   formatting: "quantity",
  //   data: [
  //     { label: "US S", count: 760 },
  //     { label: "US M", count: 697 },
  //     { label: "US L", count: 413 },
  //     { label: "US XS", count: 302 },
  //     { label: "US XL", count: 226 },
  //     { label: "US 4X", count: 163 },
  //     { label: "US XXS", count: 157 },
  //     { label: "US 3X", count: 148 },
  //     // { label: "US 2X", count: 119 },
  //     // { label: "S/M", count: 83 },
  //   ],
  // },
  {
    title: "Skims Sleepwear and Tops Lead the Charts",
    subtitle: "Sales data for Skims for the past month",
    data: [
      { label: "Intimates & Sleepwear", count: 72110 },
      { label: "Tops", count: 32550 },
      { label: "Dresses", count: 19840 },
      { label: "Swim", count: 14770 },
      { label: "Pants & Jumpsuits", count: 7160 },
      { label: "Shorts", count: 2540 },
      { label: "Skirts", count: 915 },
      { label: "Other", count: 614 },
      // { label: "Accessories", count: 544 },
      // { label: "Jackets & Coats", count: 515 },
    ],
  },
  {
    title: "Jackets & Coats Dominates The North Face Sales on Poshmark",
    subtitle: "Sales data for The North Face for the past month",
    data: [
      { label: "Jackets & Coats", count: 37710 },
      { label: "Bags", count: 14010 },
      { label: "Shirts", count: 6180 },
      { label: "Shorts", count: 5820 },
      { label: "Tops", count: 5560 },
      { label: "Pants & Jumpsuits", count: 4560 },
      { label: "Shoes", count: 3280 },
      { label: "Sweaters", count: 2560 },
      { label: "Accessories", count: 2500 },
      { label: "Pants", count: 2290 },
    ],
  },
  {
    title: "Jewelry Dominates Vintage Sales on Poshmark",
    subtitle: "Sales data for vintage items for the past month",
    data: [
      { label: "Jewelry", count: 1050000 },
      { label: "Other", count: 17810 },
      { label: "Dresses", count: 14120 },
      { label: "Jackets & Coats", count: 6830 },
      { label: "Tops", count: 6510 },
      { label: "Accessories", count: 6380 },
      { label: "Accents", count: 5470 },
      { label: "Shirts", count: 5000 },
      { label: "Sweaters", count: 4430 },
      { label: "Bags", count: 3990 },
    ],
  },
  {
    title: "Top Non-Luxury Brands by Sales",
    subtitle: "7 Day Sales Performance (in USD thousands)",
    data: [
      { label: "Vintage", count: 500.57 },
      { label: "Free People", count: 289.01 },
      { label: "Lululemon Athletica", count: 247.44 },
      { label: "Nike", count: 196.33 },
      { label: "Adidas", count: 148.9 },
      { label: "Reformation", count: 135.45 },
      { label: "Madewell", count: 109.12 },
    ],
  },
  {
    title: "Top Luxury Brands by Sales",
    subtitle: "7 Day Sales Performance (in USD thousands)",
    data: [
      { label: "Louis Vuitton", count: 554.91 },
      { label: "CHANEL", count: 340.28 },
      { label: "Gucci", count: 257.19 },
      { label: "Coach", count: 201.24 },
      { label: "Kate Spade", count: 150.24 },
      { label: "Tory Burch", count: 135.17 },
      { label: "Hermes", count: 102.1 },
    ],
  },
  {
    title: "Mid-Range Fashion Brands",
    subtitle: "7 Day Sales Performance (in USD thousands)",
    data: [
      { label: "Free People", count: 289.01 },
      { label: "Kate Spade", count: 150.24 },
      { label: "Reformation", count: 135.45 },
      { label: "Tory Burch", count: 135.17 },
      { label: "Madewell", count: 109.12 },
      { label: "Lilly Pulitzer", count: 105.52 },
      { label: "Birkenstock", count: 101.56 },
    ],
  },
  {
    title: "Top Sellers on Poshmark",
    subtitle: "7 Day Sales Performance (in USD thousands)",
    data: [
      { label: "@nilesy", count: 66830 },
      { label: "@nwtbrands2love", count: 47550 },
      { label: "@la_via", count: 45630 },
      { label: "@rebag", count: 31650 },
      { label: "@elady", count: 31340 },
      { label: "@bg1239", count: 28460 },
      { label: "@tara_perks", count: 25000 },
    ],
  },
];

export const pies: ChartData[] = [
  {
    title: "Leggings Dominate Poshmark's Pants Category",
    subtitle: "Most popular pant styles on Poshmark",
    data: [
      { label: "Leggings", count: 7000 },
      { label: "Jumpsuits & Rompers", count: 6000 },
      { label: "Track Pants & Joggers", count: 4000 },
      { label: "Wide Leg", count: 3000 },
      { label: "Ankle & Cropped", count: 2000 },
      { label: "Straight Leg", count: 2000 },
      { label: "Trousers", count: 1000 },
      { label: "Capris", count: 1000 },
      { label: "Boot Cut & Flare", count: 823 },
      { label: "Skinny", count: 445 },
    ],
  },
  {
    title: "Athletic Shorts Lead Summer Trends",
    subtitle: "Most popular shorts by sales on Poshmark",
    data: [
      { label: "Athletic Shorts", count: 9000 },
      { label: "Jean Shorts", count: 6000 },
      { label: "Athletic", count: 3000 },
      { label: "Skorts", count: 2000 },
      { label: "High Waist", count: 1000 },
      { label: "Flat Front", count: 1000 },
      { label: "Bike Shorts", count: 1000 },
      { label: "Bermudas", count: 1000 },
      { label: "Cargo", count: 770 },
      { label: "Cargos", count: 324 },
    ],
  },
  {
    title: "Crossbody Bags Top the Charts",
    subtitle: "Most popular bag styles by sales on Poshmark",
    data: [
      { label: "Crossbody Bags", count: 9000 },
      { label: "Totes", count: 6000 },
      { label: "Shoulder Bags", count: 6000 },
      { label: "Wallets", count: 5000 },
      { label: "Backpacks", count: 4000 },
      { label: "Clutches & Wristlets", count: 2000 },
      { label: "Cosmetic Bags & Cases", count: 2000 },
      { label: "Satchels", count: 2000 },
      { label: "Mini Bags", count: 1000 },
      { label: "Travel Bags", count: 1000 },
    ],
  },
  {
    title: "Midi Dresses Lead the Fashion Pack",
    subtitle: "Most popular dress styles by on Poshmark",
    data: [
      { label: "Midi", count: 14000 },
      { label: "Mini", count: 12000 },
      { label: "Maxi", count: 10000 },
      { label: "Casual", count: 1000 },
      { label: "Long Sleeve", count: 1000 },
      { label: "Wedding", count: 704 },
      { label: "Strapless", count: 574 },
      { label: "Asymmetrical", count: 409 },
      { label: "High Low", count: 402 },
      { label: "Prom", count: 382 },
    ],
  },
  {
    title: "Summer Tops Trend on Poshmark",
    subtitle: "Most popular tops on Poshmar for the past month",
    data: [
      { label: "Blouses", count: 14000 },
      { label: "Tank Tops", count: 11000 },
      { label: "Tees Short Sleeve", count: 10000 },
      { label: "Sweatshirts & Hoodies", count: 5000 },
      { label: "Button Down Shirts", count: 4000 },
      { label: "Crop Tops", count: 4000 },
      { label: "Tees Long Sleeve", count: 3000 },
      { label: "Tunics", count: 2000 },
      { label: "Bodysuits", count: 2000 },
      { label: "Camisoles", count: 961 },
    ],
  },
  {
    title: "Sandals Lead Summer Footwear Trends",
    subtitle: "Top-trending footwear styles on Poshmark this month",
    data: [
      { label: "Sandals", count: 18000 },
      { label: "Sneakers", count: 15000 },
      { label: "Athletic Shoes", count: 10000 },
      { label: "Heels", count: 6000 },
      { label: "Flats & Loafers", count: 6000 },
      { label: "Ankle Boots & Booties", count: 3000 },
      { label: "Mules & Clogs", count: 3000 },
      // { label: "Sandals & Flip Flops", count: 3000 },
      // { label: "Boots", count: 2000 },
      // { label: "Heeled Boots", count: 2000 },
    ],
  },
  {
    title: "Flare Leg Jeans Are Making a Comeback",
    subtitle: "Poshmark's best selling jean styles in the last 30 days",
    data: [
      { label: "Flare & Wide Leg", count: 3172 },
      { label: "Skinny", count: 2058 },
      { label: "Straight Leg", count: 1987 },
      { label: "Boot Cut", count: 2104 },
      { label: "Ankle & Cropped", count: 1946 },
      { label: "High Rise", count: 1023 },
      { label: "Straight", count: 971 },
      { label: "Boyfriend", count: 450 },
      // { label: "Bootcut", count: 378 },
      // { label: "Relaxed", count: 347 },
    ],
  }];

export const topData: TopData[] = [
  {
    title: "J.Crew - Top 3 Categories",
    description: "Monthly sales leaders",
    items: [
      { rank: 1, image: JCrew1, label: 'Dresses', subtitle: '$43.73K in Sales' },
      { rank: 2, image: JCrew2, label: 'Tops', subtitle: '$35.45K in Sales' },
      { rank: 3, image: JCrew3, label: 'Jackets', subtitle: '$31.28K in Sales' },
    ]
  },
  {
    title: "Anthropologie - Top 3 Categories",
    description: "Weekly sales leaders by volume",
    items: [
      { rank: 1, image: Anthro1, label: 'Earrings', subtitle: '1K Sold' },
      { rank: 2, image: Anthro2, label: 'Blouses', subtitle: '640 Sold' },
      { rank: 3, image: Anthro3, label: 'Maxi Dresses', subtitle: '569 Sold' },
    ]
  },
  {
    title: "3 Best Selling Kate Spade Categories",
    description: "Weekly sales leaders by volume and revenue",
    items: [
      { rank: 1, image: KateSpade1, label: 'Bags', subtitle: '5K Sold, $325.61K' },
      { rank: 2, image: KateSpade2, label: 'Accessories', subtitle: '1K Sold, $49.28K' },
      { rank: 3, image: KateSpade3, label: 'Jewelry', subtitle: '1K Sold, $48.02K' },
    ]
  },
  {
    title: "3 Leading Dress Brands",
    description: "These brands dominate the dress category, leading in sales on Poshmark",
    items: [
      { rank: 1, image: Reformation, label: 'Reformation' },
      { rank: 2, image: FreePeople, label: 'Free People' },
      { rank: 3, image: LillyPulitzer, label: 'Lilly Pulitzer' },
    ]
  },
  {
    title: "Fast sellers",
    description: "These ALO bags sell in less than 3 days on Poshmark",
    items: [
      { rank: 1, image: Aldo1, label: 'Winterfun Tote Bag' },
      { rank: 2, image: Aldo2, label: 'Cross Body Bucket Bag' },
      { rank: 3, image: Aldo3, label: 'The Sheer Duffle' },
    ]
  }
]