import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import Breadcrumbs from '@mui/joy/Breadcrumbs';
import Link from '@mui/joy/Link';
import Typography from '@mui/joy/Typography';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';

interface BreadcrumbLink {
  label: string;
  path: string;
}

const generateBreadcrumbLinks = (pathname: string): BreadcrumbLink[] => {
  const paths = pathname.split('/').filter(Boolean);
  const breadcrumbs: BreadcrumbLink[] = [{ label: 'Home', path: '/' }];

  if (paths.length > 0) {
    paths.forEach((path, index) => {
      breadcrumbs.push({
        label: path.charAt(0).toUpperCase() + path.slice(1),
        path: `/${paths.slice(0, index + 1).join('/')}`,
      });
    });
  }

  return breadcrumbs;
};

const CustomBreadcrumbs: React.FC = () => {
  const location = useLocation();
  const breadcrumbLinks = generateBreadcrumbLinks(location.pathname);

  return (
    <Breadcrumbs
      size="sm"
      aria-label="breadcrumbs"
      separator={<ChevronRightRoundedIcon fontSize="small" />}
      sx={{ pl: 0 }}
    >
      {breadcrumbLinks.map((link, index) => {
        const isLast = index === breadcrumbLinks.length - 1;

        if (isLast) {
          return (
            <Typography key={link.path} level="body-sm">
              {link.label}
            </Typography>
          );
        }

        return (
          <Link
            key={link.path}
            component={RouterLink}
            underline={index === 0 ? 'none' : 'hover'}
            color="neutral"
            to={link.path}
            level="body-sm"
          >
            {index === 0 ? <HomeRoundedIcon /> : link.label}
          </Link>
        );
      })}
    </Breadcrumbs>
  );
};

export default CustomBreadcrumbs;