import React from "react";
import { Analyzer } from "../Analyzer";
import { fetchListingAnalysis } from "../../api/client";
import PATHS from '../../utils/paths';

export const DepopAnalyzer = () => {
  return (
    <Analyzer 
      platform="Depop"
      canonicalUrl ={PATHS.DEPOP_LISTING_ANALYZER}
      getListingAnalysisCallback={fetchListingAnalysis}
    />
  );  
};


// https://webapi.depop.com/api/v1/recentlyviewed/13640293/?limit=8
// https://webapi.depop.com/api/v1/nudges/products/266660780/
// https://webapi.depop.com/api/v1/moreItems/29259321/266660780?limit=6&location=ca