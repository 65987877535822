import { Button } from '@mui/material';
import { colors } from '../customTheme';
import { useMediaQuery } from '@mui/material';
import React from 'react';

interface HeroButtonProps {
  onClick?: () => void;
  backgroundColor?: string;
  textColor?: string;
  borderColor?: string;
  shadowColor?: string;
  children: React.ReactNode;
}

const HeroButton: React.FC<HeroButtonProps> = ({
  onClick,
  backgroundColor = colors.woodsmoke,
  textColor = colors.wafer,
  borderColor = colors.wafer,
  shadowColor = colors.wafer,
  children
}) => {
  const isMobile = useMediaQuery('(max-width:600px)');

  return (
    <Button
      variant="contained"
      color="primary"
      onClick={onClick}
      style={{
        borderRadius: 8,
      }}
      sx={{
        color: textColor,
        fontSize: "1.2rem",
        height: 64,
        width: isMobile ? '70%' : 300,
        fontWeight: 500,
        textTransform: 'none',
        backgroundColor: backgroundColor,
        border: `1px solid ${borderColor}`,
        boxShadow: `-8px 8px 0px 0px ${shadowColor}`,
        transition: 'box-shadow 0.3s ease',
        '&:hover': {
          boxShadow: `-6px 6px 0px 0px ${shadowColor}`,
        },
      }}
    >
      {children}
    </Button>
  );
};

export default HeroButton;