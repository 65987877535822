import React, { useEffect, useState } from 'react';
import {
  Box,
  Input,
  Grid,
  Typography,
  Button,
  TabList,
  TabPanel,
  Tabs,
  Tab,
  tabClasses,
} from '@mui/joy';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import Dashboard from '../dashboard/Dashboard.tsx';
import SectionTitle from '../details/components/SectionTitle.tsx';
import CustomDataTable from './components/CustomDataTable.tsx';
import FormControl from '@mui/joy/FormControl';
import FormHelperText from '@mui/joy/FormHelperText';
import { EnhancedTableToolbar } from './components/CustomDataTable.tsx';
import { useSearch } from './hooks/useSearch.ts';
import { StatsCard } from './components/StatsCard.tsx';
import { formatNumberStandard, formatTotalPrice, formatNumberCompact } from '../../utils/helpers.js';
import SearchSuggestions from '../../components/SearchSuggestions.tsx';
import { Experimental_CssVarsProvider } from '@mui/material';
import { SearchSkeleton } from './components/SearchSkeleton.tsx';
import { useNavigate } from 'react-router-dom';
import PATHS from '../../utils/paths.js';
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import Chip from '@mui/joy/Chip';
import Avatar from '@mui/joy/Avatar';

interface FilterSelectProps {
  placeholder: string;
  value: string[];
  onChange: (value: string[]) => void;
  options: { slug: string; display: string; count: number; }[];
}

const FilterSelect = ({ placeholder, value, onChange, options }: FilterSelectProps) => (
  <Select
    multiple
    placeholder={placeholder}
    value={value}
    onChange={(_, value) => onChange(value as string[])}
    size="lg"
    renderValue={(selected) => (
      <Box sx={{ display: 'flex', gap: '0.25rem', flexWrap: 'wrap' }}>
        {(selected ?? []).map((selectedOption) => {
          const optionData = options.find(opt => opt.slug === selectedOption.value);
          return (
            <Chip
              key={selectedOption.value}
              variant="soft"
              color="primary"
              size="lg"
              endDecorator={
                <Avatar
                  size="sm"
                  sx={{
                    bgcolor: 'primary.softBg',
                    fontSize: '0.6rem',
                    width: '1.5rem',
                    height: '1.5rem'
                  }}
                >
                  {formatNumberCompact(optionData?.count ?? 0)}
                </Avatar>
              }
            >
              {optionData?.display ?? selectedOption.value}
            </Chip>
          );
        })}
      </Box>
    )}
    sx={{ minWidth: '15rem' }}
    slotProps={{
      listbox: {
        sx: {
          width: '100%',
          maxHeight: '300px',
        },
      },
    }}
  >
    {options.map((option) => (
      <Option key={option.slug} value={option.slug}>
        {option.display} ({option.count})
      </Option>
    ))}
  </Select>
);

export const Search = () => {
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();

  const {
    results,
    stats,
    query,
    loading,
    error,
    errorCode,
    showSuggestions,
    setQuery,
    setShowSuggestions,
    handleSearch,
    postGrid,
    distributions,
    filterOptions,
    selectedBrands,
    setSelectedBrands,
    selectedCategories,
    setSelectedCategories,
    selectedSizes,
    setSelectedSizes,
    selectedColors,
    setSelectedColors,
  } = useSearch();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSearch(query);
  };

  const handleSuggestionClick = (suggestion: string) => {
    setQuery(suggestion);
    handleSearch(suggestion);
  };

  const searchResultsHeaders = [
    {
      id: 'image',
      numeric: false,
      disablePadding: true,
      label: 'Photo',
      width: '98px'
    },
    {
      id: 'title',
      numeric: false,
      disablePadding: true,
      label: 'Title',
      width: postGrid.showAvailable ? '66%' : '34%'
    },
    {
      id: 'price',
      numeric: true,
      disablePadding: false,
      label: 'Price',
      width: '12%',
      formatter: (value: number) => formatTotalPrice(value)
    },
    ...(!postGrid.showAvailable ? [
      {
        id: 'timeToSell',
        numeric: true,
        disablePadding: false,
        label: 'Time to Sell',
        width: '16%',
        formatter: (value: number) => postGrid.formatTimeToSell(value)
      },
      {
        id: 'daysSinceSale',
        numeric: true,
        disablePadding: false,
        label: 'Sold Date',
        width: '16%',
        formatter: (value: number) => postGrid.formatTimeSinceSale(value)
      },
    ] : []),
    {
      id: 'likes',
      numeric: true,
      disablePadding: false,
      label: 'Likes',
      width: '10%',
      formatter: (value: number) => formatNumberStandard(value)
    },
    {
      id: 'shares',
      numeric: true,
      disablePadding: false,
      label: 'Shares',
      width: '12%',
      formatter: (value: number) => formatNumberStandard(value)
    }
  ];

  const createDistributionHeaders = (titleLabel: string) => [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: titleLabel,
    },
    {
      id: 'sold',
      numeric: true,
      disablePadding: false,
      label: 'Sold',
    },
    {
      id: 'available',
      numeric: true,
      disablePadding: false,
      label: 'Available',
    }
  ];

  const colorHeaders = createDistributionHeaders('Color');
  const sizeHeaders = createDistributionHeaders('Size');
  const styleHeaders = createDistributionHeaders('Style');
  const featureHeaders = createDistributionHeaders('Feature');
  const categoryHeaders = createDistributionHeaders('Category');
  const departmentHeaders = createDistributionHeaders('Department');

  useEffect(() => {
    if (errorCode === 429) {
      navigate(`${PATHS.PRICING}?code=${errorCode}`);
    }
  }, [errorCode, navigate]);

  return (
    <Dashboard>
      {/* Title Section */}
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, pb: 2 }}>
        <Typography
          level="h2" component="h1" style={{
            fontSize: "1.8em",
            fontWeight: 'bold',
            fontFamily: "Libre Baskerville"
          }}
        >
          Product Research
        </Typography>
        <Typography color="neutral" level="body-md">
          Find Poshmark's fast-selling products and source with confidence.
        </Typography>
      </Box>

      {/* Search Section */}
      <Box sx={{ mb: 4 }}>
        <form onSubmit={handleSubmit}>
          <FormControl>
            <Input
              size="lg"
              placeholder="Enter a product description..."
              startDecorator={<SearchRoundedIcon />}
              value={query}
              onChange={(e) => {
                setQuery(e.target.value);
                setShowSuggestions(true);
              }}
              error={!!error}
              sx={{
                '--Input-decoratorChildHeight': '45px',
                fontSize: 'var(--joy-fontSize-lg)'
              }}
              endDecorator={
                <Button
                  size="lg"
                  variant="solid"
                  color="primary"
                  loading={loading}
                  type="submit"
                  sx={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                >
                  Search
                </Button>
              }
            />
            {error && (
              <FormHelperText
                sx={(theme) => ({ color: theme.vars.palette.danger[400] })}
              >
                {error}
              </FormHelperText>
            )}
          </FormControl>
        </form>

        {showSuggestions && !results && (
          // eslint-disable-next-line react/jsx-pascal-case
          <Experimental_CssVarsProvider>
            <SearchSuggestions
              onSuggestionClick={handleSuggestionClick}
            />
          </Experimental_CssVarsProvider>
        )}
      </Box>

      {loading ? (
        <SearchSkeleton />
      ) : (
        results && (
          <Tabs
            value={activeTab}
            onChange={(event, value) => setActiveTab(value as number)}
            sx={{ mb: 2 }}
          >
            <TabList
              sx={{
                [`& .${tabClasses.root}`]: {
                  bgcolor: 'transparent',
                  fontWeight: 500,
                  '&:hover': {
                    bgcolor: 'transparent',
                  },
                  '&.Mui-selected': {
                    color: 'primary.500',
                    bgcolor: 'transparent'
                  }
                },
              }}
            >
              <Tab>Overview</Tab>
              <Tab>Breakdown</Tab>
            </TabList>

            <TabPanel value={0} sx={{ px: 0, pt: 3 }}>
              <Box sx={{
                display: 'flex',
                gap: 2,
                mb: 2,
                flexDirection: { xs: 'column', sm: 'row' },
                flexWrap: 'wrap',
                '& > *': {
                  width: { xs: '100%', sm: 'auto' }
                }
              }}>
                <FilterSelect
                  placeholder="Filter by Brand"
                  value={selectedBrands}
                  onChange={setSelectedBrands}
                  options={filterOptions?.brands ?? []}
                />
                <FilterSelect
                  placeholder="Filter by Category"
                  value={selectedCategories}
                  onChange={setSelectedCategories}
                  options={filterOptions?.categories ?? []}
                />
                <FilterSelect
                  placeholder="Filter by Size"
                  value={selectedSizes}
                  onChange={setSelectedSizes}
                  options={filterOptions?.sizes ?? []}
                />
                <FilterSelect
                  placeholder="Filter by Color"
                  value={selectedColors}
                  onChange={setSelectedColors}
                  options={filterOptions?.colors ?? []}
                />
              </Box>

              <Box sx={{ mb: 4 }}>
                <Grid container spacing={2}>
                  {/* First Row */}
                  {stats.slice(0, 2).map((stat, index) => (
                    <Grid key={index} xs={12} sm={6}>
                      <StatsCard {...stat} />
                    </Grid>
                  ))}
                  {/* Second Row */}
                  {stats.slice(2).map((stat, index) => (
                    <Grid key={index + 2} xs={12} sm={4}>
                      <StatsCard {...stat} />
                    </Grid>
                  ))}
                </Grid>
              </Box>

              <Box sx={{ mb: 4 }}>
                <SectionTitle title="Search Results" />

                <CustomDataTable
                  rows={postGrid.postGridData}
                  headers={searchResultsHeaders}
                  hideFooter={false}
                  minWidth='900px'
                  onRowClick={(row) => window.open(`https://poshmark.com/listing/${row.id}`, '_blank')}
                  toolbar={
                    <EnhancedTableToolbar
                      showAvailable={postGrid.showAvailable}
                      onToggleAvailable={() => postGrid.setShowAvailable(!postGrid.showAvailable)}
                    />
                  }
                />
              </Box>
            </TabPanel>

            <TabPanel value={1} sx={{ px: 0, pt: 3 }}>
              <Box sx={{ mb: 4 }}>
                <Grid container spacing={2}>
                  <Grid xs={12} sm={6}>
                    <CustomDataTable
                      rows={distributions.colorRows as any}
                      headers={colorHeaders}
                      toolbar={<EnhancedTableToolbar title="Colors" />}
                    />
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <CustomDataTable
                      rows={distributions.sizeRows as any}
                      headers={sizeHeaders}
                      toolbar={<EnhancedTableToolbar title="Sizes" />}
                    />
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <CustomDataTable
                      rows={distributions.styleRows as any}
                      headers={styleHeaders}
                      toolbar={<EnhancedTableToolbar title="Style Tags" />}
                    />
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <CustomDataTable
                      rows={distributions.categoryRows as any}
                      headers={categoryHeaders}
                      toolbar={<EnhancedTableToolbar title="Categories" />}
                    />
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <CustomDataTable
                      rows={distributions.departmentRows as any}
                      headers={departmentHeaders}
                      toolbar={<EnhancedTableToolbar title="Departments" />}
                    />
                  </Grid>
                  <Grid xs={12} sm={6}>
                    <CustomDataTable
                      rows={distributions.featureRows as any}
                      headers={featureHeaders}
                      toolbar={<EnhancedTableToolbar title="Features" />}
                    />
                  </Grid>
                </Grid>
              </Box>
            </TabPanel>
          </Tabs>
        )
      )}
    </Dashboard>
  );
};
