/* eslint-disable react/jsx-pascal-case */
import React, { useState } from 'react';
import ToolDetailSection from '../components/tool-content/ToolDetailSection';
import RankingPageDetails from '../components/tool-content/RankingPageDetails.js';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  Alert,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { colors } from '../customTheme';
import { getRank } from "../api/client";
import useMediaQuery from '@mui/material/useMediaQuery';
import { getListingIdFromUrl, formatTotalPrice } from '../utils/helpers';
import { calculateListingAge } from '../utils/helpers.js';
import Dashboard from './dashboard/Dashboard.tsx';
import { Experimental_CssVarsProvider } from '@mui/material/styles';

const SORT_OPTIONS = {
  relevance: "relevance_v2",
  just_shared: "best_match"
}

const ListingRankChecker = ({ title, subtitle, description, href }) => {
  const [listingURLInputError, setListingURLInputError] = useState(null);
  const [keywordsInputError, setKeywordsInputError] = useState(null);
  const [sortBy, setSortBy] = useState(SORT_OPTIONS.relevance);
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [yourRank, setYourRank] = useState(null);
  const [listingURL, setListingURL] = useState('');
  const [listingId, setListingId] = useState('');
  const [hostname, setHostname] = useState('');
  const [keywords, setKeywords] = useState('');
  const [loading, setloading] = useState(false);
  const [results, setResults] = useState(null);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const handleCheck = async () => {
    if (loading) {
      return;
    }
    if (!listingURL) {
      setListingURLInputError("Enter a valid listing URL to continue");
      return;
    }
    if (!keywords) {
      setKeywordsInputError("Enter keywords to continue");
      return;
    }
    if (!listingURL.includes("posh.mk") && !listingURL.includes("poshmark.ca") && !listingURL.includes("poshmark.com")) {
      setListingURLInputError(`${listingURL} is not a valid closet URL`);
      return;
    }

    if (listingURL.includes("closet")) {
      setListingURLInputError(`Please use a listing URL not a closet URL`);
      return;
    }

    setloading(true);
    const hostname = new URL(listingURL).hostname;
    setHostname(hostname);
    const listingId = getListingIdFromUrl(listingURL);
    setListingId(listingId);
    const res = await getRank({ sort: sortBy, keywords: keywords.toLowerCase(), listingId, hostname });

    if (res?.response?.status === 404 || res?.response?.status === 500) {
      setError(res.message);
    } else {
      setResults(res.listings);
      setYourRank(res.listingIndex + 1);
      setloading(false);
    }
  };

  const renderHeading = () => {
    return (
      <Box>
        <Typography mb={2} variant='h1'
          style={{
            fontSize: "1.8em",
            fontWeight: 'bold',
            color: colors.mineShaft,
            fontFamily: "Libre Baskerville"
          }} >{title}</Typography>
        <Typography mb={2}
          variant='body1'
          color="textSecondary">{subtitle}
        </Typography>
        <Card variant="outlined" style={{ marginBottom: '40px' }}>
          <CardContent style={{ display: 'flex', flexDirection: 'column', margin: '20px' }}>
            <FormControl
              style={{
                paddingRight: '12px',
                marginBottom: '15px',
                alignSelf: 'flex-start',
                width: isMobile ? '100%' : '60%'
              }}
            >
              <FormLabel id="demo-controlled-radio-buttons-group">Sort</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={sortBy}
                onChange={(event) => setSortBy(event.target.value)}
              >
                <FormControlLabel value={SORT_OPTIONS.relevance} control={<Radio />} label="Relevance" />
                <FormControlLabel value={SORT_OPTIONS.just_shared} control={<Radio />} label="Just Shared" />
              </RadioGroup>
            </FormControl>
            <Box display="flex" flexDirection='column' alignItems={isMobile ? 'stretch' : 'center'}>
              <TextField
                size="small"
                label="Enter keywords, ex: Lululemon define jacket"
                variant="outlined"
                fullWidth
                value={keywords}
                style={{
                  paddingRight: '12px',
                  marginBottom: '30px',
                  alignSelf: 'flex-start',
                  width: isMobile ? '100%' : '60%'
                }}
                onChange={(e) => {
                  setKeywords(e.target.value);
                  setKeywordsInputError(null);
                }}
                error={keywordsInputError ? true : false}
                helperText={keywordsInputError}
              />
              <TextField
                size="small"
                label="Enter your listing URL"
                variant="outlined"
                fullWidth
                value={listingURL}
                style={{
                  paddingRight: '12px',
                  marginBottom: '30px',
                  alignSelf: 'flex-start',
                  width: isMobile ? '100%' : '60%'
                }}
                onChange={(e) => {
                  setListingURL(e.target.value);
                  setListingURLInputError(null);
                }}
                error={listingURLInputError ? true : false}
                helperText={listingURLInputError}
              />
              <Button
                color="primary"
                variant='contained'
                onClick={() => handleCheck()}
                style={{
                  borderRadius: 10,
                  paddingRight: '12px',
                  height: 44,
                  width: isMobile ? '100%' : 220,
                  marginTop: isMobile ? 10 : 0,
                  boxShadow: 'none',
                  backgroundColor: colors.mineShaft,
                  alignSelf: 'flex-start'
                }}>
                {loading ? (
                  <CircularProgress
                    size={24} // Adjust size as needed
                    color="inherit" // This makes sure the loader matches the button text color
                  />
                ) : (
                  'Search'
                )}
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    )
  }

  const renderResultsTable = () => {
    return (
      <TableContainer sx={{ border: '1px solid silver' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Rank</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Price</TableCell>
              <TableCell>Listing Age</TableCell>
              <TableCell>Condition</TableCell>
              <TableCell>Shares</TableCell>
              <TableCell>Likes</TableCell>
              <TableCell>Style Tags</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              results?.map((item, index) => {
                const age = calculateListingAge(item.publishDate);
                const itemURL = `https://${hostname}/listing/${item.id}`;
                return (<TableRow
                  key={index}
                  style={{
                    backgroundColor: item.id === listingId ? '#e9fce6' : 'white' // Replace with your desired condition and colors
                  }}
                >
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <img
                      src={item.coverShot}
                      alt="Cover Shot"
                      style={{
                        width: '65px', // Adjust the width as needed
                        height: '65px', // Adjust the height as needed
                        objectFit: 'cover' // Ensures the image fits well within the given dimensions
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <a
                      href={`https://${hostname}/listing/${item.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'none', color: '#1990ff' }}
                    >
                      {item.title}
                    </a>
                  </TableCell>
                  <TableCell>{formatTotalPrice(item.price)}</TableCell>
                  <TableCell>{`${age} ${age === 1 ? 'Day' : 'Days'}`}</TableCell>
                  <TableCell>{item.condition ? item.condition.replace('_', " ") : item.condition}</TableCell>
                  <TableCell>{item.shareCount}</TableCell>
                  <TableCell>{item.likeCount}</TableCell>
                  <TableCell>{item.styleTags.length > 0 ? item.styleTags.join(", ") : null}</TableCell>
                  <TableCell>
                    {
                      index < 10 && item.id !== listingId && (
                        <a
                          href={`${window.location.origin}/compare-listings?q1=${listingURL}&q2=${itemURL}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: 'none', color: '#1990ff' }}
                        >
                          Compare→
                        </a>
                      )
                    }
                  </TableCell>
                </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  const renderAnalysis = () => {
    return (
      <Card variant="outlined">
        <CardContent style={{ display: 'flex', flexDirection: 'column', margin: '20px' }}>
          <Box display="flex" flexDirection='column' alignItems={isMobile ? 'stretch' : 'left'}
            sx={{
              minHeight: '150px',
              backgroundColor: 'white'
            }}>
            {loading ? (
              <div>
                <CircularProgress color="info" />
                <Typography variant="h6">Checking Rank</Typography>
              </div>
            ) : !results ? (
              <>
                <Typography variant="body1" style={{ marginBottom: '15px' }}>-----------------</Typography>
                <Typography variant="body1">Perform a search to see top listings</Typography>
              </>
            ) : (
              renderResultsTable()
            )}
          </Box>
        </CardContent>
      </Card>
    );
  }

  const renderYourRanking = () => {
    if (yourRank < 1) {
      return (
        <>
          <div style={{ marginBottom: '20px' }}>
            Does not rank within the first 3 pages of results.
          </div>
          <div style={{ marginBottom: '7px' }}>
            Improve your listing with feedback from our{' '}
            <a
              href={`${window.location.origin}/listing-analyzer-poshmark?q=${listingURL}`}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: '#1990ff' }}
            >
              Listing Analyzer →
            </a>
          </div>
          {sortBy === SORT_OPTIONS.just_shared && (
            <div>
              Automate Sharing your listings with our{' '}
              <a
                href={`${window.location.origin}/poshmark-assistant`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: 'none', color: '#1990ff' }}
              >
                Closet Sharer →
              </a>
            </div>
          )}
        </>
      )
    } else {
      return (<>{`Ranks ${yourRank} out of ${300 > results?.listings?.length ? results?.listings?.length : 300}`}</>);
    }
  }

  const renderListingFeedback = () => {
    return (
      <Alert
        severity={(yourRank < 1) ? "warning" : "success"}
        style={{
          marginBottom: '40px',
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          border: '1px solid',
          borderColor: (yourRank < 1) ? '#FFA726' : '#66BB6A',
        }}
      >
        <Typography mb={4} variant='h5' fontFamily={"Libre Baskerville"}>Your Listing</Typography>
        <Box display="flex" flexDirection='column' alignItems={isMobile ? 'stretch' : 'left'}>
          {yourRank !== null && yourRank !== undefined ? (
            <Typography mb={2} variant='body1'>{renderYourRanking()}</Typography>
          ) : (
            <>
              <Typography variant="body1" style={{ marginBottom: '15px' }}>-----------------</Typography>
              <Typography variant="body1">Perform a search to see your rank</Typography>
            </>
          )
          }
        </Box>
      </Alert>
    )
  }

  return (
    <Dashboard>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={href} />
      </Helmet>
      <Experimental_CssVarsProvider>
        <Box>
          {renderHeading()}
          {(results && yourRank !== undefined) && (renderListingFeedback())}
          {renderAnalysis()}
        </Box>
        <ToolDetailSection
          children={<RankingPageDetails></RankingPageDetails>}
        />
      </Experimental_CssVarsProvider>
    </Dashboard >
  );
};

export default ListingRankChecker;
