import React from "react";
import { Analyzer } from "../Analyzer";
import { fetchListingAnalysis } from "../../api/client";
import PATHS from '../../utils/paths';

export const EbayAnalyzer = () => {
  return (
    <Analyzer 
      platform="Ebay"
      canonicalUrl ={PATHS.EBAY_LISTING_ANALYZER}
      getListingAnalysisCallback={fetchListingAnalysis}
    />
  );
};

// https://developer.ebay.com/api-docs/user-guides/static/finding-user-guide-landing.html