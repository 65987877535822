import React, { useMemo } from 'react';
import { formatTotalPrice, formatNumberCompact, formatName, formatPath } from '../utils/helpers';
import { TopPage, NameCell } from './TopPage';
import PATHS from '../utils/paths';
import CustomChip from '../components/CustomChip.tsx';

const showAvatar = true;

const columns = [
  { field: 'id', headerName: 'Rank', width: 80 },
  {
    field: 'name',
    headerName: 'Category',
    flex: 2,
    sortable: true,
    renderCell: (params) => (
      <NameCell name={formatName(params.value)} href={`/category/${formatPath(params.value)}`} />
    ),
  },
  {
    field: 'soldItemsCount',
    headerName: 'Items Sold',
    type: 'number',
    flex: 2,
    sortable: true,
    valueFormatter: ({ value }) => formatNumberCompact(value),
  },
  {
    field: 'totalPrice',
    headerName: 'Sales',
    type: 'number',
    flex: 2,
    sortable: true,
    valueFormatter: ({ value }) => formatTotalPrice(value),
  },
  {
    field: 'averageSalePrice',
    headerName: 'Average Price',
    type: 'number',
    flex: 2,
    sortable: true,
    valueFormatter: ({ value }) => formatTotalPrice(value),
  },
  {
    field: 'brands',
    headerName: 'Top Brands',
    sortable: false,
    flex: 4,
    renderCell: (params) => (
      <div>
        {params.value
          .filter(brand => brand.name.toLowerCase() !== "unknown")
          .slice(0, 3)
          .map((brand, index) => (
            <CustomChip
              key={index}
              showAvatar={showAvatar}
              name={formatName(brand.name)}
              count={brand.quantity}
              index={index}
            />
          ))}
      </div>
    ),
  },
];

const TopCategories = () => {

  const LastUpdatedDate = useMemo(() => {
    // Calculate the date 3 days from now
    const today = new Date();
    const updatedDate = new Date(today);
    updatedDate.setDate(updatedDate.getDate() - 1);
    const formattedDate = updatedDate.toLocaleDateString("en-US", { year: 'numeric', month: 'long', day: 'numeric' });

    return formattedDate;
  }, []);

  return (
    <TopPage
      type={"categories"}
      columns={columns}
      title={"The Top 100 Categories on Poshmark"}
      description={"Curious about what's in demand? Use this resource to help you source top-selling items for your Poshmark closet and boost your sales."}
      href={PATHS.TOP_CATEGORIES}
      toggleOptions={[
        {
          name: 'date-range',
          label: 'Date Range',
          placeholder: 'Filter by date range',
          options: [
            { value: '1m', label: '1 Month' },
          ],
        },
      ]}
      subtitle={
        < div >
          <p>
            Use this resource with insights from Poshmark sales data to help you source top-selling high value items for your Poshmark closet to make faster sales.
          </p>
          <p>
            Last updated: {LastUpdatedDate}
          </p >
        </div >
      }
    />
  );
};

export default TopCategories;
