import React from 'react';
import { Typography, Box } from '@mui/material';

const AnalyzerDetails = ({ compact, platform }) => {
  const paragraphStyle = {
    marginBottom: '15px',
    lineHeight: '1.6'
  };

  const h2Style = { fontSize: '1.8rem', marginTop: '20px', marginBottom: '20px', fontFamily: "Libre Baskerville", fontWeight: 'bold' }

  return (
    <Box py={4} >
      <Box>
        <Typography variant='h2' style={h2Style}>
          {`About the ${platform} Listing Analyzer`}
        </Typography>
        <Typography variant='body1' style={paragraphStyle}>
          {`Our free listing analyzer provides the insights you need to enhance your ${platform} listings, helping them rank higher in search results and boosting your sales. Simply provide the URL for your listing, and the tool will do the rest.`}
        </Typography>
        <Typography variant='body1' style={paragraphStyle}>
          The analyzer offers a detailed breakdown covering all the important aspects of a listing:
        </Typography>
        <Typography variant='body1' style={paragraphStyle}>
          <ul>
            <li>Title</li>
            <li>Description</li>
            <li>Photos</li>
            <li>Tags</li>
          </ul>
        </Typography>
        <Typography variant='body1' style={paragraphStyle}>
          It also highlights other areas for improvement and provides a personalized listing quality score. With this valuable information, you can refine your listings to achieve five-star quality and gain an edge over the competition.
        </Typography>
        <Typography variant='body1' style={paragraphStyle}>
          All for free!
        </Typography>
      </Box>
    </Box>
  );
};

export default AnalyzerDetails;