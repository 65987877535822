import React from 'react';
import { Box, CardContent, Typography } from '@mui/material';

const SellersPageDetails = () => {
  const paragraphStyle = {
    marginBottom: '15px',
    lineHeight: '1.6'
  };

  const h2Style = { fontSize: '1.8rem', marginTop: '20px', marginBottom: '20px', fontFamily: "Libre Baskerville" }
  const sectionStyle = { marginBottom: '80px' }

  return (
    <CardContent>
      <Box style={sectionStyle}>
        <Typography variant='h2' style={h2Style}>Poshmark's Top Sellers</Typography>
        <Typography variant='body1' style={paragraphStyle}>
          Have you ever wondered, about the financial potential of reselling on Poshmark?
        </Typography>
        <Typography variant='body1' style={paragraphStyle}>
          Or which brands top sellers sell?
        </Typography>
        <Typography variant='body1' style={paragraphStyle}>
          Then this is the tool for you. It is designed to provide insights into the metrics and brands of Poshmark's top sellers that you can leverage for your business. The goal is to motivate you to become a top seller.
        </Typography>
      </Box>
      <Box style={sectionStyle}>
        <Typography variant='h2' style={h2Style}>What Influences This Best-Sellers List?</Typography>
        <Typography variant='body1' style={paragraphStyle}>
          Some sellers will dominate consistently, but rankings are likely to shift for various reasons:
        </Typography>
        <Typography variant='body1' style={paragraphStyle}>
          <ul>
            <li><strong>Seasonal trends:</strong> Some sellers focus on items that trend at certain times of the year.</li>
            <li><strong>Experience and team support:</strong> Sellers with more practice and assistance from their teams tend to maximize their profits.</li>
            <li><strong>Life events:</strong> A life event like a new addition to the family or a health scare can cause a shift in priorities, leading to decreased sales.</li>
          </ul>
        </Typography>
      </Box>
      <Box style={sectionStyle}>
        <Typography variant='h2' style={h2Style}>How Do We Uncover Poshmark's Top Sellers?</Typography>
        <Typography variant='body1' style={paragraphStyle}>
          We analyze the public closets of Poshmark sellers daily and monitor their sales and the average selling price. 
        </Typography>
        <Typography variant='body1' style={paragraphStyle}>
        This allows us to determine their ranking and provide the most current and accurate insights in an easy-to-understand table with their metrics.
        </Typography>
      </Box>
      <Box style={sectionStyle}>
        <Typography variant='h2' style={h2Style}>Can You Tell Which Sellers are Reputable?</Typography>
        <Typography variant='body1' style={paragraphStyle}>
          There is no way to say who is 100% reputable.
        </Typography>
        <Typography variant='body1' style={paragraphStyle}>
          The best way to determine this is by reviewing the love notes on a sellers profile to gauge their customers' satisfaction. The more love notes, the better, and if you see consistent positive reviews and a handful of less-than-satisfactory reviews, sometimes that is ok, depending on what was said in the love note.
        </Typography>
        <Typography variant='body1' style={paragraphStyle}>
          Just avoid sellers with consistent negative love notes.
        </Typography>
        <Typography variant='body1' style={paragraphStyle}>
          For added protection, Poshmark also removes listings reported as replicas, although really good fakes might slip past detection.
        </Typography>
      </Box>
      <Box style={sectionStyle}>
        <Typography variant='h2' style={h2Style}>How do Luxury Brand Resellers Find Their Inventory?</Typography>
        <Typography variant='body1' style={paragraphStyle}>
          We can’t specify where a seller sources their inventory, as it is not mentioned in their listings. They probably have many different sources to maintain a consistent supply.
        </Typography>
      </Box>
      <Box>
        <Typography variant='h2' style={h2Style}>How to Use Our Top Sellers List?</Typography>
        <Typography variant='body1' style={paragraphStyle}>
          This list is meant to help you learn by exposing what works for top sellers.
        </Typography>
        <Typography variant='body1' style={paragraphStyle}>
        It is also meant as a source of inspiration. It is easy to lose motivation and consistency, but if you see what's possible through the earnings of other sellers, you might get the spark you need to keep going.
        </Typography>
        <Typography variant='body1' style={paragraphStyle}>
          Dive deeper into the data for each seller by clicking the seller's name. This will take you to a dedicated page with more sales metrics, like:
        </Typography>
        <Typography variant='body1' style={paragraphStyle}>
          <ul>
            <li>Highest average prices</li>
            <li>Average time on the market</li>
            <li>+ more</li>
          </ul>
        </Typography>
        <Typography variant='body1' style={paragraphStyle}>
          Use the insights from these top sellers to experiment and inform your overall strategy.
        </Typography>
      </Box>
    </CardContent>
  );
};

export default SellersPageDetails;
