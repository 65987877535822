import * as React from 'react';
import GlobalStyles from '@mui/joy/GlobalStyles';
import Sheet from '@mui/joy/Sheet';
import IconButton from '@mui/joy/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/joy/Typography';
import Box from '@mui/joy/Box';

import { toggleSidebar } from '../utils.ts';

export default function Header() {
  return (
    <Sheet
      sx={{
        display: { xs: 'flex', md: 'none' },
        alignItems: 'center',
        position: 'fixed',
        top: 0,
        width: '100vw',
        height: 'var(--Header-height)',
        zIndex: 9995,
        p: 2,
        borderBottom: '1px solid',
        borderColor: 'background.level2',
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ':root': {
            '--Header-height': '52px',
            [theme.breakpoints.up('md')]: {
              '--Header-height': '0px',
            },
          },
        })}
      />
      <IconButton
        onClick={() => toggleSidebar()}
        variant="outlined"
        color="neutral"
        size="sm"
        sx={{ position: 'absolute', left: 16 }}
      >
        <MenuIcon />
      </IconButton>
      <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
        <Typography
          level="title-md"
          component="a"
          href="/"
          style={{
            fontFamily: "Libre Baskerville",
            fontWeight: "bold",
            textDecoration: "none",
            color: "inherit"
          }}
        >
          Poshwatch
        </Typography>
      </Box>
    </Sheet>
  );
}