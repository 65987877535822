import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as StripePayment from '../../api/stripe_paypment.ts';
import { MyFirebase } from '../../api/my_firebase.ts';
import { useAuth } from '../../contexts/AuthContext.js';
import PATHS from '../../utils/paths';

export const useCheckout = () => {
  const [loading, setLoading] = useState(false);
  const { isSignedIn } = useAuth();
  const navigate = useNavigate();

  const handleCheckout = async (tier: string, billingCycle: 'monthly' | 'yearly') => {
    if (!isSignedIn) {
      navigate(`${PATHS.REGISTER}?from=pricing`);
      return;
    }

    setLoading(true);

    console.log('tier', tier);
    console.log('billingCycle', billingCycle);

    let priceId: string;
    switch (tier) {
      case 'Business':
        priceId = billingCycle === 'yearly' 
          ? StripePayment.BUSINESS_YEARLY_PRICE_ID 
          : StripePayment.BUSINESS_MONTHLY_PRICE_ID;
        break;
      case 'Starter':
        priceId = billingCycle === 'yearly' 
          ? StripePayment.STARTER_YEARLY_PRICE_ID 
          : StripePayment.STARTER_MONTHLY_PRICE_ID;
        break;
      default:
        throw new Error('Invalid tier selected');
    }

    const app = MyFirebase.getInstance().getApp();
    const url = await StripePayment.getCheckoutUrl(app, priceId);

    window.location.href = url;
  };

  return { loading, handleCheckout };
};