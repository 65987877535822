import * as React from 'react';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import StarIcon from '@mui/icons-material/Star';
import Typography from '@mui/material/Typography';

export const HoverRating = ({ value, label, size = 34, style }) => {
  return (
    <Box
      sx={{
        width: 200,
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        ...style
      }}
    >
      <Rating
        readOnly
        name="hover-feedback"
        value={value}
        precision={0.5}
        icon={<StarIcon style={{ fontSize: size }} />}
        emptyIcon={<StarIcon style={{ fontSize: size, opacity: 0.55 }}
          fontSize="40" />}
      />
      {value !== null && (
        <Box sx={{ mt: 0.8 }}>
          <Typography variant="body" style={{ fontWeight: 500 }}>{label}</Typography>
        </Box>
      )}
    </Box>
  );
}
