import React from 'react';
import { Box, Typography, Sheet } from '@mui/joy';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';

interface DataItem {
  name: string;
  value: number;
}

interface DonutChartProps {
  data: DataItem[];
  title: string;
  centerLabel?: string;
}

const DonutChart: React.FC<DonutChartProps> = ({ data, title, centerLabel }) => {
  const totalValue = data.reduce((sum, item) => sum + item.value, 0);

  const colors = [
    '#75B7E1', // Saturated Light Blue
    '#F2D173', // Saturated Pale Yellow
    '#7DDAC5', // Saturated Mint Green
    '#A094E3', // Saturated Light Lavender
    '#F5A7A3', // Saturated Pale Pink
    '#F7B787', // Saturated Light Peach
    '#66D7D7', // Saturated Pale Turquoise
    '#D3A4DB', // Saturated Soft Lilac
    '#A7D68C', // Saturated Light Sage
    '#F18686'  // Saturated Pale Coral
  ];

  return (
    <Sheet
      variant="outlined"
      sx={{
        borderRadius: 'md',
        padding: 2,
      }}
    >
      <Typography level="h4" sx={{ mb: 2, textAlign: 'start' }}>
        {title}
      </Typography>
      <Box sx={{ position: 'relative', height: 200 }}>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie
              data={data}
              cx="50%"
              cy="50%"
              innerRadius="80%"
              outerRadius="100%"
              paddingAngle={2}
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        {centerLabel && (
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
            }}
          >
            <Typography level="body-sm">{centerLabel}</Typography>
          </Box>
        )}
      </Box>
      <Box sx={{ mt: 2 }}>
        {data.map((item, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <Box
              sx={{
                width: 12,
                height: 12,
                borderRadius: '50%',
                backgroundColor: colors[index % colors.length],
                mr: 1,
              }}
            />
            <Typography level="body-sm">
              {item.name} ({Math.round((item.value / totalValue) * 100)}%)
            </Typography>
          </Box>
        ))}
      </Box>
    </Sheet>
  );
};

export default DonutChart;